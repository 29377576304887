import React, { Component } from 'react'
import Modal from 'react-modal'
import { graphql } from 'react-apollo'
import addPostscriptToAnswer from '../mutations/add-postscript-to-answer'
import TextareaAutosize from 'react-autosize-textarea'

const className = {
  base: 'modal widest',
  afterOpen: 'modal-after-open',
  beforeClose: 'modal-before-close',
}

const overlayClassName = {
  base: 'overlay',
  afterOpen: 'overlay-after-open',
  beforeClose: 'overlay-before-close',
}

const wordCount = (text) => {
  if (!text) return 0
  return text.split(' ').filter((s) => /[A-z]+/.test(s)).length
}

@graphql(addPostscriptToAnswer, { name: 'addPostscriptToAnswer' })
class AdditionalThoughtsModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      text: '',
    }
  }

  handleClose = (e) => {
    e.preventDefault()

    const { onClose } = this.props
    onClose(null)
  }

  handleThoughtsChange = (e) => {
    this.setState({ text: e.target.value })
  }

  handleSave = (e) => {
    e.preventDefault()

    const { onClose, assignment } = this.props
    const { text } = this.state
    const variables = { text, id: assignment.answer.id }

    this.props
      .addPostscriptToAnswer({
        variables: variables,
      })
      .then(() => {
        onClose()
        this.setState({
          text: '',
        })
      })
  }
  render() {
    const { isOpen, assignment, id } = this.props
    const { text } = this.state
    const formClasses = true ? 'reflection-form active' : 'reflection-form'
    const submitted = false

    const { question, answer } = assignment
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={this.handleClose}
        contentLabel={'Pulse Modal'}
        className={className}
        overlayClassName={overlayClassName}
      >
        <a className="close" onClick={this.handleClose}>
          Cancel
        </a>
        <form id={id} className={formClasses}>
          <div className="reflection-card add-more">
            <label>{question.text}</label>
            <div class="text">{answer.text}</div>
            <TextareaAutosize
              value={text || ''}
              placeholder="+  Add more"
              onChange={this.handleThoughtsChange}
            />
            <div className="word-count">Words: {wordCount(text)} / 450</div>
          </div>
          <div className="actions">
            <button onClick={this.handleSave}>
              {submitted ? <i className="material-icons">check</i> : 'Submit'}
            </button>
          </div>
        </form>
      </Modal>
    )
  }
}

export default AdditionalThoughtsModal
