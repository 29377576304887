import { gql } from 'react-apollo'

export default gql`
  mutation updateGroup($id: ID!, $name: String!, $color: String!) {
    updateGroup(id: $id, name: $name, color: $color) {
      id
      name
      color
      archived
    }
  }
`
