import React, { Component } from 'react'
import Modal from 'react-modal'
import StudentSelect from './student-select'
import Reflections from './reflections'

const className = {
  base: 'full-modal',
  afterOpen: 'modal-after-open',
  beforeClose: 'modal-before-close',
}

const overlayClassName = {
  base: 'overlay',
  afterOpen: 'overlay-after-open',
  beforeClose: 'overlay-before-close',
}

class ViewReflections extends Component {
  constructor(props) {
    super(props)

    this.state = {
      searchText: '',
    }
  }

  setSearchText = (val) => {
    this.setState({ searchText: val })
  }

  handleClose = (e) => {
    e && e.preventDefault()
    window.viewReflectionsBackAction = null
    this.setSelectedStudent(null)
    this.props.onClose()
  }

  handleBack = (e) => {
    e.preventDefault()
    let backAction = window.viewReflectionsBackAction
    window.viewReflectionsBackAction
      ? window.viewReflectionsBackAction()
      : this.setSelectedStudent(null)
    window.viewReflectionsBackAction = null
    if (backAction) {
      setTimeout(() => {
        this.handleClose()
      }, 500)
    }
  }

  showStudents = (e) => {
    e.preventDefault()
    window.viewReflectionsBackAction = null
    this.setSelectedStudent(null)
  }

  setSelectedStudent = (student) => {
    this.props.setSelectedStudent(student)
  }

  showClose = () => {
    const { selectedStudent } = this.props
    return selectedStudent ? { display: 'none' } : {}
  }

  render() {
    const { isOpen, project, selectedStudent } = this.props
    const { searchText } = this.state

    return (
      <Modal
        isOpen={isOpen}
        className={className}
        contentLabel="View Reflections Modal"
        overlayClassName={overlayClassName}
      >
        <header className="full-modal-header">
          <a href="/" className="logo">
            <img src="/images/logo.png" className="logo" alt="" />
          </a>
          <h2>Reflections - {project && project.name}</h2>
          <nav role="navigation" aria-label="App Navigation">
            <a href="#" style={this.showClose()} onClick={this.handleClose}>
              Close
              <i className="material-icons">close</i>
            </a>
          </nav>
        </header>
        <section className="full-modal-body">
          {selectedStudent ? (
            <Reflections
              project={project}
              student={selectedStudent}
              searchText={searchText}
              setSearchText={this.setSearchText}
              showStudents={this.showStudents}
              onBack={this.handleBack}
            />
          ) : (
            project && (
              <StudentSelect
                project={project}
                onSelect={this.setSelectedStudent}
              />
            )
          )}
        </section>
      </Modal>
    )
  }
}

export default ViewReflections
