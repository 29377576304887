import { gql } from 'react-apollo'

export default gql`
  mutation SortBanks($ids: [ID]!) {
    sortBanks(ids: $ids) {
      id
      sortOrder
    }
  }
`
