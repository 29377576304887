import React, { PureComponent } from 'react'
import Dropzone from 'react-dropzone'
import TextareaAutosize from 'react-autosize-textarea'
import WarningModal from './warning-modal'
import { graphql } from 'react-apollo'
import fetchReflection from '../queries/reflection'
import submitAnswer from '../mutations/submit-answer'
import updateAnswer from '../mutations/update-answer'
import attachEvidence from '../mutations/attach-evidence'
import debounce from 'debounce'
import { isPast } from 'date-fns'
import { formatDate } from '../utils'

const evidenceName = (evidence) => {
  if (!evidence) return null
  return typeof evidence === 'string'
    ? evidence.split('/').pop().replace(/\?.+/, '')
    : evidence.name
}

const wordCount = (text) => {
  if (!text) return 0
  return text.split(' ').filter((s) => /[A-z]+/.test(s)).length
}

@graphql(submitAnswer, { name: 'submitAnswer' })
@graphql(updateAnswer, { name: 'updateAnswer' })
@graphql(attachEvidence, { name: 'attachEvidence' })
class ReflectionForm extends PureComponent {
  constructor(props) {
    super(props)

    const {
      data: { answer },
    } = props

    this.state = {
      submitted: false,
      warningMsg: null,
      isOpen: false,
      warningAction: null,
      errorMsg: null,
      saved: false,
      text: answer && answer.text,
      evidence: answer && answer.evidence,
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    const {
      data: { answer },
    } = props
    const { text } = this.state
    const newText = text || (answer && answer.text)

    this.setState({
      submitted: false,
      text: newText,
      evidence: answer && answer.evidence,
    })
  }

  updateAnswer = debounce(() => {
    const { text } = this.state
    const { data, updateAnswer, onSave } = this.props

    updateAnswer({
      variables: { assignmentId: data.id, text },
    }).then(() => {
      onSave()
    })
  }, 500)

  handleDrop = (files) => {
    const { data, attachEvidence } = this.props
    const evidence = files[0]
    this.setState({ evidence })

    attachEvidence({
      variables: { assignmentId: data.id, evidence },
    })
  }

  handleDropReject = () => {
    this.setState({
      warningMsg:
        'Unable to attach file. Please make sure the file is a PDF 10MB or smaller.',
      isOpen: true,
      warningAction: null,
    })
  }

  handleChange = (e) => {
    const wc = wordCount(e.target.value)
    if (wc > 450) return
    this.setState({ text: e.target.value })
    this.updateAnswer()
  }

  doSubmit = () => {
    const { id, data, onSuccess, submitAnswer } = this.props

    submitAnswer({
      refetchQueries: [
        {
          query: fetchReflection,
          variables: { id },
        },
      ],
      variables: {
        assignmentId: data.id,
      },
    }).then(({ data: { submitAnswer } }) => {
      if (submitAnswer) {
        this.setState({ submitted: true })
        onSuccess()
      } else {
        // We fail to submit without triggering an exception
        this.setState({
          warningMsg:
            'There was a problem submitting your answer. Please check your answer and try again.',
          isOpen: true,
          warningAction: null,
        })
      }
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()

    const { evidence, text } = this.state
    const { data } = this.props
    const { requireEvidence } = data
    const wc = wordCount(text)

    if (requireEvidence && !evidence) {
      this.setState({ errorMsg: 'Evidence is required!' }, () => {
        setTimeout(() => {
          this.setState({ errorMsg: null })
        }, 5000)
      })
      return
    }

    if (wc === 0) {
      this.setState({
        warningMsg: 'Please respond before submitting.',
        isOpen: true,
        warningAction: null,
      })
      return
    }

    if (wc < 80) {
      this.setState({
        warningMsg: 'Self-check! The minimum word count is 80.',
        isOpen: true,
        warningAction: null,
      })
      return
    }

    this.doSubmit()
  }

  dismissWarning = (e) => {
    e.preventDefault()

    this.setState({ warningMsg: null, isOpen: false })
  }

  renderEvidence() {
    const { evidence } = this.state
    return (
      <div className="attachments">
        <Dropzone
          className="attach-evidence"
          onDrop={this.handleDrop}
          onDropRejected={this.handleDropReject}
          maxSize={10000000}
          style={{}}
        >
          <i className="material-icons">cloud_upload</i>
          {evidence ? (
            <span>{evidenceName(evidence)}</span>
          ) : (
            <div>
              <span>Attach Evidence (PDF).</span>
              &nbsp;Size limit: 10MB.
            </div>
          )}
        </Dropzone>
      </div>
    )
  }

  render() {
    const {
      errorMsg,
      warningMsg,
      isOpen,
      warningAction,
      saved,
      submitted,
      text,
    } = this.state
    const { active, data, handleFocus } = this.props
    const { id, dueAt, metaReflection, question, requireEvidence } = data
    const formClasses = active ? 'reflection-form active' : 'reflection-form'
    const dueAtClasses = isPast(dueAt) ? 'due-at late' : 'due-at'
    const msgClasses = `msg ${saved && 'saved'} ${errorMsg && 'error'}`
    const formId = `assignment-${id}`

    return (
      <form id={formId} className={formClasses} onSubmit={this.handleSubmit}>
        <div className="reflection-card">
          {metaReflection && (
            <label className="meta-reflection">Meta-Reflection</label>
          )}
          {dueAt && (
            <div className={dueAtClasses}>
              <i className="material-icons">access_time</i>
              Due {formatDate(dueAt, 'ddd, MMMM D [at] h:mma')}
            </div>
          )}
          <label>{question.text}</label>
          <TextareaAutosize
            value={text || ''}
            placeholder="+  Add response"
            onChange={this.handleChange}
            onFocus={() => handleFocus(id)}
          />
          <div className="word-count">Words: {wordCount(text)} / 450</div>
        </div>
        {requireEvidence && this.renderEvidence()}
        <div className="actions">
          <button>
            {submitted ? <i className="material-icons">check</i> : 'Submit'}
          </button>
          <span className={msgClasses}>
            {saved && 'Draft saved!'}
            {errorMsg}
          </span>
        </div>
        <WarningModal
          isOpen={isOpen}
          onClose={this.dismissWarning}
          onConfirm={warningAction}
        >
          <h2 style={{ marginTop: 48, marginBottom: 54 }}>{warningMsg}</h2>
        </WarningModal>
      </form>
    )
  }
}

export default ReflectionForm
