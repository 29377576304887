import { gql } from 'react-apollo'

export default gql`
  mutation archiveGroup($id: ID!) {
    updateGroup(id: $id, archived: true) {
      id
      name
      color
      archived
    }
  }
`
