import React from 'react'
import { prop, sortWith, descend } from 'ramda'

const previousCategory = (selectedCategory, categories, callback) => {
  const index = categories.indexOf(selectedCategory)

  if (index === 0) {
    callback(categories[categories.length - 1])
    return
  }

  callback(categories[index - 1])
}

const nextCategory = (selectedCategory, categories, callback) => {
  const index = categories.indexOf(selectedCategory)

  if (index === categories.length - 1) {
    callback(categories[0])
    return
  }

  callback(categories[index + 1])
}

const QuestionFilters = ({
  categories,
  category,
  stages,
  stage,
  dropdown,
  onCategoryChange,
  onStageChange,
  toggleDropdown,
  searchText,
  onSearchTextChange,
}) => {
  const onBackClicked = () => {
    onCategoryChange(null)
    onSearchTextChange('')
  }

  let sortedCategories = []
  if (categories) {
    const sortByAllowed = sortWith([descend(prop('allowed'))])
    sortedCategories = sortByAllowed(categories)
  }

  return (
    <div>
      {category === null && (
        <div className="heading">
          <h1>Curated Question Banks</h1>
          <span>Narrow by Type</span>
        </div>
      )}
      {category !== null && (
        <div className="heading">
          <div
            className="back-to-question-types"
            onClick={() => onBackClicked(null)}
          >
            <i className="material-icons">keyboard_arrow_left</i>
            <a>Back to Question Banks</a>
          </div>
          <div className="category-switcher">
            <div
              className="arrow-link"
              onClick={() =>
                previousCategory(category, categories, onCategoryChange)
              }
            >
              <i className="material-icons">keyboard_arrow_left</i>
            </div>
            <div className="category-select" onClick={toggleDropdown}>
              <div className="selected-category">{category.name}</div>
              <div className="icon">
                <i className="material-icons">keyboard_arrow_down</i>
              </div>

              <ul
                className="category-options"
                style={{ display: dropdown ? 'block' : 'none' }}
              >
                {categories &&
                  categories.map((category) => (
                    <li onClick={() => onCategoryChange(category)}>
                      {category.name}
                    </li>
                  ))}
              </ul>
            </div>
            <div
              className="arrow-link"
              onClick={() =>
                nextCategory(category, categories, onCategoryChange)
              }
            >
              <i className="material-icons">keyboard_arrow_right</i>
            </div>
          </div>
          <div className="stages">
            <ul className="stages-tabs">
              {stages &&
                stages.map((s) => (
                  <li
                    key={s}
                    className={s === stage && 'active'}
                    onClick={() => onStageChange(s)}
                  >
                    {s}
                  </li>
                ))}
            </ul>
          </div>
        </div>
      )}
      {category !== null && (
        <div className="question-search field">
          <input
            type="search"
            placeholder="Narrow by keyword or learner profile?"
            value={searchText}
            onChange={(e) => onSearchTextChange(e.target.value)}
          />
        </div>
      )}
      <div className="question-filters">
        {category === null && (
          <ul className="categories">
            {sortedCategories &&
              sortedCategories.map(
                (category) =>
                  !category.hidden && (
                    <li
                      key={category.id}
                      style={category.allowed ? {} : { color: '#c6c6c6' }}
                      onClick={() => onCategoryChange(category)}
                    >
                      {category.name}
                      <i className="material-icons">keyboard_arrow_right</i>
                    </li>
                  )
              )}
          </ul>
        )}
      </div>
    </div>
  )
}

export default QuestionFilters
