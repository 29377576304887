import { gql } from 'react-apollo'

export default gql`
  mutation attachEvidence($assignmentId: ID!, $evidence: Upload) {
    attachEvidence(assignmentId: $assignmentId, evidence: $evidence) {
      id
      assignmentId
      text
      evidence
      submittedAt
      updatedAt
    }
  }
`
