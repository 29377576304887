import React, { Component } from 'react'
import Calendar from './calendar'
import debounce from 'debounce'

class DatePicker extends Component {
  constructor(props) {
    super(props)

    const datetime = props.datetime || new Date()

    this.state = {
      datetime,
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    const datetime = props.datetime || new Date()

    this.setState({
      datetime,
    })
  }

  validateDate = debounce((nextDatetime) => {
    const { min, max } = this.props

    if (min > nextDatetime && nextDatetime < max) {
      this.setState({
        datetime: min,
      })
    }
  }, 1500)

  handleDate = (pickeddatetime, monthChange) => {
    if (monthChange) {
      this.setState({ datetime: pickeddatetime })
      return
    }

    const { onSave } = this.props
    this.setState({ pickeddatetime }, () => {
      this.validateDate(pickeddatetime)
    })
    onSave(pickeddatetime)
  }

  render() {
    const { datetime } = this.state
    const { isActive, min, max } = this.props
    const classes = isActive ? 'datetime-picker active' : 'datetime-picker'

    return (
      <div className={classes}>
        <Calendar
          date={datetime}
          min={min}
          max={max}
          onChange={this.handleDate}
        />
      </div>
    )
  }
}

export default DatePicker
