import { gql } from 'react-apollo'

export default gql`
  query findOrganization($id: ID!) {
    organization(id: $id) {
      id
      name
      subscriptionStart
      timezone

      users {
        id
        name
        email
        active
        graduationYear
        role
        teacherAssignmentCount
        studentAssignmentCount
        allActiveProjectsByTeacherCount
        teacherDateOfLastActivity
        lastActiveProjectByTeacher
        lastQuestionAnsweredByStudent
        updatedAt
        insertedAt
      }
    }
  }
`
