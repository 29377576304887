import React from 'react'

const StudentTableRow = ({ data, disabled, checked, onClick, readOnly }) => {
  const rowClasses = disabled ? 'disabled' : ''
  const action = disabled ? () => {} : onClick
  return (
    <tr className={rowClasses} onClick={action}>
      <td>
        <div>
          <input
            type="checkbox"
            value={data.id}
            checked={checked}
            disabled={disabled}
          />
          <label>{'\u00a0'}</label>
        </div>
      </td>
      <td>
        <div>{data.name}</div>
      </td>
      {!readOnly && (
        <td>
          <div>{data.email}</div>
        </td>
      )}
      <td>
        <div>{data.graduation_year}</div>
      </td>
    </tr>
  )
}

export default StudentTableRow
