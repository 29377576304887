import React, { Component } from 'react'
import { append, equals, without } from 'ramda'

const goToSectionNew = (groupId, projectId) => {
  const search = projectId ? `?project_id=${projectId}` : ''
  window.location.href = `/groups/${groupId}/sections/new${search}`
}

const Section = ({ data, selected, onClick }) => {
  const { id, name, students } = data
  return (
    <div className="section-option">
      <input
        type="checkbox"
        id={`section-${id}`}
        name="sections"
        checked={selected}
      />
      <label htmlFor={`section-${id}`} onClick={onClick}>
        <strong>{name}</strong>
        <br />
        <small>{students.length} Students</small>
      </label>
    </div>
  )
}

class SectionPicker extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selected: props.selected,
      showWarning: false,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // reset selected on group change
    if (!equals(nextProps.group, this.props.group)) {
      this.setState({ selected: [] })
    }
  }

  isSelected = (section) => {
    const { selected } = this.state
    return selected.includes(section.id)
  }

  toggleSelected = (section) => {
    const { selected } = this.state
    const { liveQuestionCount } = this.props
    const showWarning = liveQuestionCount > 0

    if (this.isSelected(section)) {
      this.setState({
        selected: without([section.id], selected),
        showWarning: showWarning,
      })
    } else {
      this.setState({
        selected: append(section.id, selected),
        showWarning: false,
      })
    }
  }

  handleSave = () => {
    const { selected } = this.state
    const { onSave } = this.props

    onSave(selected)
  }

  render() {
    const { group, projectId } = this.props
    const activeSections =
      group.sections && group.sections.filter((s) => !s.archived)
    const { showWarning } = this.state

    return (
      <div className="section-picker">
        <h2>Pick Sections</h2>
        {showWarning && (
          <div className="warning">
            <i className="material-icons">error</i>
            <span>
              <span style={{ fontWeight: 'bold', fontSize: '1.2em' }}>
                Warning
              </span>
              <br />
              Are you sure you want to remove this section of students?
              <br />
              They will lose access to the project.
              <br />
              To cancel, click the "cancel" link (top right).
            </span>
          </div>
        )}
        <div className="sections">
          {activeSections &&
            activeSections.map((s) => (
              <Section
                key={s.id}
                data={s}
                selected={this.isSelected(s)}
                onClick={() => this.toggleSelected(s)}
              />
            ))}
        </div>
        <a
          className="add-new"
          onClick={() => goToSectionNew(group.id, projectId)}
        >
          <i className="material-icons">add_circle</i>
          Add New Section
        </a>
        <button onClick={this.handleSave}>Save &amp; Close</button>
      </div>
    )
  }
}

export default SectionPicker
