import React, { Component } from 'react'
import Modal from 'react-modal'
import { graphql } from 'react-apollo'
import addCollaborator from '../mutations/add-colleague-to-project'
import removeCollaborator from '../mutations/remove-colleague-from-project'

const className = {
  base: 'modal share-project',
  afterOpen: 'modal-after-open',
  beforeClose: 'modal-before-close',
}

const overlayClassName = {
  base: 'overlay',
  afterOpen: 'overlay-after-open',
  beforeClose: 'overlay-before-close',
}

@graphql(addCollaborator, { name: 'addCollaborator' })
@graphql(removeCollaborator, { name: 'removeCollaborator' })
class ShareProject extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: null,
    }
  }

  handleChange = (e) => {
    this.setState({ email: e.target.value })
  }

  handleSubmit = (e) => {
    e.stopPropagation()

    const { email } = this.state
    const {
      addCollaborator,
      afterShare,
      project: { id },
    } = this.props

    addCollaborator({
      variables: { id, email },
    }).then(afterShare)
  }

  handleRemoveCollab = (e) => {
    e.stopPropagation()

    const {
      afterShare,
      onCancel,
      project: { id },
      removeCollaborator,
    } = this.props

    removeCollaborator({
      variables: { id },
    }).then(() => {
      afterShare()
      onCancel()
    })
  }

  render() {
    const { email } = this.state
    const { isOpen, onCancel, project } = this.props
    const collaborator = project && project.collaborator

    if (collaborator) {
      return (
        <Modal
          isOpen={isOpen}
          onRequestClose={onCancel}
          contentLabel="Share Project Modal"
          className={className}
          overlayClassName={overlayClassName}
        >
          <div>
            <a className="close" onClick={onCancel}>
              Cancel
            </a>
          </div>
          <h2>Invite a Colleague</h2>
          <p>
            You are allowed a maxiumum of 1 colleague per reflEQ. If you'd like
            to share with a different colleague, remove the one below.
          </p>
          <div className="current-collab">
            <div>
              <strong>{collaborator.name}</strong>
            </div>
            <div>{collaborator.email}</div>
            <div onClick={this.handleRemoveCollab}>
              <a>Remove</a>
            </div>
          </div>
        </Modal>
      )
    } else {
      return (
        <Modal
          isOpen={isOpen}
          onRequestClose={onCancel}
          contentLabel="Share Project Modal"
          className={className}
          overlayClassName={overlayClassName}
        >
          <div>
            <a className="close" onClick={onCancel}>
              Cancel
            </a>
          </div>
          <h2>Invite a Colleague</h2>
          <p>
            Your colleague will receive joint access to this project. You can
            collaborate to assign prompts and view student reflections.
          </p>
          <form onSubmit={this.handleSubmit}>
            <div className="field floating section-name">
              <input
                type="text"
                value={email}
                onChange={this.handleChange}
                required
              />
              <label>Colleague's Email Address</label>
              <div className="highlight" />
            </div>
            <button onClick={this.handleSubmit}>Add to project</button>
          </form>
        </Modal>
      )
    }
  }
}

export default ShareProject
