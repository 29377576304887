import { gql } from 'react-apollo'

export default gql`
  query reflectionList {
    projects {
      id
      name
      group {
        id
        name
      }
      owner {
        id
        name
      }
      collaborator {
        id
        name
      }
      outstandingAssignments {
        id
        dueAt
        answer {
          updatedAt
        }
      }
      completedAssignments {
        id
        dueAt
        answer {
          updatedAt
        }
      }
    }
  }
`
