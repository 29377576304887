import React, { Component } from 'react'
import Assignment from './assignment'
import { DropTarget } from 'react-dnd'
import { graphql } from 'react-apollo'
import fetchAssignments from '../queries/assignments'
import createAssignment from '../mutations/create-assignment'
import sortAssignments from '../mutations/sort-assignments'
import deleteAssignment from '../mutations/delete-assignment'
import update from 'immutability-helper'
import debounce from 'debounce'
import { sortBy, prop } from 'ramda'

const queueTarget = {
  canDrop(props) {
    const { data, metaReflection } = props
    return (
      !metaReflection ||
      (data && data.assignments && data.assignments.length === 0)
    )
  },

  drop(props, monitor) {
    if (monitor.didDrop()) {
      return // don't do anything if an assignment target already handled drop
    }

    const item = monitor.getItem()

    if (item.cardType === 'question') {
      const questionId = item.id
      const { createAssignment, data, projectId, metaReflection } = props
      const { refetch } = data

      createAssignment({
        variables: {
          projectId,
          questionId,
          metaReflection,
        },
      }).then(refetch)
    }
  },
}

const collect = (connect, monitor) => {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  }
}

@graphql(fetchAssignments)
@graphql(createAssignment, { name: 'createAssignment' })
@graphql(sortAssignments, { name: 'sortAssignments' })
@graphql(deleteAssignment, { name: 'deleteAssignment' })
@DropTarget('question', queueTarget, collect)
class ProjectQueue extends Component {
  constructor(props) {
    super(props)

    this.state = {
      assignments: [],
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { activeAssignment } = this.props
    const { data } = props

    // refetch assignments if just assigned
    if (activeAssignment && !props.activeAssignment) {
      data.refetch()
    }

    const sortBySortPosition = sortBy(prop('sortPosition'))
    const assignments =
      (data && data.assignments && sortBySortPosition(data.assignments)) || []

    this.setState({ assignments })
  }

  canDrop() {
    const { data, metaReflection } = this.props
    return (
      !metaReflection ||
      (data && data.assignments && data.assignments.length === 0)
    )
  }

  handleCreate = (questionId, hoverIndex) => {
    const {
      createAssignment,
      data: { assignments, refetch },
      projectId,
      metaReflection,
    } = this.props

    createAssignment({
      variables: {
        projectId,
        questionId,
        metaReflection,
      },
    }).then(() => {
      refetch().then(() => {
        this.handleSort(assignments.length, hoverIndex)
      })
    })
  }

  handleDelete = (id) => {
    const { activeAssignment, data, deleteAssignment, onAssign } = this.props
    const { refetch } = data

    // reset if activeAssignment
    if (activeAssignment && activeAssignment.id === id) {
      onAssign(null)
    }

    deleteAssignment({
      variables: { id },
    }).then(refetch)
  }

  dispatchSort = debounce(() => {
    const { assignments } = this.state
    const { data, sortAssignments } = this.props
    const { refetch } = data
    const ids = assignments.map((a) => a.id)
    const expected = ids.map((id, i) => ({ id, sortPosition: i }))

    sortAssignments({
      variables: { ids },
      optimisticResponse: {
        sortAssignments: expected,
      },
    }).then(refetch)
  }, 500)

  handleSort = (dragIndex, hoverIndex) => {
    const { assignments } = this.state
    const dragItem = assignments[dragIndex]

    this.setState(
      update(this.state, {
        assignments: {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragItem],
          ],
        },
      })
    )

    this.dispatchSort()
  }

  render() {
    const { connectDropTarget, metaReflection, onAssign, allowAssign, isOver } =
      this.props
    const { assignments } = this.state
    const showDrop =
      !metaReflection || (assignments && assignments.length === 0)
    const dropClass = isOver ? 'drop isOver' : 'drop'

    return connectDropTarget(
      <ul className="queue">
        {assignments.map((a, i) => (
          <Assignment
            key={a.id}
            data={a}
            index={i}
            allowAssign={allowAssign}
            onAssign={onAssign}
            onCreate={this.handleCreate}
            onDelete={this.handleDelete}
            onSort={this.handleSort}
            canDrop={this.canDrop}
          />
        ))}
        {showDrop && (
          <li>
            <div className={dropClass}>
              Drag and drop a {metaReflection && 'final'} question here.
            </div>
          </li>
        )}
      </ul>
    )
  }
}

export default ProjectQueue
