import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { ApolloProvider, graphql } from 'react-apollo'
import client from '../client'
import fetchQuestions from '../queries/adminQuestions'
import QuestionFormModal from '../components/question-form-modal'
import { formatDate } from '../utils'
import { uniq } from 'ramda'

const questionClassName = (question) => {
  if (question.archived) {
    return 'disabled'
  } else {
    return ''
  }
}

@graphql(fetchQuestions)
class QuestionList extends Component {
  state = {
    filterCategory: null,
    showArchived: false,
    showModal: false,
  }

  toggleShowArchived = () => {
    this.setState({ showArchived: !this.state.showArchived })
  }

  toggleShowArchivedText = () => {
    if (this.state.showArchived) {
      return 'Hide archived questions'
    } else {
      return 'Show archived questions'
    }
  }

  onCategoryFilterChange = (event) => {
    this.setState({ filterCategory: event.target.value })
  }

  openQuestionForm = (questionId) => {
    const {
      data: { adminQuestions },
      learnerTypes,
      stages,
    } = this.props
    const categories = uniq(adminQuestions.map((q) => q.category))
    const question = adminQuestions.find((q) => q.id === questionId)
    const props = {
      question,
      learnerTypes,
      stages,
      categories,
      onSubmit: this.closeQuestionForm,
    }

    this.setState({ editingQuestion: props, showModal: true })
  }

  closeQuestionForm = () => {
    this.setState({ showModal: false })
  }

  showCheckMark = (preview) => {
    if (preview) {
      return (
        <i style={{ color: '#1DAEA6' }} className="material-icons">
          done
        </i>
      )
    }
  }

  componentDidUpdate() {
    // equalize table div heights
    const tableDivs = Array.from(
      document.querySelectorAll('table.fancy td > div')
    )

    if (tableDivs.length > 0) {
      const heights = tableDivs.map((d) => d.offsetHeight)
      const maxHeight = Math.max(...heights)

      tableDivs.map((d) => {
        d.style.height = `${maxHeight}px`
      })
    }
  }

  render() {
    const {
      data: { adminQuestions },
    } = this.props

    let categories =
      adminQuestions && uniq(adminQuestions.map((q) => q.category.name))

    let filteredQuestions = adminQuestions
    if (adminQuestions && this.state.filterCategory) {
      filteredQuestions = adminQuestions.filter(
        (q) => q.category.name === this.state.filterCategory
      )
    }

    if (filteredQuestions && this.state.showArchived === false) {
      filteredQuestions = filteredQuestions.filter((q) => q.archived === false)
    }

    const { showModal } = this.state

    return (
      <div className="admin-questions">
        <div className="header">
          <h1>Questions</h1>
          <nav className="actions">
            <a className="button" href="/admin/questions/new">
              New Question
            </a>
            <a className="button" href="/admin/questions.csv">
              Export
            </a>
          </nav>
        </div>
        <table className="fancy">
          <thead>
            <tr>
              <th>
                <div>Text</div>
              </th>
              <th>
                <div>
                  <select
                    onChange={this.onCategoryFilterChange}
                    className="choices"
                  >
                    <option value="" disabled selected="selected">
                      Filter by Category
                    </option>
                    {categories &&
                      categories.map((category) => (
                        <option key={category} value={category}>
                          {category}
                        </option>
                      ))}
                  </select>
                </div>
              </th>
              <th>
                <div>Stage</div>
              </th>
              <th>
                <div>Learner Type</div>
              </th>
              <th>
                <div># of Assignments</div>
              </th>
              <th>
                <div>Preview</div>
              </th>
              <th>
                <div>Last Modified</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredQuestions &&
              filteredQuestions.map((q) => (
                <tr
                  key={q.id}
                  className={questionClassName(q)}
                  onClick={() => this.openQuestionForm(q.id)}
                >
                  <td>
                    <div>
                      <span style={{ maxWidth: '100%' }}>{q.text}</span>
                    </div>
                  </td>
                  <td>
                    <div>{q.category.name}</div>
                  </td>
                  <td>
                    <div>{q.stage}</div>
                  </td>
                  <td>
                    <div>{q.learnerType}</div>
                  </td>
                  <td>
                    <div style={{ alignItems: 'center' }}>
                      {q.assignmentCount}
                    </div>
                  </td>
                  <td>
                    <div>{this.showCheckMark(q.preview)}</div>
                  </td>
                  <td>
                    <div>{formatDate(q.updatedAt)}</div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <header className="header">
          <nav className="actions">
            <a onClick={this.toggleShowArchived}>
              {this.toggleShowArchivedText()}
            </a>
          </nav>
        </header>
        <QuestionFormModal
          isOpen={showModal}
          onCancel={this.closeQuestionForm}
          formProps={this.state.editingQuestion}
        />
      </div>
    )
  }
}

const questionList = document.getElementById('question-list')
if (questionList) {
  const csrfTag = document.querySelector('meta[name=csrf-token]')
  const csrfToken = csrfTag.getAttribute('content')
  const categories = JSON.parse(questionList.getAttribute('data-categories'))
  const learnerTypes = JSON.parse(
    questionList.getAttribute('data-learner-types')
  )
  const stages = JSON.parse(questionList.getAttribute('data-stages'))
  const props = { csrfToken, categories, learnerTypes, stages }

  ReactDOM.render(
    <ApolloProvider client={client}>
      <QuestionList {...props} />
    </ApolloProvider>,
    questionList
  )
}
