import React, { Component } from 'react'
import { getHours } from 'date-fns'

class TimePicker extends Component {
  handleTime = (evt) => {
    evt.preventDefault()
    if (evt.target.value === '') {
      return
    }
    const { onSave } = this.props
    const rawTime = evt.target.value
    const timeArray = rawTime.split(':')

    onSave(timeArray[0], timeArray[1])
  }

  renderOption = (mins, hours, hours24, ampm) => {
    const { datetime } = this.props
    const currentHours = getHours(datetime)
    if (hours24 === currentHours && mins === '00') {
      return (
        <option
          onClick={this.handleTime}
          className={'time-option'}
          value={`${hours24}:${mins}`}
        >{`${hours}:${mins} ${ampm}`}</option>
      )
    } else {
      return (
        <option
          onClick={this.handleTime}
          className={'time-option'}
          value={`${hours24}:${mins}`}
        >{`${hours}:${mins} ${ampm}`}</option>
      )
    }
  }

  render() {
    const { isActive } = this.props
    const classes = isActive ? 'time-picker active' : 'time-picker'

    return (
      <div className={classes}>
        <div style={{ height: '250px', overflow: 'scroll' }}>
          {[6, 7, 8, 9, 10, 11].map((num) => {
            return ['00', '15', '30', '45'].map((mins) => {
              let newnum = num === 12 ? 0 : num
              return this.renderOption(mins, num, newnum, 'AM')
            })
          })}
          {[12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((num) => {
            return ['00', '15', '30', '45'].map((mins) => {
              let newnum = num === 12 ? 12 : num + 12
              return this.renderOption(mins, num, newnum, 'PM')
            })
          })}
          {this.renderOption('00', 12, 24, 'PM')}
        </div>
      </div>
    )
  }
}

export default TimePicker
