import React from 'react'
import { formatDate, dateFromISO8601 } from '../utils'
import HighlightedText from '../components/highlighted-text'
import HighlightInputControl from '../components/highlight-input-control'
import PostscriptText from './postscript-text'

const Response = ({ highlight, assignment, student }) => {
  const studentAnswers = assignment.submittedAnswers.filter(
    (a) => a.user.id === student.id
  )
  const answer = studentAnswers.length > 0 && studentAnswers[0]
  if (!answer) return null
  const submittedAt = new Date(answer.submittedAt)
  const dueAt = new Date(assignment.dueAt)

  return (
    <div className="response-row reflection">
      <div className="container">
        <div className="response">
          <div className="student-name">{assignment.question.text}</div>
          <div className="submitted-at">
            {submittedAt > dueAt && <div className="badge">Late</div>}
            <i className="material-icons">access_time</i>
            Submitted {formatDate(submittedAt, 'ddd, MMM D [at] h:mma')}
          </div>
          <div className="text">
            <HighlightedText text={answer.text} highlight={highlight} />
            <PostscriptText answer={answer} />
          </div>
          {answer.evidence && (
            <a className="evidence" href={answer.evidence}>
              <i className="material-icons">attach_file</i>
              {decodeURI(answer.evidence.split('/').pop().replace(/\?.+/, ''))}
            </a>
          )}
        </div>
      </div>
    </div>
  )
}

const Reflections = ({
  project,
  showStudents,
  onBack,
  student,
  searchText,
  setSearchText,
}) => {
  const studentSections = project.sections.filter((s) =>
    s.students.map((st) => st.id).includes(student.id)
  )
  const studentSectionNames = studentSections
    .map((s) => s.name.replace(/\s/g, '\u00A0'))
    .join(', ')

  // Convert to a mutable array so we can sort without issues
  const assignments = [...project.assignments].sort(
    (a, b) => dateFromISO8601(a.dueAt) - dateFromISO8601(b.dueAt)
  )

  return (
    <div id="view-reflection">
      <header className="responses-header">
        <div className="nav-container">
          <nav role="navigation" aria-label="Modal Navigation">
            <a className="back-button" href="#" onClick={onBack}>
              <i className="material-icons">keyboard_arrow_left</i>
              Back
            </a>
          </nav>
        </div>
        <div className="container top-pad">
          <div className="info">
            <h1 className="title">{student.name}</h1>
            <div className="details">
              <span className="course-info">
                {project.group.name}
                {'\u00A0\u00A0'}|{'\u00A0\u00A0'}
                {studentSectionNames}
                {'\u00A0\u00A0'}|{'\u00A0\u00A0'}
                Graduation Year {student.graduation_year}
                {'\u00A0\u00A0'}
              </span>
            </div>
          </div>
          <div className="actions">
            <a className="print" onClick={() => window.print()}>
              <i className="material-icons">print</i>
              Print
            </a>
            <button onClick={showStudents}>Show Students</button>
          </div>
        </div>
        <div className="container">
          <HighlightInputControl
            searchText={searchText}
            setSearchText={setSearchText}
          />
        </div>
      </header>
      <div className="responses">
        {assignments.map((a) => (
          <Response assignment={a} highlight={searchText} student={student} />
        ))}
      </div>
    </div>
  )
}

export default Reflections
