import { gql } from 'react-apollo'

export default gql(`
  mutation createAssignment(
    $questionId: ID!,
    $projectId: ID!,
    $metaReflection: Boolean
  ) {
    createAssignment(
      questionId: $questionId,
      projectId: $projectId,
      metaReflection: $metaReflection
    ) {
      id
      metaReflection
      question {
        id
        text
      }
    }
  }
`)
