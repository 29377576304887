import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import StudentTableRow from '../components/student-table-row'
import CreateUserModal from '../components/create-user-modal'
import { ApolloProvider, graphql } from 'react-apollo'
import client from '../client'
import fetchStudents from '../queries/students'
import createSection from '../mutations/create-section'
import updateSection from '../mutations/update-section'
import addSectionToProject from '../mutations/add-section-to-project'
import * as R from 'ramda'
import Fuse from 'fuse.js'
import Toggle from 'react-toggle'

@graphql(fetchStudents)
@graphql(createSection, { name: 'createSection' })
@graphql(updateSection, { name: 'updateSection' })
@graphql(addSectionToProject, { name: 'addSectionToProject' })
class SectionForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      name: props.name,
      studentIds: props.studentIds || [],
      originalStudentIds: props.studentIds || [],
      searchText: '',
      creatingUser: false,
      addStudentsNow: true,
      toggleMessage: 'Add students manually',
    }
  }

  openCreateUserModal = () => {
    this.setState({ creatingUser: true })
  }

  closeCreateUserModal = () => {
    this.props.data.refetch()

    this.setState({ creatingUser: false })
  }

  afterSubmit = (resp) => {
    const hasProjectId = window.location.search.match(/\?project_id=(\d+)/)
    if (hasProjectId) {
      const { addSectionToProject } = this.props
      const { data } = resp
      const id = hasProjectId[1]
      const sectionId = data.createSection
        ? data.createSection && data.createSection.id
        : data.updateSection && data.updateSection.id

      addSectionToProject({
        variables: { id, sectionId },
      }).then(() => {
        window.location.href = `/projects/${id}/edit`
      })
    } else {
      window.history.back()
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()

    const { name, studentIds } = this.state
    const { groupId, id, createSection, updateSection } = this.props

    if (id) {
      updateSection({
        variables: { id, name, studentIds },
      }).then(this.afterSubmit)
    } else {
      createSection({
        variables: { groupId, name, studentIds },
      }).then(this.afterSubmit)
    }
  }

  handleChange = (e) => {
    this.setState({ name: e.target.value })
  }

  handleSearch = (e) => {
    this.setState({ searchText: e.target.value })
  }

  filterStudents = (student) => {
    const { id, studentIds } = this.props

    // Exclude archived students from new sections
    if (!id && !student.active) {
      return false
    }

    // Exclude archived students from existing sections
    // which they were not part of. Uses props.studentIds
    // to ensure that archived students that used to be
    // part of the section will still be available until
    // you resave the section.
    if (
      typeof id === 'string' &&
      !student.active &&
      !studentIds.includes(student.id)
    ) {
      return false
    }

    return true
  }

  sortStudentsBySearch = (students) => {
    const { searchText } = this.state

    if (searchText === '') {
      return students
    }

    const fuseOptions = {
      shouldSort: true,
      tokenize: true,
      threshold: 1,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: ['name', 'email'],
    }

    const fuse = new Fuse(students, fuseOptions)

    return fuse.search(searchText)
  }

  isOriginallySelected(student) {
    const { originalStudentIds } = this.state
    return originalStudentIds.includes(student.id)
  }

  isSelected(student) {
    const { studentIds } = this.state
    return studentIds.includes(student.id)
  }

  alreadyAssigned(student) {
    const {
      id,
      data: { group },
    } = this.props
    const studentIds =
      group &&
      R.flatten(
        group.sections
          .filter((s) => s.id !== id)
          .map((s) => s.students.map((st) => st.id))
      )

    return studentIds.includes(student.id)
  }

  toggleSelected = (student) => {
    const { studentIds } = this.state

    if (this.isSelected(student)) {
      this.setState({
        studentIds: R.without([student.id], studentIds),
      })
    } else {
      this.setState({
        studentIds: R.append(student.id, studentIds),
      })
    }
  }

  /**
   * Sort students by relevance score if searching, otherwise sort by whether
   * they are selected, and name
   */
  sortStudents(students) {
    const self = this
    students = students.filter(this.filterStudents)

    if (this.state.searchText.length > 0) {
      return this.sortStudentsBySearch(students)
    } else {
      let sort = R.sortWith([
        R.ascend((student) => self.alreadyAssigned(student)),
        R.descend((student) => self.isOriginallySelected(student)),
        R.ascend(R.prop('name')),
      ])

      return sort(students)
    }
  }

  handleAddStudentsNowChange(addStudentsNow) {
    const addStudentsNowFlag = !addStudentsNow
    let toggleMessage
    if (addStudentsNowFlag) {
      toggleMessage = 'Add students manually'
    } else {
      toggleMessage = 'Generate Class Code'
    }
    this.setState({
      addStudentsNow: addStudentsNowFlag,
      toggleMessage: toggleMessage,
    })
  }

  render() {
    const { data, owned, readOnly, userRole } = this.props
    const {
      addStudentsNow,
      name,
      studentIds,
      searchText,
      creatingUser,
      toggleMessage,
    } = this.state

    return (
      <div className="section-form">
        <CreateUserModal
          isOpen={creatingUser}
          onClose={this.closeCreateUserModal}
        />
        <form onSubmit={this.handleSubmit}>
          <div className="header">
            <div className="field floating section-name">
              <input
                type="text"
                value={name}
                onChange={this.handleChange}
                required
                disabled={!!readOnly}
                className={readOnly ? 'valid' : ''}
              />
              <label>Section Name</label>
              <div className="highlight" />
            </div>
            {addStudentsNow && (
              <div className="search">
                <i className="material-icons">search</i>
                <input
                  type="text"
                  value={searchText}
                  onChange={this.handleSearch}
                  placeholder="Search Students..."
                />
              </div>
            )}
            {(userRole === 'Teacher' || userRole === 'School Admin') &&
              owned === 'true' && (
                <div className="section-options">
                  <label>
                    <Toggle
                      defaultChecked={addStudentsNow}
                      icons={false}
                      onChange={() =>
                        this.handleAddStudentsNowChange(addStudentsNow)
                      }
                    />
                    <div>{toggleMessage}</div>
                  </label>
                  {!addStudentsNow && (
                    <div>
                      <p>
                        A unique Class Code will be created for this Section.
                        Provide the Class Code to your students. They will paste
                        it into Refleq to join the Section.
                      </p>
                      <p>
                        Note this process relies on the students to self-enroll.
                      </p>
                      <img src="/images/enter_class_code.png" />
                    </div>
                  )}
                </div>
              )}
          </div>
          {addStudentsNow && (
            <table className="fancy">
              <thead>
                <tr>
                  <th>
                    <div>{readOnly ? 'Added' : 'Add'}</div>
                  </th>
                  <th>
                    <div>Name</div>
                  </th>
                  {!readOnly && (
                    <th>
                      <div>Email</div>
                    </th>
                  )}
                  <th>
                    <div>Graduation Year</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.students &&
                  this.sortStudents(data.students).map((s) => (
                    <StudentTableRow
                      data={s}
                      disabled={this.alreadyAssigned(s)}
                      checked={this.isSelected(s)}
                      onClick={() => (readOnly ? null : this.toggleSelected(s))}
                      readOnly={readOnly}
                    />
                  ))}
              </tbody>
            </table>
          )}
          {!readOnly && (
            <footer className="section-footer">
              <div className="container">
                <div className="content">
                  {addStudentsNow && userRole === 'Teacher' && (
                    <span className="add-user">
                      Don't see the students I need? Contact my organization's
                      ReflEQ administrator to import students, or add them
                      myself <a onClick={this.openCreateUserModal}>HERE</a>.
                    </span>
                  )}
                  {addStudentsNow && (
                    <span className="count">{studentIds.length} selected</span>
                  )}
                  <button>Save Section And Exit</button>
                </div>
              </div>
            </footer>
          )}
        </form>
      </div>
    )
  }
}

const sectionForm = document.getElementById('section-form')
if (sectionForm) {
  const readOnly = sectionForm.getAttribute('data-read-only')
  const groupId = sectionForm.getAttribute('data-group-id')
  const id = sectionForm.getAttribute('data-id')
  const organizationId = sectionForm.getAttribute('data-organization-id')
  const name = sectionForm.getAttribute('data-name')
  const studentIds = JSON.parse(sectionForm.getAttribute('data-student-ids'))
  const userRole = sectionForm.getAttribute('data-user-role')
  const owned = sectionForm.getAttribute('data-owned')
  const props = {
    groupId,
    id,
    name,
    studentIds,
    readOnly,
    organizationId,
    owned,
    userRole,
  }

  ReactDOM.render(
    <ApolloProvider client={client}>
      <SectionForm {...props} />
    </ApolloProvider>,
    sectionForm
  )
}
