import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import Group from '../components/group'
import GroupForm from '../components/group-form'
import WarningModal from '../components/warning-modal'
import { ApolloProvider, graphql } from 'react-apollo'
import client from '../client'
import fetchGroups from '../queries/group-list'

@graphql(fetchGroups)
class GroupList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      warningMsg: null,
      warningAction: null,
      activeTab: 'my-groups',
      showNewGroupForm: false,
    }
  }

  setWarning = (warningMsg, warningAction) => {
    this.setState({
      warningMsg,
      warningAction,
    })
  }

  dismissWarning = (e) => {
    e.preventDefault()

    this.setState({ warningMsg: null })
  }

  showTab = (tabName) => {
    this.setState({ activeTab: tabName })
  }

  toggleNewGroupForm = () => {
    const { showNewGroupForm } = this.state

    this.setState({ showNewGroupForm: !showNewGroupForm })
  }

  renderShowNewGroupForm = () => {
    const { showNewGroupForm } = this.state

    if (showNewGroupForm) {
      return (
        <div className="group-card new" onClick={this.toggleNewGroupForm}>
          <i className="material-icons">add_circle</i>
          <GroupForm
            isActive={showNewGroupForm}
            dismiss={this.toggleNewGroupForm}
          />
        </div>
      )
    }
  }

  renderTabControl = (tabName, tabDisplayName) => {
    const { activeTab } = this.state
    let className = ''

    if (activeTab === tabName) {
      className = 'active'
    }

    return (
      <a className={className} onClick={() => this.showTab(tabName)}>
        {tabDisplayName}
      </a>
    )
  }

  renderGroupTab = () => {
    const { role } = this.props
    const { activeTab } = this.state
    let className = ''

    if (activeTab === 'shared-groups') {
      className = 'active'
    }

    const tabDisplayName =
      role === 'Teacher' ? 'Other Groups' : 'Groups by instructors'

    return (
      <a className={className} onClick={() => this.showTab('shared-groups')}>
        {tabDisplayName}
      </a>
    )
  }

  archivedGroups = (ownedGroups, otherGroups) => {
    let other = otherGroups && otherGroups.filter((g) => g.archived)
    let owned = ownedGroups && ownedGroups.filter((g) => g.archived)
    return owned && owned.concat(other)
  }

  render() {
    const { data, currentUserId, role } = this.props
    const { warningMsg, warningAction, activeTab } = this.state
    const activeOwnedGroups =
      data.ownedGroups && data.ownedGroups.filter((g) => !g.archived)
    const activeOtherGroups =
      data.otherGroups && data.otherGroups.filter((g) => !g.archived)
    const archivedGroups = this.archivedGroups(
      data.ownedGroups,
      data.otherGroups
    )

    return (
      <div>
        <div className="header container">
          <h1>Groups</h1>
          {activeTab === 'my-groups' && (
            <nav className="actions">
              <a onClick={this.toggleNewGroupForm} className="button">
                New Group
              </a>
            </nav>
          )}
        </div>
        <nav className="tabbed-subnav" role="navigation">
          <div className="container">
            {this.renderTabControl('my-groups', 'My Groups')}
            {this.renderGroupTab()}
            {this.renderTabControl('archived-groups', 'Archived')}
          </div>
        </nav>
        <div className="group-list container">
          {activeTab === 'my-groups' && (
            <div className="group-list-section my-groups">
              {this.renderShowNewGroupForm()}
              {activeOwnedGroups &&
                activeOwnedGroups.map((g) => (
                  <Group
                    data={g}
                    owned
                    currentUserId={currentUserId}
                    setWarning={this.setWarning}
                  />
                ))}
            </div>
          )}
          {activeTab === 'shared-groups' && (
            <div className="group-list-section shared-groups">
              {this.renderShowNewGroupForm()}
              {activeOtherGroups &&
                activeOtherGroups.map((g) => (
                  <Group
                    data={g}
                    currentUserId={currentUserId}
                    setWarning={this.setWarning}
                    role={role}
                  />
                ))}
            </div>
          )}
          {activeTab === 'archived-groups' && (
            <div className="group-list-section archived-groups">
              {this.renderShowNewGroupForm()}
              {archivedGroups &&
                archivedGroups.map((g) => (
                  <Group
                    data={g}
                    owned
                    currentUserId={currentUserId}
                    archived
                    setWarning={this.setWarning}
                    role={role}
                  />
                ))}
            </div>
          )}
          <WarningModal
            dismissText="No"
            isOpen={warningMsg}
            onClose={this.dismissWarning}
            onConfirm={warningAction}
          >
            <h2
              style={{ marginTop: 48, marginBottom: 54 }}
              dangerouslySetInnerHTML={{ __html: warningMsg }}
            />
          </WarningModal>
        </div>
      </div>
    )
  }
}

const groupsList = document.getElementById('groups-list')
if (groupsList) {
  const role = groupsList.dataset.role
  const currentUserId = groupsList.dataset.id

  ReactDOM.render(
    <ApolloProvider client={client}>
      <GroupList role={role} currentUserId={currentUserId} />
    </ApolloProvider>,
    groupsList
  )
}
