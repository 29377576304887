import React from 'react'
import ReactDOM from 'react-dom'
import OrganizationReportForm from '../components/organization-report-form'
import LearnerTypeChart from '../components/learner-type-chart'

const organizationReportForm = document.getElementById(
  'organization-report-form'
)
if (organizationReportForm) {
  const startDate = organizationReportForm.getAttribute('data-start-date')
  const minStartDate = organizationReportForm.getAttribute(
    'data-min-start-date'
  )
  const endDate = organizationReportForm.getAttribute('data-end-date')
  const organizationId = organizationReportForm.getAttribute(
    'data-organization-id'
  )

  const props = { startDate, endDate, minStartDate, organizationId }

  ReactDOM.render(<OrganizationReportForm {...props} />, organizationReportForm)
}

const reportLearnerTypeChart = document.getElementById(
  'report-learner-type-chart'
)
if (reportLearnerTypeChart) {
  const assignments = JSON.parse(
    reportLearnerTypeChart.getAttribute('data-assignments')
  )

  ReactDOM.render(
    <LearnerTypeChart questions={assignments} />,
    reportLearnerTypeChart
  )
}
