import React, { Component } from 'react'
import { clone } from 'ramda'
import { StickyContainer, Sticky } from 'react-sticky'
import Quote from '../components/quote'
import Spinner from '../components/spinner'
import JournalExportModal from '../components/journal-export-modal'
import AdditionalThoughtsModal from '../components/additional-thoughts-modal'
import ReflectionForm from '../components/reflection-form'
import ReactDOM from 'react-dom'
import { ApolloProvider, graphql } from 'react-apollo'
import client from '../client'
import fetchReflection from '../queries/reflection'
import { dateFromISO8601, formatDate } from '../utils'
import { sort } from 'ramda'
import PostscriptText from '../components/postscript-text'

const formattedTime = (timestamp) => {
  return formatDate(timestamp, 'ddd, MMMM D [at] h:mma')
}

const formattedDate = (timestamp) => {
  return formatDate(timestamp, 'MMM d, YYYY')
}

const byDueAtAsc = (a, b) => {
  return a.dueAt && b.dueAt
    ? dateFromISO8601(a.dueAt) - dateFromISO8601(b.dueAt)
    : 0
}

const copyToClipboard = (text) => {
  if (window.clipboardData && window.clipboardData.setData) {
    // IE specific code path to prevent textarea
    // being shown while dialog is visible.
    return window.clipboardData.setData('Text', text)
  } else if (
    document.queryCommandSupported &&
    document.queryCommandSupported('copy')
  ) {
    const textarea = document.createElement('textarea')
    textarea.textContent = text
    // Prevent scrolling to bottom of page in MS Edge.
    textarea.style.position = 'fixed'
    document.body.appendChild(textarea)
    textarea.select()

    try {
      // Security exception may be thrown by some browsers.
      return document.execCommand('copy')
    } catch (ex) {
      console.warn('Copy to clipboard failed.', ex)
      return false
    } finally {
      document.body.removeChild(textarea)
    }
  }
}

const sortReflections = (assignments) =>
  assignments.sort(
    (a, b) =>
      dateFromISO8601(a.answer.submittedAt) -
      dateFromISO8601(b.answer.submittedAt)
  )

@graphql(fetchReflection)
class Reflection extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeAssignmentId: null,
      quoteOpacity: 1,
      shareCopied: false,
      saving: false,
      initialLoad: true,
      exportModal: false,
      exportList: [],
      additionalThoughtsOpen: false,
      additionalQuestion: null,
    }
  }

  componentDidUpdate() {
    // handle scrolling to active question
    const formId = window.location.hash.split('#')[1]

    if (formId && this.state.initialLoad) {
      const assignmentId = window.location.hash.split('-')[1]
      this.setState(
        {
          initialLoad: false,
          activeAssignmentId: assignmentId,
        },
        () => {
          const form = document.getElementById(formId)
          if (form) {
            form.scrollIntoView({ inline: 'start' })
          }
        }
      )
    }
  }

  toggleAdditionalThoughts = (assignment) => {
    const { additionalThoughtsOpen } = this.state
    if (additionalThoughtsOpen) {
      const { data } = this.props
      data.refetch()
    }
    this.setState({
      additionalThoughtsOpen: !additionalThoughtsOpen,
      additionalQuestion: assignment,
    })
  }

  openExportModal = (e) => {
    const {
      data: { project },
    } = this.props
    const ids = project.completedAssignments
      .filter((a) => !a.metaReflection)
      .map((i) => i.id)
    e.preventDefault()
    this.setState({ exportModal: true, exportList: ids })
  }

  handleExport = (e) => {
    e.preventDefault()
    const { exportList } = this.state
    const { id, currentUserPartial } = this.props

    const base = 'https://refleq-export.herokuapp.com/api/render'
    const options = [
      'emulateScreenMedia=false',
      'pdf.margin.top=1cm',
      'pdf.margin.bottom=1cm',
      'pdf.margin.left=1cm',
      'pdf.margin.right=1cm',
      `attachmentName=Refleq${currentUserPartial}${id}.pdf`,
    ].join('&')
    const exportURL = `${base}?url=${
      this.props.shareURL
    }&${options}&ids=${exportList.join('|')}`
    window.open(exportURL)
    this.setState({ exportModal: false })
  }

  handlePrint = (e) => {
    e.preventDefault()

    window.print()
  }

  handleShare = (e) => {
    e.preventDefault()

    copyToClipboard(this.props.shareURL)

    this.setState({ shareCopied: true }, () => {
      setTimeout(() => {
        this.setState({ shareCopied: false })
      }, 10000)
    })
  }

  handleShowQuote = () => {
    const {
      data: { randomQuote },
    } = this.props
    const header = document.getElementById('reflection')
    setTimeout(() => {
      header.scrollIntoView({ behavior: 'smooth' })
    }, 300)
    setTimeout(() => {
      this.setState({ randomQuote })
    }, 800)
  }

  handleSave = (callback = () => {}) => {
    this.setState({ saving: true, randomQuote: null }, () => {
      setTimeout(() => {
        this.setState({ saving: false }, callback)
      }, 3000)
    })
  }

  setActiveAssignment = (id) => {
    this.setState({ activeAssignmentId: id })
  }

  saveAndExit = () => {
    this.handleSave(() => {
      window.location.href = '/'
    })
  }

  handleClose = () => {
    this.setState({ exportModal: false })
  }

  toggleChecked = (id) => {
    let { exportList } = this.state
    let exists = exportList.find((i) => {
      return i === id
    })
    if (exists) {
      exportList = exportList.filter((val) => val !== id)
    } else {
      exportList.push(id)
    }
    this.setState({ exportList: exportList })
  }

  isChecked = (id) => {
    const { exportList } = this.state
    if (
      exportList.find((i) => {
        return i === id
      })
    )
      return true
    return false
  }

  groupAssignments = (assignments) => {
    let container = []

    let currentGroup = []

    let currentType = null
    assignments.forEach((element) => {
      if (currentType === null) {
        currentGroup.push(element)
        currentType = element.metaReflection
      } else {
        if (element.metaReflection === currentType) {
          currentGroup.push(element)
        } else {
          currentType = element.metaReflection
          container.push(clone(currentGroup))
          currentGroup = []
          currentGroup.push(element)
        }
      }
    })
    container.push(currentGroup)
    return container
  }

  render() {
    const {
      additionalThoughtsOpen,
      additionalQuestion,
      saving,
      shareCopied,
      quoteOpacity,
      randomQuote,
      exportModal,
      exportList,
    } = this.state
    const {
      data: { project },
    } = this.props
    if (!project) return null

    const { completedAssignments, outstandingAssignments } = project
    window.outstandingAssignments = outstandingAssignments
    const assignments = outstandingAssignments.concat(completedAssignments)

    const mods =
      assignments &&
      assignments.map((a) => a.answer && dateFromISO8601(a.answer.updatedAt))
    const lastModified = Math.max(...mods)

    const activeAssignmentId =
      this.state.activeAssignmentId ||
      (outstandingAssignments[0] && outstandingAssignments[0].id)

    const shareCopiedClasses = shareCopied
      ? 'share-copied active'
      : 'share-copied'
    const exportable = sortReflections(
      completedAssignments.filter((a) => !a.metaReflection)
    )
    const groupedAssignments = this.groupAssignments(
      sortReflections(completedAssignments)
    )
    return (
      <div className="reflection">
        {(randomQuote ||
          (outstandingAssignments && outstandingAssignments.length > 0)) && (
          <StickyContainer className="time-to-reflect">
            <Sticky topOffset={40} bottomOffset={40}>
              {({ isSticky, style }) => {
                const headingClass = isSticky ? 'sticky' : ''
                return (
                  <h2 className={headingClass} style={style}>
                    Time to reflect!
                    <div className="save-info">
                      {saving ? (
                        <div className="save-indicator">
                          <Spinner />
                          <span>Saving...</span>
                        </div>
                      ) : (
                        <span className="last-modified">
                          {lastModified
                            ? `Last edited ${formattedTime(lastModified)}`
                            : ''}
                        </span>
                      )}
                      <button onClick={this.saveAndExit}>Save And Exit</button>
                    </div>
                  </h2>
                )
              }}
            </Sticky>
            {randomQuote && (
              <Quote quote={randomQuote} opacity={quoteOpacity} />
            )}
            {outstandingAssignments &&
              sort(byDueAtAsc, outstandingAssignments).map((a) => (
                <ReflectionForm
                  id={project.id}
                  active={a.id === activeAssignmentId}
                  data={a}
                  handleFocus={this.setActiveAssignment}
                  key={a.id}
                  onSave={this.handleSave}
                  onSuccess={this.handleShowQuote}
                />
              ))}
          </StickyContainer>
        )}
        <div className="completed-reflections">
          {completedAssignments.length > 0 && (
            <StickyContainer>
              <Sticky>
                {({ isSticky, style }) => {
                  const headingClass = isSticky ? 'sticky' : ''
                  return (
                    <h2 className={headingClass} style={style}>
                      Reflection
                      <div className="actions">
                        <a onClick={this.handlePrint}>
                          <i className="material-icons">local_printshop</i>
                          Print
                        </a>
                        <a onClick={this.openExportModal}>
                          <i className="material-icons">picture_as_pdf</i>
                          Export
                        </a>
                        <a onClick={this.handleShare}>
                          <div className={shareCopiedClasses}>
                            <i className="material-icons">check_circle</i>
                            <span
                              onClick={() => {
                                window.open(this.props.shareURL, '_blank')
                              }}
                            >
                              Public link copied!
                            </span>
                          </div>
                          <i className="material-icons">reply</i>
                          Share
                        </a>
                      </div>
                    </h2>
                  )
                }}
              </Sticky>
              {groupedAssignments && (
                <div>
                  {groupedAssignments.map((group) => (
                    <div className="completed-assignments">
                      {group.map((a) => (
                        <div className="assignment" key={a.id}>
                          {a.metaReflection && (
                            <label className="meta-reflection">
                              Meta-Reflection
                            </label>
                          )}
                          <div className="submitted-at">
                            {a.answer.submittedAt > a.dueAt && (
                              <div className="badge">Late</div>
                            )}
                            Submitted {formattedTime(a.answer.submittedAt)}
                          </div>
                          <label>{a.question.text}</label>
                          <div className="text">
                            {a.answer.text}
                            <PostscriptText answer={a.answer} />
                          </div>
                          {!a.answer.postscript && (
                            <div class="subheader">
                              <a
                                onClick={() => this.toggleAdditionalThoughts(a)}
                              >
                                <i class="material-icons">add_circle</i>
                                Add more
                              </a>
                            </div>
                          )}
                          {a.answer.evidence && (
                            <a className="evidence" href={a.answer.evidence}>
                              <i className="material-icons">attach_file</i>
                              {decodeURI(
                                a.answer.evidence
                                  .split('/')
                                  .pop()
                                  .replace(/\?.+/, '')
                              )}
                            </a>
                          )}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              )}
            </StickyContainer>
          )}
        </div>
        <JournalExportModal
          exportModal={exportModal}
          handleClose={this.handleClose}
          exportable={exportable}
          exportList={exportList}
          handleExport={this.handleExport}
          toggleChecked={this.toggleChecked}
          isChecked={this.isChecked}
        />
        {additionalQuestion && (
          <AdditionalThoughtsModal
            isOpen={additionalThoughtsOpen}
            assignment={additionalQuestion}
            id={10}
            onClose={this.toggleAdditionalThoughts}
          />
        )}
      </div>
    )
  }
}

const reflection = document.getElementById('reflection')
if (reflection) {
  const id = reflection.getAttribute('data-id')
  const shareURL = reflection.getAttribute('data-share-url')
  const currentUserPartial = reflection.getAttribute('data-current-user')
  ReactDOM.render(
    <ApolloProvider client={client}>
      <Reflection
        id={id}
        shareURL={shareURL}
        currentUserPartial={currentUserPartial}
      />
    </ApolloProvider>,
    reflection
  )
}
