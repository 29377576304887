import React, { Component } from 'react'
import Modal from 'react-modal'
import Pulse from './pulse'
import PulseAnonymous from './pulse-anonymous'
import { graphql } from 'react-apollo'
import findPulse from '../queries/find-pulse'

const className = {
  base: 'full-modal',
  afterOpen: 'modal-after-open',
  beforeClose: 'modal-before-close',
}

const overlayClassName = {
  base: 'overlay',
  afterOpen: 'overlay-after-open',
  beforeClose: 'overlay-before-close',
}

@graphql(findPulse, {
  options: { pollInterval: 5000 },
  skip: (ownProps) => !ownProps.id,
})
class PulseModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      anonymous: true,
      searchText: '',
    }
  }

  toggleAnonymous = () => {
    const { anonymous } = this.state
    this.setState({ anonymous: !anonymous })
  }

  handleClose = (e) => {
    e.preventDefault()
    this.setState({ anonymous: true })
    const { onClose } = this.props
    onClose(null)
  }

  setViewReflectionsStudent = (student) => {
    const { onClose } = this.props
    this.props.setViewReflectionsStudent(student)
    setTimeout(() => onClose(null), 300)
  }

  setSearchText = (val) => {
    this.setState({ searchText: val })
  }

  render() {
    const { data, onClose } = this.props
    const { searchText } = this.state

    if (!data || !data.findPulse) {
      return <span>nothing</span>
    }

    const { findPulse } = data
    const assignment = findPulse
    const { anonymous } = this.state
    const title = anonymous ? 'The Pulse (Anonymized)' : 'The Pulse'

    return (
      <Modal
        isOpen={!!assignment}
        onRequestClose={this.handleClose}
        contentLabel={'Pulse Modal'}
        className={className}
        overlayClassName={overlayClassName}
      >
        <header className="full-modal-header">
          <a href="/" className="logo">
            <img src="/images/logo.png" className="logo" alt="" />
          </a>
          <h2>{title}</h2>
          <nav role="navigation" aria-label="App Navigation">
            <a href="#" onClick={this.handleClose}>
              Close
              <i className="material-icons">close</i>
            </a>
          </nav>
        </header>
        {anonymous ? (
          <PulseAnonymous
            assignment={assignment}
            toggleAnonymous={this.toggleAnonymous}
            searchText={searchText}
            setSearchText={this.setSearchText}
          />
        ) : (
          <Pulse
            assignment={assignment}
            toggleAnonymous={this.toggleAnonymous}
            searchText={searchText}
            setSearchText={this.setSearchText}
            setViewReflectionsStudent={this.setViewReflectionsStudent}
            returnTo={() => onClose(assignment)}
          />
        )}
      </Modal>
    )
  }
}

export default PulseModal
