import { gql } from 'react-apollo'

export default gql`
  query {
    adminQuestions {
      id
      text
      stage
      category {
        id
        name
      }
      learnerType
      archived
      preview
      pAndA
      assignmentCount
      updatedAt
    }
  }
`
