const toggleButtons = document.querySelectorAll('.question-category-toggle')

toggleButtons.forEach((button) => {
  button.addEventListener('click', (e) => {
    e.preventDefault()
    const id = e.currentTarget.dataset.id
    const state = e.currentTarget.dataset.state
    const input = document.getElementById(
      `question_category_${e.currentTarget.dataset.id}`
    )

    switch (state) {
      case 'show':
        e.currentTarget.classList.remove('show')
        e.currentTarget.classList.add('inactive')
        e.currentTarget.dataset.state = 'inactive'
        input.value = ''
        break
      case 'inactive':
        e.currentTarget.classList.remove('inactive')
        e.currentTarget.classList.add('hide')
        e.currentTarget.dataset.state = 'hide'
        input.value = -e.currentTarget.dataset.id
        break
      default:
        e.currentTarget.classList.remove('hide')
        e.currentTarget.classList.add('show')
        e.currentTarget.dataset.state = 'show'
        input.value = e.currentTarget.dataset.id
        break
    }
  })
})
