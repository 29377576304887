import { gql } from 'react-apollo'

export default gql`
  mutation markRead($id: ID!) {
    markRead(id: $id) {
      id
      read
    }
  }
`
