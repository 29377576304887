import { gql } from 'react-apollo'

export default gql`
  query projectList {
    projects {
      id
      name
      inserted_at
      collaborator {
        id
        name
        email
      }
      assignments {
        id
      }
      liveQuestions {
        id
      }
      sections {
        id
        name
        group {
          id
          name
          color
        }
        students {
          id
          name
        }
      }
    }
    sharedProjects {
      id
      name
      inserted_at
      owner {
        id
        name
      }
      assignments {
        id
      }
      liveQuestions {
        id
      }
      sections {
        id
        name
        group {
          id
          name
          color
        }
        students {
          id
          name
        }
      }
    }
  }
`
