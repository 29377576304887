import { gql } from 'react-apollo'

export default gql(`
  mutation createQuestion($text: String!, $stage: String!, $categoryId: Int!, $learnerType: String!, $preview: Boolean!, $pAndA: Boolean!) {
    createQuestion(text: $text, stage: $stage, categoryId: $categoryId, learnerType: $learnerType, preview: $preview, pAndA: $pAndA) {
      id
      text
      stage
      category {
        id
        name
      }
      learnerType
      archived
      preview
      pAndA
    }
  }
`)
