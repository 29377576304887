import { gql } from 'react-apollo'

export default gql`
  query reflection($id: ID!) {
    project(id: $id) {
      id
      name
      completedAssignments {
        id
        metaReflection
        requireEvidence
        allowLate
        assignedAt
        dueAt
        answer {
          id
          text
          evidence
          submittedAt
          updatedAt
          postscript {
            id
            text
            insertedAt
          }
        }
        question {
          id
          text
        }
      }
      outstandingAssignments {
        id
        metaReflection
        requireEvidence
        allowLate
        assignedAt
        dueAt
        answer {
          id
          text
          evidence
          submittedAt
          updatedAt
        }
        question {
          id
          text
        }
      }
    }
    randomQuote {
      text
      attribution
    }
  }
`
