import React, { Component } from 'react'

class Tooltip extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: props.isOpen,
    }
  }

  toggleIsOpen = (e) => {
    e.stopPropagation()
    const { isOpen } = this.state
    this.setState({ isOpen: !isOpen })
  }

  render() {
    const { children } = this.props
    const { isOpen } = this.state
    const infoClasses = isOpen ? 'info active' : 'info'

    return (
      <div className="tooltip">
        <div className="trigger" onClick={this.toggleIsOpen}>
          <i className="material-icons">info</i>
        </div>
        <div className={infoClasses} onClick={this.toggleIsOpen}>
          {children}
        </div>
      </div>
    )
  }
}

export default Tooltip
