import React, { Component } from 'react'
import GroupForm from './group-form'
import { graphql } from 'react-apollo'
import fetchGroups from '../queries/group-list'
// import deleteSection from '../mutations/delete-section'
// import deleteGroup from '../mutations/delete-group'
import archiveSection from '../mutations/archive-section'
import archiveGroup from '../mutations/archive-group'
import unArchiveGroup from '../mutations/un-archive-group'
import duplicateGroup from '../mutations/duplicate-group'

const goToSectionsNew = (groupId) => {
  const { search } = window.location
  window.location.href = `/groups/${groupId}/sections/new${search}`
}

const goToSectionsEdit = (groupId, sectionId) => {
  const { search } = window.location
  window.location.href = `/groups/${groupId}/sections/${sectionId}/edit${search}`
}

const goToSectionsShow = (groupId, sectionId) => {
  const { search } = window.location
  window.location.href = `/groups/${groupId}/sections/${sectionId}${search}`
}

const Dropdown = ({
  owned,
  archived,
  isActive,
  dismiss,
  onCopy,
  onEdit,
  onDelete,
  onUnArchive,
  role,
}) => {
  const styles = isActive ? { visibility: 'visible', opacity: 1 } : {}
  if (owned) {
    return (
      <div className="dropdown" style={styles}>
        <div className="dismiss" onClick={dismiss}>
          <i className="material-icons">more_horiz</i>
        </div>
        {!archived && <a onClick={onCopy}>Duplicate</a>}
        {!archived && <a onClick={onEdit}>Edit</a>}
        {!archived && <a onClick={onDelete}>Archive</a>}
        {archived && <a onClick={onUnArchive}>Un-Archive</a>}
      </div>
    )
  } else if (role === 'School Admin') {
    return (
      <div className="dropdown" style={styles}>
        <div className="dismiss" onClick={dismiss}>
          <i className="material-icons">more_horiz</i>
        </div>
        {!archived && <a onClick={onDelete}>Archive</a>}
        {archived && <a onClick={onUnArchive}>Un-Archive</a>}
      </div>
    )
  } else {
    return null
  }
}

const Section = ({ owned, name, code, students, onEdit, onDelete }) => {
  return (
    <li className="section">
      <div className="section-name">{name}</div>
      <div className="section-code">{code}</div>
      <div className="section-actions">
        <a className="section-edit" onClick={onEdit}>
          {students.length} Students
        </a>
        {owned && (
          <a className="section-delete" onClick={onDelete} title="Archive">
            <i className="material-icons">archive</i>
          </a>
        )}
      </div>
    </li>
  )
}

// @graphql(deleteSection, { name: 'deleteSection' })
// @graphql(deleteGroup, { name: 'deleteGroup' })
@graphql(archiveSection, { name: 'archiveSection' })
@graphql(archiveGroup, { name: 'archiveGroup' })
@graphql(unArchiveGroup, { name: 'unArchiveGroup' })
@graphql(duplicateGroup, { name: 'duplicateGroup' })
class Group extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showDropdown: false,
      showForm: false,
    }
  }

  toggleForm = () => {
    const { showForm } = this.state
    this.setState({
      showForm: !showForm,
      showDropdown: false,
    })
  }

  copyGroup = () => {
    const { data, duplicateGroup } = this.props

    duplicateGroup({
      refetchQueries: [{ query: fetchGroups }],
      variables: { id: data.id },
    })

    this.setState({ showDropdown: false })
  }

  deleteGroup = () => {
    const { data, archiveGroup, setWarning } = this.props

    archiveGroup({
      refetchQueries: [{ query: fetchGroups }],
      variables: { id: data.id },
    })

    setWarning(null, null)
    this.setState({ showDropdown: false })
  }

  unArchiveGroup = () => {
    const { data, unArchiveGroup, setWarning } = this.props

    unArchiveGroup({
      refetchQueries: [{ query: fetchGroups }],
      variables: { id: data.id },
    })

    setWarning(null, null)
    this.setState({ showDropdown: false })
  }

  deleteSection = (id) => {
    const { archiveSection, setWarning } = this.props

    archiveSection({
      refetchQueries: [{ query: fetchGroups }],
      variables: { id },
    })

    setWarning(null, null)
  }

  render() {
    const { data, archived, setWarning, currentUserId, role } = this.props
    const { showDropdown, showForm } = this.state

    const owned = currentUserId === data.owner.id

    if (!data) return null // TODO: consider adding loading state

    return (
      <div className="group-card">
        <div
          className="group-header"
          style={{ backgroundColor: `#${data.color}` }}
        >
          {data.name}{' '}
          {currentUserId !== data.owner.id && data.owner.role === 'Teacher' && (
            <span>({data.owner.lastName})</span>
          )}
          {currentUserId !== data.owner.id && data.owner.role !== 'Teacher' && (
            <span>(Admin)</span>
          )}
          <div>
            <div>
              <div
                className="dropdown-toggle"
                onClick={() => this.setState({ showDropdown: true })}
              >
                {(owned || role === 'School Admin') && (
                  <i className="material-icons">more_horiz</i>
                )}
              </div>
              <Dropdown
                owned={owned}
                archived={archived}
                groupId={data.id}
                isActive={showDropdown}
                onCopy={this.copyGroup}
                onEdit={this.toggleForm}
                onDelete={() =>
                  setWarning(
                    "I’m finished working with this particular class. <ul style='font-size: 16px;text-align:left;'><li>Archive cleans up your Groups area (end of semester / academic year)</li><li>Create a new Group when you teach a new class or different students</li></ul>",
                    this.deleteGroup
                  )
                }
                onUnArchive={() =>
                  setWarning(
                    "I'm still working with this class and group of students",
                    this.unArchiveGroup
                  )
                }
                dismiss={() => this.setState({ showDropdown: false })}
                role={role}
              />
            </div>
          </div>
        </div>
        <div className="group-details">
          <ul className="group-sections">
            {data.sections &&
              data.sections
                .filter((s) => !s.archived)
                .map((s) => {
                  const link = () =>
                    owned
                      ? goToSectionsEdit(data.id, s.id)
                      : goToSectionsShow(data.id, s.id)
                  return (
                    <Section
                      owned={owned}
                      groupId={data.id}
                      onEdit={link}
                      onDelete={() =>
                        setWarning(
                          'Are you sure you want to delete this section?',
                          () => this.deleteSection(s.id)
                        )
                      }
                      {...s}
                    />
                  )
                })}
          </ul>
          {owned && (
            <button
              className="section-new"
              onClick={() => goToSectionsNew(data.id)}
            >
              + Add a section
            </button>
          )}
        </div>
        {owned && (
          <GroupForm {...data} isActive={showForm} dismiss={this.toggleForm} />
        )}
      </div>
    )
  }
}

export default Group
