import { gql } from 'react-apollo'

export default gql`
  mutation createSection($groupId: ID!, $name: String!, $studentIds: [ID]!) {
    createSection(groupId: $groupId, name: $name, studentIds: $studentIds) {
      id
      name
      students {
        id
        name
        email
        graduation_year
      }
    }
  }
`
