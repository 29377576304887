import React, { Component } from 'react'
import DateInput from './date-input'
import TimeInput from './time-input'
import Tooltip from './tooltip'
import WarningModal from '../components/warning-modal'
import { graphql } from 'react-apollo'
import updateAssignment from '../mutations/update-assignment'
import deleteAssignment from '../mutations/delete-assignment'
import { dateFromISO8601 } from '../utils'
import {
  addMinutes,
  addDays,
  setMinutes,
  setHours,
  getHours,
  getMinutes,
} from 'date-fns'

@graphql(updateAssignment, { name: 'updateAssignment' })
@graphql(deleteAssignment, { name: 'deleteAssignment' })
class AssignmentForm extends Component {
  constructor(props) {
    super(props)

    const currentTime = new Date()
    const assignedAt = props.assignedAt && dateFromISO8601(props.assignedAt)
    const dueAt = props.dueAt && dateFromISO8601(props.dueAt)
    const assignedAtDefault = currentTime
    const editMode = props.dueAt && props.assignedAt

    this.state = {
      allowLate: props.allowLate || true,
      assignedAt: assignedAt || assignedAtDefault,
      dueAt: dueAt || addMinutes(assignedAtDefault, 15),
      requireEvidence: props.requireEvidence,
      notifyStudents: props.notifyStudents || false,
      assignNow: props.assignedAt === null,
      assignLater: props.assignedAt !== null,
      dateAssigned: props.assignedAt !== null,
      timeAssigned: props.assignedAt !== null,
      dateDueAssigned: props.dueAt !== null,
      timeDueAssigned: props.dueAt !== null,
      validAssignment: false,
      currentTime: currentTime,
      warningOpen: false,
      notifyStudentsDueChange: false,
      editMode: editMode,
      missingDueTime: false,
      unassignPrompt: false,
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    const assignedAt = props.assignedAt && dateFromISO8601(props.assignedAt)
    const dueAt = props.dueAt && dateFromISO8601(props.dueAt)

    this.setState({
      allowLate: true, // props.allowLate,
      assignedAt: assignedAt,
      dueAt: dueAt,
      requireEvidence: props.requireEvidence,
    })
  }

  componentDidMount() {
    this.validateAssignment()
  }

  handleSave = () => {
    const {
      data,
      deleteAssignment,
      id,
      onCancel,
      onMetaSave,
      metaReflection,
      updateAssignment,
    } = this.props
    const {
      allowLate,
      notifyStudents,
      assignedAt,
      dueAt,
      requireEvidence,
      validAssignment,
      notifyStudentsDueChange,
      unassignPrompt,
    } = this.state

    if (unassignPrompt) {
      deleteAssignment({
        variables: { id },
      }).then(() => {
        onCancel()
        data.refetch()
      })
      return
    }

    const variables = {
      id,
      allowLate,
      requireEvidence,
      notifyStudents,
      notifyStudentsDueChange,
    }
    if (assignedAt) {
      variables.assignedAt = assignedAt
    }
    if (dueAt) {
      variables.dueAt = dueAt
    }

    if (!validAssignment) {
      this.setState({ warningOpen: true })
      return
    }

    updateAssignment({
      variables,
    }).then((data) => {
      if (data.payload && data.payload.errors) {
        // TODO: display errors
        console.log(data.payload.errors)
      } else {
        if (metaReflection && assignedAt && dueAt) {
          onMetaSave()
        } else {
          onCancel()
        }
      }
    })
  }

  handleTimeAssignedAt = (hrs, mins) => {
    const { assignedAt, dateAssigned } = this.state
    let newassignedAt = setHours(assignedAt, hrs)
    newassignedAt = setMinutes(newassignedAt, mins)
    this.setState({ timeAssigned: true })
    this.handleAssignedAt(newassignedAt, dateAssigned)
    this.validateAssignment()
  }

  handleTimeDueAt = (hrs, mins) => {
    const { dueAt, dateDueAssigned } = this.state
    let newDueAt = setHours(dueAt, hrs)
    newDueAt = setMinutes(newDueAt, mins)
    this.setState({ timeDueAssigned: true })
    this.handleDueAt(newDueAt, dateDueAssigned)
    this.validateAssignment()
  }

  handleAssignedAt = (d, dateAssigned = true) => {
    let stateChange = { assignedAt: d, dateAssigned: dateAssigned }

    this.setState(stateChange)
    this.validateAssignment()
  }

  handleCancelComplete = () => {
    this.props.onCancel()
  }

  handleDueAt = (d, dateDueAssigned = true) => {
    this.setState({ dueAt: d, dateDueAssigned: dateDueAssigned })
    this.validateAssignment()
  }

  handleAssignNow = () => {
    const { assignNow, currentTime, editMode } = this.state
    if (editMode) {
      return
    }
    if (assignNow === false) {
      const mins = getMinutes(currentTime)
      const hrs = getHours(currentTime)
      this.handleTimeAssignedAt(hrs, mins)
      this.handleAssignedAt(currentTime)
      this.setState({
        assignNow: !assignNow,
        assignLater: assignNow,
        timeAssigned: false,
        dateAssigned: false,
      })
    }
    this.validateAssignment()
  }

  handleAssignLater = () => {
    const { assignLater, editMode } = this.state
    if (editMode) {
      return
    }
    if (assignLater === false) {
      this.setState({ assignLater: !assignLater, assignNow: assignLater })
    }
    this.validateAssignment()
  }

  toggleAllowLate = () => {
    // TODO: handle allow late
    // const { allowLate } = this.state
    // this.setState({allowLate: !allowLate})
  }

  toggleNotifyStudents = (e) => {
    e && e.preventDefault()
    const { notifyStudents } = this.state
    this.setState({ notifyStudents: !notifyStudents })
  }

  toggleRequireEvidence = (e) => {
    e && e.preventDefault()
    const { requireEvidence } = this.state
    this.setState({ requireEvidence: !requireEvidence })
  }

  toggleNotifyStudentsDueChange = (e) => {
    e && e.preventDefault()
    const { notifyStudentsDueChange } = this.state
    this.setState({ notifyStudentsDueChange: !notifyStudentsDueChange })
  }

  toggleUnassignPrompt = (e) => {
    e && e.preventDefault()
    const { unassignPrompt } = this.state
    this.setState({ unassignPrompt: !unassignPrompt })
  }

  validateAssignment = () => {
    setTimeout(() => {
      const {
        dateAssigned,
        timeAssigned,
        dateDueAssigned,
        assignedAt,
        dueAt,
        currentTime,
        timeDueAssigned,
        assignNow,
        editMode,
      } = this.state

      if (!timeDueAssigned) {
        this.setState({ validAssignment: false, missingDueTime: true })
        return
      }

      const dueAtFloor = addMinutes(assignedAt, 15)

      // if it is an edit we only care about the due date being after the assigned date
      if (editMode && dueAt.getTime() >= dueAtFloor.getTime()) {
        this.setState({ validAssignment: true, missingDueTime: false })
        return
      }

      if (
        assignNow &&
        dateDueAssigned &&
        timeDueAssigned &&
        assignedAt.getTime() >= currentTime.getTime() &&
        dueAt.getTime() >= dueAtFloor.getTime()
      ) {
        this.setState({ validAssignment: true, missingDueTime: false })
        return
      }

      if (
        dateAssigned &&
        timeAssigned &&
        dateDueAssigned &&
        timeDueAssigned &&
        assignedAt.getTime() >= currentTime.getTime() &&
        dueAt.getTime() >= dueAtFloor.getTime()
      ) {
        this.setState({ validAssignment: true, missingDueTime: false })
        return
      }

      this.setState({ validAssignment: false, missingDueTime: false })
    }, 100)
  }

  toggleWarning = (e) => {
    e && e.preventDefault()
    const { warningOpen } = this.state
    this.setState({ warningOpen: !warningOpen })
  }

  render() {
    const {
      allowLate,
      assignedAt,
      dueAt,
      requireEvidence,
      notifyStudents,
      assignNow,
      assignLater,
      dateAssigned,
      timeAssigned,
      dateDueAssigned,
      timeDueAssigned,
      warningOpen,
      notifyStudentsDueChange,
      unassignPrompt,
      missingDueTime,
      editMode,
    } = this.state
    const { question, onCancel, metaReflection, submittedAnswers } = this.props
    const { text } = question
    const currentTime = new Date()
    const assignedAtDefault = currentTime

    return (
      <div className="assignment-form">
        <div className="heading">
          <h1>{text}</h1>
        </div>
        {metaReflection && (
          <div className="warning">
            <i className="material-icons">info</i>
            <span>
              Meta-reflections are assigned at the end of a journal. They
              encourage a look-back at previous reflections to build deeper
              insight.
            </span>
          </div>
        )}
        {!unassignPrompt && (
          <section>
            <div className="now-or-later">
              <div className="field now-or-later small-label">
                {editMode && 'Assigned to students'}
                {!editMode && 'Assign to students'}
              </div>
              <div
                className="field now-or-later-inputs"
                onClick={this.handleAssignNow}
              >
                {!editMode && (
                  <section>
                    <input
                      type="checkbox"
                      name="assign-now"
                      id="assign-now"
                      checked={assignNow}
                      readOnly={editMode}
                    />
                    <label htmlFor="assign-now">Now</label>
                  </section>
                )}
              </div>
              <div
                className="field now-or-later-inputs"
                onClick={this.handleAssignLater}
              >
                {!editMode && (
                  <section>
                    <input
                      type="checkbox"
                      name="assign-later"
                      id="assign-later"
                      checked={assignLater}
                      readOnly={editMode}
                    />
                    <label htmlFor="assign-later">Schedule</label>
                  </section>
                )}
              </div>
            </div>
            <div className="fields">
              <div className="assignment-datetime">
                <DateInput
                  datetime={assignedAt}
                  min={assignedAtDefault}
                  max={addDays(assignedAtDefault, 8)}
                  onChange={this.handleAssignedAt}
                  readOnly={editMode || assignNow}
                  dateAssigned={dateAssigned}
                />
                <TimeInput
                  datetime={assignedAt}
                  onChange={this.handleTimeAssignedAt}
                  readOnly={editMode || assignNow}
                  timeAssigned={timeAssigned}
                />
              </div>
              <div className="now-or-later">
                <div className="field due-date small-label">
                  {editMode && 'New due date for responses'}
                  {!editMode && 'Due date for responses'}
                </div>
              </div>
              <div className="assignment-datetime">
                <DateInput
                  datetime={dueAt}
                  min={addMinutes(assignedAt || assignedAtDefault, 15)}
                  dateAssigned
                  readOnly={assignNow}
                  dateDueAssigned={dateDueAssigned}
                  onChange={this.handleDueAt}
                  required
                />
                <TimeInput
                  datetime={dueAt}
                  onChange={this.handleTimeDueAt}
                  readOnly={assignNow}
                  timeAssigned
                  timeDueAssigned={timeDueAssigned}
                />
              </div>
              {editMode && (
                <section>
                  <div
                    className="field inline"
                    onClick={this.toggleNotifyStudentsDueChange}
                  >
                    <input
                      type="checkbox"
                      name="notify-students-due"
                      id="notify-students-due"
                      checked={notifyStudentsDueChange}
                    />
                    <label htmlFor="notify-students">
                      Notify students by e-mail that due date has changed.
                    </label>
                  </div>
                </section>
              )}
              {!editMode && (
                <section>
                  <div
                    className="field inline"
                    onClick={this.toggleNotifyStudents}
                  >
                    <input
                      type="checkbox"
                      name="notify-students"
                      id="notify-students"
                      checked={notifyStudents}
                    />
                    <label htmlFor="notify-students">
                      Notify students by e-mail
                      <Tooltip>
                        Students will get an e-mail when the question is
                        assigned. They will also get a reminder 24 hours before
                        the due date.
                      </Tooltip>
                    </label>
                  </div>
                  <div
                    className="field inline"
                    onClick={this.toggleRequireEvidence}
                  >
                    <input
                      type="checkbox"
                      name="require-evidence"
                      id="require-evidence"
                      checked={requireEvidence}
                    />
                    <label htmlFor="require-evidence">
                      Require students to attach evidence (PDF)
                    </label>
                  </div>
                  <div className="field inline" onClick={this.toggleAllowLate}>
                    <input
                      type="checkbox"
                      name="allow-late"
                      id="allow-late"
                      checked={allowLate}
                      readOnly
                    />
                    <label htmlFor="allow-late">
                      Allow late responses
                      <Tooltip>
                        Currently locked in ON position. Students can submit
                        reflections after the due date, but will be marked
                        "late" in ReflEQ.
                      </Tooltip>
                    </label>
                  </div>
                </section>
              )}
            </div>
          </section>
        )}
        <div className="fields">
          {editMode && (
            <section>
              {submittedAnswers.length === 0 && (
                <div
                  className="field inline"
                  onClick={this.toggleUnassignPrompt}
                >
                  <input
                    type="checkbox"
                    name="unassign-prompt"
                    id="unassign-prompt"
                    checked={unassignPrompt}
                  />
                  <label htmlFor="unassign-prompt">Un-assign prompt</label>
                </div>
              )}
            </section>
          )}
        </div>
        <div className="actions">
          <button onClick={this.handleSave}>
            {editMode && 'Save'}
            {!editMode && 'Assign'}
          </button>
          <a className="cancel" onClick={onCancel}>
            Cancel
          </a>
        </div>
        <WarningModal isOpen={warningOpen} onClose={this.toggleWarning}>
          <h2>Assignment Dates are not valid</h2>
          {!missingDueTime && (
            <p style={{ marginTop: 48, marginBottom: 54 }}>
              Assign date must not be in the past.
              <br />
              Due date must be at least 15 minutes after the Assign date.
            </p>
          )}
          {missingDueTime && (
            <p style={{ marginTop: 48, marginBottom: 54 }}>
              You have selected a Due date but not a Time. <br />
              Please select a Time.
            </p>
          )}
        </WarningModal>
      </div>
    )
  }
}

export default AssignmentForm
