import React, { Component } from 'react'
import TimePicker from './time-picker'
import { formatDate } from '../utils'

class TimeInput extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isActive: false,
      scheduled: this.props.readOnly,
    }
  }

  divClass = (readOnly) => {
    return `field time ${readOnly ? 'disabled' : ''}`
  }

  toggleActive = () => {
    if (!this.readOnly()) {
      const { isActive } = this.state
      this.setState({ isActive: !isActive })
    }
  }

  formattedTime = () => {
    const { datetime, timeAssigned, timeDueAssigned } = this.props

    if (timeAssigned === false && this.readOnly() === false) {
      return ''
    }

    if (timeDueAssigned === false && this.readOnly() === false) {
      return ''
    }

    return formatDate(datetime, 'h:mma')
  }

  handleSave = (hrs, mins) => {
    this.props.onChange(hrs, mins)
    this.setState({ isActive: false, scheduled: true })
  }

  readOnly = () => {
    const { readOnly, timeDueAssigned } = this.props

    if (timeDueAssigned === undefined) {
      return readOnly
    }
    return false
  }

  render() {
    const { required, datetime, label } = this.props
    const { isActive } = this.state

    return (
      <div className={this.divClass(this.readOnly())}>
        <label>{label}</label>
        <input
          type="text"
          onFocus={this.toggleActive}
          placeholder="Time"
          value={this.formattedTime()}
          readOnly={this.readOnly()}
          required={required}
        />
        <div
          className={isActive ? 'overlay no-shadow' : ''}
          onClick={this.toggleActive}
        />
        <TimePicker
          isActive={isActive}
          onSave={this.handleSave}
          datetime={datetime}
        />
      </div>
    )
  }
}

export default TimeInput
