import React, { Component } from 'react'
import Modal from 'react-modal'
import FloatingLabel from '../components/floating-label'
import Choices from 'choices.js'
import { graphql } from 'react-apollo'
import createStudent from '../mutations/create-student'

const className = {
  base: 'full-modal',
  afterOpen: 'modal-after-open',
  beforeClose: 'modal-before-close',
}

const overlayClassName = {
  base: 'overlay',
  afterOpen: 'overlay-after-open',
  beforeClose: 'overlay-before-close',
}

@graphql(createStudent, { name: 'createStudent' })
class CreateUserModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      graduationYear: new Date().getFullYear(),
    }
  }

  initializeGraduationYear = (element) => {
    const choices = new Choices(element, {
      shouldSort: false,
      shouldSortItems: false,
    })

    this.setState({ choices: choices })
  }

  handleClose = (e) => {
    e.preventDefault()

    const { onClose } = this.props
    onClose(null)
  }

  handleFirstNameChange = (e) => {
    this.setState({ firstName: e.target.value })
  }

  handleLastNameChange = (e) => {
    this.setState({ lastName: e.target.value })
  }

  handleEmailChange = (e) => {
    this.setState({ email: e.target.value })
  }

  handleGraduationYearChange = (e) => {
    this.setState({ graduationYear: e.target.value })
  }

  handleSave = (e) => {
    e.preventDefault()

    const { onClose } = this.props
    const { firstName, lastName, email, graduationYear } = this.state
    const variables = { firstName, lastName, email, graduationYear }

    this.props
      .createStudent({
        variables: variables,
      })
      .then(() => {
        onClose(null)
        this.setState({
          firstName: '',
          lastName: '',
          email: '',
          graduationYear: new Date().getFullYear(),
        })
      })
  }

  render() {
    const { isOpen } = this.props
    const { firstName, lastName, email, graduationYear } = this.state

    const currentYear = new Date().getFullYear()
    const years = Array.from({ length: 10 }, (x, i) =>
      (currentYear + i).toString()
    )

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={this.handleClose}
        contentLabel={'Pulse Modal'}
        className={className}
        overlayClassName={overlayClassName}
      >
        <header className="full-modal-header">
          <a href="/" className="logo">
            <img src="/images/logo.png" className="logo" alt="" />
          </a>
          <h2>Create User</h2>
          <nav role="navigation" aria-label="App Navigation">
            <a href="#" onClick={this.handleClose}>
              Close
              <i className="material-icons">close</i>
            </a>
          </nav>
        </header>
        <div className="full-modal-body">
          <section id="admin-users">
            <div className="container">
              <div className="header">
                <h1>Create Student</h1>
                <nav className="actions">
                  <a className="button" onClick={this.handleClose}>
                    Back
                  </a>
                </nav>
              </div>
            </div>
            <div className="container flex">
              <div className="well">
                <form onSubmit={this.handleSave}>
                  <FloatingLabel
                    labelText="First Name"
                    labelFor="user_first_name"
                  >
                    <input
                      id="user_first_name"
                      name="user[first_name]"
                      onChange={this.handleFirstNameChange}
                      required
                      type="text"
                      value={firstName}
                    />
                  </FloatingLabel>
                  <FloatingLabel
                    labelText="Last Name"
                    labelFor="user_last_name"
                  >
                    <input
                      id="user_last_name"
                      name="user[last_name]"
                      onChange={this.handleLastNameChange}
                      required
                      type="text"
                      value={lastName}
                    />
                  </FloatingLabel>
                  <FloatingLabel labelText="Email" labelFor="user_email">
                    <input
                      id="user_email"
                      name="user[email]"
                      onChange={this.handleEmailChange}
                      required
                      type="text"
                      value={email}
                    />
                  </FloatingLabel>
                  <div id="user_graduation_choices">
                    <select
                      ref={this.initializeGraduationYear}
                      onChange={this.handleGraduationYearChange}
                      value={graduationYear}
                    >
                      {years.map((year) => (
                        <option value={year}>{year}</option>
                      ))}
                    </select>
                  </div>
                  <input type="submit" value="Create Student" />
                </form>
              </div>
            </div>
          </section>
        </div>
      </Modal>
    )
  }
}

export default CreateUserModal
