import { gql } from 'react-apollo'

export default gql`
  query assignments($projectId: ID!, $metaReflection: Boolean) {
    assignments(projectId: $projectId, metaReflection: $metaReflection) {
      id
      sortPosition
      metaReflection
      allowLate
      requireEvidence
      assignedAt
      dueAt
      notifyStudentsDueChange
      question {
        id
        text
        learnerType
      }
    }
  }
`
