import React, { Component } from 'react'

const defaultColor = '5A6175'
const colors = [
  '18947E',
  '6FCBDD',
  'FF7600',
  'EAA822',
  'ED3F3D',
  '90C631',
  '5BC4BE',
  'B4E0E9',
  'FFA166',
  'FFC969',
  'FF8A80',
  'B3D576',
  '311039',
  '5A6175',
  'BABABA',
]

const Swatch = ({ color, onClick }) => {
  return (
    <div
      className="swatch"
      style={{ backgroundColor: `#${color}` }}
      onClick={onClick}
    />
  )
}

class ColorSelect extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showOptions: false,
    }
  }

  toggleOptions = () => {
    const { showOptions } = this.state
    this.setState({ showOptions: !showOptions })
  }

  onSwatchSelect = (color) => {
    this.props.onSelect(color)
    this.toggleOptions()
  }

  render() {
    const { color } = this.props
    const { showOptions } = this.state
    const selected = color || defaultColor
    const optionsClass = showOptions ? 'color-options active' : 'color-options'

    return (
      <div className="color-select">
        <div className="trigger" onClick={this.toggleOptions}>
          <Swatch color={selected} />
          <span>Assign Group Color</span>
          <i className="material-icons">keyboard_arrow_down</i>
        </div>
        <input
          type="text"
          name="color"
          value={selected}
          onFocus={this.toggleOptions}
          onBlur={this.toggleOptions}
        />
        <div className={optionsClass}>
          {colors.map((c) => (
            <Swatch color={c} onClick={() => this.onSwatchSelect(c)} />
          ))}
        </div>
      </div>
    )
  }
}

export default ColorSelect
