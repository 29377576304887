import { gql } from 'react-apollo'

export default gql`
  mutation unArchiveGroup($id: ID!) {
    updateGroup(id: $id, archived: false) {
      id
      name
      color
      archived
    }
  }
`
