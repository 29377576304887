import React, { Component } from 'react'
import Modal from 'react-modal'

const className = {
  base: 'modal',
  afterOpen: 'modal-after-open',
  beforeClose: 'modal-before-close',
}

const overlayClassName = {
  base: 'overlay',
  afterOpen: 'overlay-after-open',
  beforeClose: 'overlay-before-close',
}

class WarningModal extends Component {
  render() {
    const {
      contentLabel,
      children,
      isOpen,
      onClose,
      onConfirm,
      confirmText,
      dismissText,
    } = this.props

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        contentLabel={contentLabel || 'Warning'}
        className={className}
        overlayClassName={overlayClassName}
      >
        {onClose && (
          <a className="close" onClick={onClose}>
            Cancel
          </a>
        )}
        {children}
        <div className="actions">
          <button onClick={onClose}>{dismissText || 'Go Back'}</button>
          {onConfirm && (
            <button onClick={onConfirm}>{confirmText || 'Continue'}</button>
          )}
        </div>
      </Modal>
    )
  }
}

export default WarningModal
