import { format, parse } from 'date-fns'

const dateFromISO8601 = (isoDateString) => {
  if (!isoDateString) return null
  const d = isoDateString.match(/\d+/g)
  const isoTime = Date.UTC(d[0], d[1] - 1, d[2], d[3], d[4], d[5])
  const isoDate = new Date(isoTime)

  return isoDate
}

const formatDate = (timestamp, formatStr = 'MMM D [at] h:mma') => {
  if (!timestamp) return null
  const datetime =
    typeof timestamp === 'string' ? dateFromISO8601(timestamp) : timestamp

  return format(datetime, formatStr)
}

const formatDateOnly = (date, formatStr = 'M/D/YYYY') => {
  if (!date) return null
  var result = parse(date, 'yyyy/MM/dd', new Date())
  return format(result, formatStr)
}

const levenshteinDistance = (s, t) => {
  var d = [] // 2d matrix

  // Step 1
  var n = s.length
  var m = t.length

  if (n === 0) return m
  if (m === 0) return n

  // Create an array of arrays in javascript (a descending loop is quicker)
  for (var i = n; i >= 0; i--) d[i] = []

  // Step 2
  for (var i2 = n; i2 >= 0; i2--) d[i2][0] = i
  for (var j = m; j >= 0; j--) d[0][j] = j

  // Step 3
  for (var i3 = 1; i3 <= n; i3++) {
    var sI = s.charAt(i3 - 1)

    // Step 4
    for (var j2 = 1; j2 <= m; j2++) {
      // Check the jagged ld total so far
      if (i3 === j && d[i3][j2] > 4) return n

      var tJ = t.charAt(j2 - 1)
      var cost = sI === tJ ? 0 : 1 // Step 5

      // Calculate the minimum
      var mi = d[i3 - 1][j2] + 1
      var b = d[i3][j - 1] + 1
      var c = d[i3 - 1][j2 - 1] + cost

      if (b < mi) mi = b
      if (c < mi) mi = c

      d[i3][j2] = mi // Step 6

      // Damerau transposition
      if (
        i3 > 1 &&
        j > 1 &&
        sI === t.charAt(j2 - 2) &&
        s.charAt(i3 - 2) === tJ
      ) {
        d[i3][j2] = Math.min(d[i3][j2], d[i3 - 2][j2 - 2] + cost)
      }
    }
  }

  // Step 7
  return d[n][m]
}

const learnerTypeColors = {
  Flexible: '#afd8e3',
  Cognitive: '#adcb6a',
  Transfer: '#63b6af',
  'Self-Directed': '#ed7c71',
  Mindset: '#f0945b',
  Social: '#f6bf60',
}

const learnerTypeAccentColor = (learnerType) => {
  if (learnerType !== null && typeof learnerType === 'string') {
    const type = learnerType.replace('<mark>', '').replace('</mark>', '')
    return learnerTypeColors[type]
  } else {
    return learnerTypeColors[learnerType]
  }
}

export {
  dateFromISO8601,
  formatDate,
  formatDateOnly,
  learnerTypeColors,
  learnerTypeAccentColor,
  levenshteinDistance,
}
