import { gql } from 'react-apollo'

export default gql(`
  mutation updateQuestion($id: ID!, $text: String!, $stage: String!, $categoryId: Int!, $learnerType: String!, $archived: Boolean!, $preview: Boolean!, $pAndA: Boolean!) {
    updateQuestion(id: $id, text: $text, stage: $stage, categoryId: $categoryId, learnerType: $learnerType, archived: $archived, preview: $preview, pAndA: $pAndA) {
      id
      text
      stage
      category {
        id
        name
      }
      learnerType
      archived
      preview
      pAndA
    }
  }
`)
