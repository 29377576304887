import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { ApolloProvider, graphql } from 'react-apollo'
import sendOnboardingEmail from '../mutations/send-onboarding-email'
import client from '../client'

@graphql(sendOnboardingEmail, { name: 'sendOnboardingEmail' })
class OnboardingLink extends Component {
  constructor(props) {
    super(props)

    this.state = {
      message: 'Send On-Boarding Email',
      recipient: '',
      disabled: true,
    }
  }

  handleSendOnboardingEmail = (e) => {
    e.preventDefault()
    const { id, sendOnboardingEmail } = this.props
    const { recipient } = this.state

    sendOnboardingEmail({ variables: { id, recipient } }).then((org) => {
      this.setState({ message: 'Email sent', disabled: true, recipient: '' })
    })
  }

  handleRecipientInput = (e) => {
    this.setState({ recipient: e.currentTarget.value })
    if (this.state.recipient.length > 5) {
      this.setState({ disabled: false })
    } else {
      this.setState({ disabled: true })
    }
  }

  render() {
    const { message, disabled, recipient } = this.state

    return (
      <div style={{ marginBottom: '40px' }}>
        <input
          type="text"
          placeholder="Onboarding recipient"
          onChange={this.handleRecipientInput}
          value={recipient}
        />
        <button disabled={disabled} onClick={this.handleSendOnboardingEmail}>
          {message}
        </button>
      </div>
    )
  }
}

const onboardingLink = document.getElementById('onboarding-link')
if (onboardingLink) {
  const id = onboardingLink.getAttribute('data-id')
  const props = { id }

  ReactDOM.render(
    <ApolloProvider client={client}>
      <OnboardingLink {...props} />
    </ApolloProvider>,
    onboardingLink
  )
}
