import React, { Component } from 'react'
import HeightMatchingGroup from '../components/height-matching-group'
import { graphql } from 'react-apollo'
import fetchProjects from '../queries/project-list'
import renameProject from '../mutations/rename-project'
import ProjectActions from '../components/project-actions'
import debounce from 'debounce'
import format from 'date-fns/format'

const countStudents = (sections) => {
  return (
    sections &&
    sections.map((s) => s.students.length).reduce((i, acc) => i + acc, 0)
  )
}

const goToProjectEdit = (projectId) => {
  window.location.href = `/projects/${projectId}/edit`
}

@graphql(renameProject, { name: 'renameProject' })
class ProjectRow extends Component {
  constructor(props) {
    super(props)

    const {
      data: { name },
    } = this.props

    this.state = {
      name: name,
      showActions: false,
      showRename: false,
    }
  }

  stopPropagation = (e) => {
    e.stopPropagation()
  }

  renameThisProject = debounce((name) => {
    const { renameProject, data } = this.props
    const { id } = data

    if (name.length > 0) {
      renameProject({
        variables: { id, name },
        refetchQueries: [{ query: fetchProjects }],
      })
    }
  }, 500)

  toggleActions = (e) => {
    e.stopPropagation()
    const { showActions } = this.state
    this.setState({ showActions: !showActions })
  }

  handleNameChange = (e) => {
    const name = e.target.value
    this.setState({ name: name })
    this.renameThisProject(name)
  }

  handleKeyUp = (e) => {
    const keyCode = e.keyCode
    if (keyCode === 13) {
      this.toggleRename(e)
    }
  }

  toggleRename = (e) => {
    e.stopPropagation()
    const { showRename } = this.state
    this.setState({ showRename: !showRename }, () => {
      if (!showRename) {
        this.nameInput.focus()
        this.nameInput.select()
      }
    })
  }

  handleClickShare = (e) => {
    e.stopPropagation()
    const { data, toggleShareProject } = this.props
    toggleShareProject(data)
  }

  render() {
    const { data, isShared } = this.props
    const { name, showActions, showRename } = this.state
    const { id, collaborator, liveQuestions, owner, sections, inserted_at } =
      data
    const group = sections[0] && sections[0].group
    const sectionNames =
      sections &&
      sections.map((s) => s.name.replace(/\s/g, '\u00A0')).join(', ')
    const numStudents = countStudents(sections)
    const numQuestions = (liveQuestions && liveQuestions.length) || 0
    const colleague = isShared
      ? owner.name
      : (collaborator && collaborator.name) || ''
    const nameClasses = showRename ? 'project-name renaming' : 'project-name'

    return (
      <HeightMatchingGroup
        tagName="tr"
        selector=".match-height"
        onClick={() => goToProjectEdit(id)}
      >
        <td className={nameClasses}>
          <div className="match-height">
            <strong>{name}</strong>
            <div className="field floating" onClick={this.stopPropagation}>
              <input
                type="text"
                value={name}
                onBlur={this.toggleRename}
                onChange={this.handleNameChange}
                onKeyUp={this.handleKeyUp}
                ref={(input) => {
                  this.nameInput = input
                }}
                required
              />
              <div className="highlight" />
            </div>
          </div>
        </td>
        <td>
          <div className="match-height">
            {format(inserted_at, 'MMM DD, YYYY')}
          </div>
        </td>
        <td className="section-names">
          {group ? (
            <div className="match-height">
              <p>
                <strong style={{ color: `#${group.color}` }}>
                  {group.name}
                </strong>
                {'\u00a0'}({sectionNames})
              </p>
            </div>
          ) : (
            <div className="match-height" />
          )}
        </td>
        <td>
          <div className="match-height">{numStudents}</div>
        </td>
        <td>
          <div className="match-height">{numQuestions}</div>
        </td>
        <td>
          <div className="match-height">{colleague}</div>
        </td>
        <td className="share">
          {isShared ? (
            <div className="match-height" />
          ) : (
            <div className="match-height" onClick={this.handleClickShare}>
              <i className="material-icons">person_add</i>
            </div>
          )}
        </td>
        <td className="actions">
          {isShared ? (
            <div className="match-height" />
          ) : (
            <div className="match-height">
              <ProjectActions
                id={id}
                toggleOpen={this.toggleActions}
                toggleRename={this.toggleRename}
                isOpen={showActions}
                canDelete={liveQuestions.length === 0}
              />
            </div>
          )}
        </td>
      </HeightMatchingGroup>
    )
  }
}

export default ProjectRow
