import React, { Component } from 'react'

class QuestionUnlockBankInfo extends Component {
  render() {
    return (
      <div className="question-card">
        <span>
          This question bank is not active in your organization's account.
        </span>
      </div>
    )
  }
}

export default QuestionUnlockBankInfo
