import React, { Component } from 'react'
import { findDOMNode } from 'react-dom'
import { DragSource, DropTarget } from 'react-dnd'
import { formatDate, learnerTypeAccentColor } from '../utils'

const cardTarget = {
  drop(props, monitor) {
    // jet if we can't drop
    if (!props.canDrop) {
      return
    }

    const item = monitor.getItem()

    // jet if we don't have a question
    if (item.cardType !== 'question') {
      return
    }

    // get the index of the assignment target
    const hoverIndex = props.index

    props.onCreate(item.id, hoverIndex)
  },

  hover(props, monitor, component) {
    const item = monitor.getItem()

    // jet if we don't have an assignment
    if (item.cardType === 'question') {
      return
    }

    const dragIndex = item.index
    const hoverIndex = props.index

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return
    }

    // Determine rectangle on screen
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect()

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

    // Determine mouse position
    const clientOffset = monitor.getClientOffset()

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return
    }

    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return
    }

    // Time to actually perform the action
    props.onSort(dragIndex, hoverIndex)

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex
  },
}

const cardSource = {
  beginDrag(props) {
    const {
      data: { id },
      index,
    } = props
    return { id, index }
  },
}

const onMouseEnter = (e) => {
  e.target.classList.add('hover')
}

const onMouseLeave = (e) => {
  e.target.classList.remove('hover')
}

@DropTarget(['assignment', 'question'], cardTarget, (connect) => ({
  connectDropTarget: connect.dropTarget(),
}))
@DragSource('assignment', cardSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
}))
class Assignment extends Component {
  render() {
    const {
      connectDragSource,
      connectDropTarget,
      data,
      isDragging,
      onAssign,
      onDelete,
      allowAssign,
    } = this.props
    const {
      id,
      question: { text, learnerType },
    } = data

    const opacity = isDragging ? 0 : 1
    const cursor = isDragging ? 'move' : 'pointer'
    const borderLeftColor = learnerTypeAccentColor(learnerType)
    const assignText = data.assignedAt ? formatDate(data.assignedAt) : 'Assign'

    return connectDragSource(
      connectDropTarget(
        <li onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
          <div
            className="assignment question-card"
            style={{ cursor, opacity, borderLeftColor }}
          >
            <div className="question">{text}</div>
            <nav className="actions">
              {allowAssign && (
                <a onClick={() => onAssign(data)}>
                  <span>{assignText}</span>
                </a>
              )}
              <a onClick={() => onDelete(id)}>
                <span>Remove</span>
              </a>
            </nav>
          </div>
        </li>
      )
    )
  }
}

export default Assignment
