import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import HeightMatchingGroup from '../components/height-matching-group'
import { ApolloProvider, graphql } from 'react-apollo'
import client from '../client'
import fetchReflections from '../queries/reflection-list'
import addStudentToSection from '../mutations/add-student-to-section'
import { dateFromISO8601, formatDate } from '../utils'
import { isPast } from 'date-fns'

const goToProject = (projectId) => {
  window.location.href = `/my-reflections/${projectId}`
}

const goToAssignment = (projectId, assignmentId) => {
  window.location.href = `/my-reflections/${projectId}#assignment-${assignmentId}`
}

const DueDates = ({ data, completed }) => {
  if (completed) {
    const { completedAssignments } = data

    if (completedAssignments.length > 0) {
      const lastDue = Math.max(
        ...completedAssignments.map((a) => dateFromISO8601(a.dueAt))
      )

      return (
        <div className="match-height">
          {formatDate(lastDue, 'ddd, MMM D [at] h:mma')}
        </div>
      )
    } else {
      return <div className="match-height" />
    }
  } else {
    const { outstandingAssignments } = data

    return (
      <div className="match-height">
        <ul className="due-dates">
          {outstandingAssignments
            .filter((a) => a.dueAt)
            .sort((a, b) => dateFromISO8601(a.dueAt) - dateFromISO8601(b.dueAt))
            .map((a) => (
              <li
                key={a.id}
                className={isPast(dateFromISO8601(a.dueAt)) ? 'late' : ''}
                onClick={(e) => {
                  e.stopPropagation()
                  goToAssignment(data.id, a.id)
                }}
              >
                <a>{formatDate(a.dueAt, 'ddd, MMM D [at] h:mma')}</a>
                <i className="material-icons">open_in_new</i>
              </li>
            ))}
        </ul>
      </div>
    )
  }
}

const Project = ({ data, isPast }) => {
  const {
    name,
    group,
    completedAssignments,
    outstandingAssignments,
    owner,
    collaborator,
  } = data
  const badgeCount = outstandingAssignments.length
  const assignments = isPast ? completedAssignments : outstandingAssignments
  const mods = assignments.map(
    (a) => a.answer && a.answer.updatedAt && dateFromISO8601(a.answer.updatedAt)
  )
  const lastModified = Math.max(...mods)

  return (
    <HeightMatchingGroup
      tagName="tr"
      selector=".match-height"
      onClick={() => goToProject(data.id)}
    >
      <td>
        <div className="match-height">
          <div>
            <strong>{name}</strong>
            {badgeCount > 0 && <span className="badge">{badgeCount}</span>}
          </div>
          <div>{group.name}</div>
        </div>
      </td>
      <td>
        <DueDates data={data} completed={isPast} />
      </td>
      <td>
        <div className="match-height">
          <div>{owner && owner.name}</div>
          <div>{collaborator && collaborator.name}</div>
        </div>
      </td>
      <td>
        <div className="match-height">
          {mods.length > 0 && formatDate(lastModified, 'ddd, MMM D [at] h:mma')}
        </div>
      </td>
    </HeightMatchingGroup>
  )
}

const ProjectsTableHead = ({ isPast }) => {
  return (
    <thead>
      <tr>
        <th>
          <div>Project</div>
        </th>
        <th>
          <div>{isPast ? 'Last Due Dates' : 'Due Dates'}</div>
        </th>
        <th>
          <div>Viewable By</div>
        </th>
        <th>
          <div>Last Modified</div>
        </th>
      </tr>
    </thead>
  )
}

const ProjectsTable = ({ projects, isPast }) => {
  if (projects && projects.length > 0) {
    return (
      <table className="fancy">
        <ProjectsTableHead isPast={isPast} />
        <tbody>
          {projects &&
            projects.map((p) => (
              <Project key={p.id} data={p} isPast={isPast} />
            ))}
        </tbody>
      </table>
    )
  } else {
    return (
      <div>
        <p>No reflections to do.</p>
        <p>See the My Journals tab for completed reflections.</p>
      </div>
    )
  }
}

const badgeCount = (projects) => {
  return (
    projects &&
    projects
      .map((p) => p.outstandingAssignments.length)
      .reduce((a, b) => a + b, 0)
  )
}

@graphql(fetchReflections)
@graphql(addStudentToSection, { name: 'addStudentToSection' })
class ReflectionList extends Component {
  constructor(props) {
    super(props)

    this.state = { activeTab: 'my-refleqs', submitDisabled: true }
  }

  showTab = (tabName) => {
    this.setState({ activeTab: tabName })
  }

  handleChange = (event) => {
    this.setState({ code: event.target.value })
    if (event.target.value.length === 5) {
      this.setState({ submitDisabled: false })
    } else {
      this.setState({ submitDisabled: true })
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()

    const { code } = this.state
    const { addStudentToSection, data } = this.props
    const { refetch } = data

    addStudentToSection({
      variables: { code },
    }).then((operation) => {
      refetch()
      window.setTimeout(() => {
        this.setState({
          code: '',
        })
      }, 200)
      if (operation.payload.data.addStudentToSection) {
        alert(
          `You have successfully enrolled in ${operation.payload.data.addStudentToSection.name}`
        )
      } else if (operation.payload.errors) {
        alert(operation.payload.errors[0].message)
      }
    })
  }

  renderTabControl = (tabName, tabDisplayName, badgeCount) => {
    const { activeTab } = this.state
    const showBadge = typeof badgeCount === 'number' && badgeCount > 0
    let className = ''

    if (activeTab === tabName) {
      className = 'active'
    }

    return (
      <a className={className} onClick={() => this.showTab(tabName)}>
        {tabDisplayName}

        {showBadge && <span className="badge-count">{badgeCount}</span>}
      </a>
    )
  }

  render() {
    const {
      data: { projects },
    } = this.props
    const { activeTab, code, submitDisabled } = this.state

    const activeProjects =
      projects && projects.filter((p) => p.outstandingAssignments.length > 0)
    const otherProjects =
      projects &&
      projects.filter(
        (p) =>
          p.completedAssignments.length > 0 &&
          p.outstandingAssignments.length === 0
      )
    const activeBadgeCount = badgeCount(activeProjects)
    const otherBadgeCount = badgeCount(otherProjects)

    return (
      <div className="project-list">
        <div className="add-section-by-code">
          <input
            type="text"
            name="code"
            onChange={this.handleChange}
            placeholder="Class Code"
            value={code}
            maxLength="5"
          ></input>
          <button disabled={submitDisabled} onClick={this.handleSubmit}>
            Join
          </button>
        </div>
        <nav className="tabbed-subnav" role="navigation">
          <div className="container">
            {this.renderTabControl(
              'my-refleqs',
              'Time to reflect',
              activeBadgeCount
            )}
            {this.renderTabControl(
              'refleq-folders',
              'My Journals',
              otherBadgeCount
            )}
          </div>
        </nav>
        <div className="container">
          {activeTab === 'my-refleqs' && (
            <ProjectsTable projects={activeProjects} />
          )}
          {activeTab === 'refleq-folders' && (
            <ProjectsTable projects={otherProjects} isPast />
          )}
        </div>
      </div>
    )
  }
}

const reflectionList = document.getElementById('reflection-list')
if (reflectionList) {
  ReactDOM.render(
    <ApolloProvider client={client}>
      <ReflectionList />
    </ApolloProvider>,
    reflectionList
  )
}
