import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import Notification from '../components/notification'
import { ApolloProvider, graphql } from 'react-apollo'
import client from '../client'
import fetchNotifications from '../queries/notifications'
import PubSub from 'pubsub-js'

@graphql(fetchNotifications, { options: { pollInterval: 30000 } })
class NotificationList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      display: false,
    }
  }

  toggleDisplay = () => {
    const { display } = this.state
    this.setState({ display: !display })
  }

  render() {
    const { data } = this.props
    const { display } = this.state
    const anyUnread =
      data.notifications && data.notifications.filter((n) => !n.read).length > 0
    const badgeClasses = anyUnread ? 'badge active' : 'badge'
    const listClasses = display
      ? 'notification-list active'
      : 'notification-list'

    const re = /^Import completed/
    data.notifications &&
      data.notifications.some((notification) => {
        if (re.exec(notification.message) && !notification.read) {
          PubSub.publish('refresh_users', true)
        }
      })

    return (
      <div>
        <a className="notifications-trigger">
          <div className="notification-icon" onClick={this.toggleDisplay}>
            <i className="material-icons">notifications</i>
            <div className={badgeClasses} />
          </div>
        </a>
        <div
          className={display ? 'overlay no-shadow' : ''}
          onClick={this.toggleDisplay}
        />
        <div
          ref={(listDiv) => {
            this.listDiv = listDiv
          }}
          className={listClasses}
          onClick={this.toggleDisplay}
        >
          <h2>Notifications</h2>
          {data.notifications &&
            data.notifications.map((n) => (
              <Notification
                key={n.id}
                data={n}
                parentRef={this.listDiv}
                active={display}
              />
            ))}
        </div>
      </div>
    )
  }
}

const notificationsList = document.getElementById('notifications-list')
if (notificationsList) {
  ReactDOM.render(
    <ApolloProvider client={client}>
      <NotificationList />
    </ApolloProvider>,
    notificationsList
  )
}
