import React from 'react'
import Typing from 'react-typing-animation'

const widont = (s) => {
  const words = s.split(' ')
  const firstWords = words.slice(0, -1)
  const lastWords = words.slice(-1)
  return firstWords.join(' ') + '\u00a0' + lastWords.join('\u00a0')
}

const Quote = ({ quote, opacity }) => {
  return (
    <blockquote className="random-quote" style={{ opacity }}>
      <Typing>
        {'\u201C'}
        {widont(quote.text)}
        {'\u201D'}
        <cite>
          {'\u2014'}
          {quote.attribution}
        </cite>
      </Typing>
    </blockquote>
  )
}

export default Quote
