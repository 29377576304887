import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { DragDropContext } from 'react-dnd'
import sortBanks from '../mutations/sort-banks'
import { graphql, ApolloProvider } from 'react-apollo'
import update from 'immutability-helper'
import HTML5Backend from 'react-dnd-html5-backend'
import Bank from '../components/bank'
import debounce from 'debounce'
import client from '../client'

// @graphql(fetchBanks)
@graphql(sortBanks, { name: 'sortBanks' })
@DragDropContext(HTML5Backend)
class BankList extends Component {
  constructor(props) {
    super(props)
    this.state = { banks: props.banks }
  }

  dispatchSort = debounce(() => {
    const { banks } = this.state
    const { sortBanks } = this.props
    const ids = banks.map((b) => b.id)
    const expected = ids.map((id, i) => ({ id, sortOrder: i }))

    sortBanks({
      variables: { ids },
      optimisticResponse: {
        sortBanks: expected,
      },
    })
  }, 500)

  moveBank = (dragIndex, hoverIndex) => {
    const { banks } = this.state
    const dragBank = banks[dragIndex]

    this.setState(
      update(this.state, {
        banks: {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragBank],
          ],
        },
      })
    )

    this.dispatchSort()
  }

  showArchiveStatus = (bank) => {
    if (bank.archived) {
      return <span className="bold">hidden</span>
    }
    return (
      <i style={{ color: '#1DAEA6' }} className="material-icons">
        done
      </i>
    )
  }

  render() {
    const { banks } = this.state
    const countWidth = { width: '50px', textAlign: 'center' }
    const nameWidth = { width: '300px' }
    const center = { textAlign: 'center' }

    return (
      <table>
        <thead>
          <tr>
            <th style={nameWidth}>Name</th>
            <th style={center}>Questions</th>
            <th style={countWidth} title="Initial">
              In
            </th>
            <th style={countWidth} title="Mid-stream">
              Mi
            </th>
            <th style={countWidth} title="Wrap-up">
              Wu
            </th>
            <th style={countWidth} title="Meta">
              Me
            </th>
            <th style={center}>Visibility</th>
            <th style={center}>Hide/Unhide</th>
          </tr>
        </thead>
        <tbody>
          {banks.map((b, i) => (
            <Bank
              index={i}
              data={b}
              key={b.id}
              bank={b}
              moveBank={this.moveBank}
              showArchiveStatus={this.showArchiveStatus}
            />
          ))}
        </tbody>
      </table>
    )
  }
}

const bankList = document.getElementById('bank-list')
if (bankList) {
  const csrfTag = document.querySelector('meta[name=csrf-token]')
  const csrfToken = csrfTag.getAttribute('content')
  const banks = JSON.parse(bankList.getAttribute('data-banks'))
  const props = { csrfToken, banks }

  ReactDOM.render(
    <ApolloProvider client={client}>
      <BankList {...props} />
    </ApolloProvider>,
    bankList
  )
}
