import React from 'react'
import { formatDate } from '../utils'

const formattedDate = (timestamp) => {
  return formatDate(timestamp, 'MMM D, YYYY')
}

const PostscriptText = ({ answer }) => {
  if (answer.postscript) {
    return (
      <p>
        <span className="postscriptAdded">
          {`[Added ${formattedDate(answer.postscript.insertedAt)}]`}
        </span>{' '}
        {answer.postscript.text}
      </p>
    )
  } else {
    return ' '
  }
}

export default PostscriptText
