import React, { Component } from 'react'
import { graphql } from 'react-apollo'
import fetchProjects from '../queries/project-list'
import duplicateProject from '../mutations/duplicate-project'
import deleteProject from '../mutations/delete-project'
import debounce from 'debounce'

@graphql(duplicateProject, { name: 'duplicateProject' })
@graphql(deleteProject, { name: 'deleteProject' })
class ProjectActions extends Component {
  duplicateThisProject = debounce((e) => {
    const { duplicateProject, id, toggleOpen } = this.props
    duplicateProject({
      variables: { id },
      refetchQueries: [{ query: fetchProjects }],
    }).then(() => {
      toggleOpen(e)
    })
  }, 500)

  deleteThisProject = debounce(() => {
    const { deleteProject, id } = this.props
    deleteProject({
      variables: { id },
      refetchQueries: [{ query: fetchProjects }],
    })
  }, 500)

  triggerRename = (e) => {
    const { toggleRename, toggleOpen } = this.props
    toggleOpen(e)
    toggleRename(e)
  }

  render() {
    const { toggleOpen, isOpen, canDelete } = this.props
    const classes = isOpen ? 'project-actions active' : 'project-actions'

    return (
      <div className="more-trigger" onClick={toggleOpen}>
        <i className="material-icons">more_horiz</i>
        <div
          className={isOpen ? 'overlay no-shadow' : ''}
          onClick={this.toggleOpen}
        />
        <div className={classes} onClick={(e) => e.stopPropagation()}>
          <div className="actions-close-div" onClick={toggleOpen}>
            <i className="material-icons actions-close-trigger">more_horiz</i>
          </div>
          <div className="action-div" onClick={this.duplicateThisProject}>
            <i className="material-icons">content_copy</i>
            Duplicate
          </div>
          <hr />
          <div className="action-div" onClick={this.triggerRename}>
            <i className="material-icons">create</i>
            Rename
          </div>
          {canDelete && <hr />}
          {canDelete && (
            <div className="action-div" onClick={this.deleteThisProject}>
              <i className="material-icons">delete</i>
              Delete
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default ProjectActions
