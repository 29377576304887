import React, { Component } from 'react'
import { learnerTypeAccentColor } from '../utils'
import { DragSource } from 'react-dnd'

const cardSource = {
  beginDrag(props) {
    return {
      cardType: 'question',
      id: props.id,
      text: props.text,
      learnerType: props.learnerType,
    }
  },
}

const collect = (connect, monitor) => {
  return {
    connectDragPreview: connect.dragPreview(),
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  }
}

@DragSource('question', cardSource, collect)
class QuestionCard extends Component {
  render() {
    const {
      text,
      learnerType,
      connectDragSource,
      connectDragPreview,
      isDragging,
      enabled,
    } = this.props
    const learnerTypeColor = learnerTypeAccentColor(learnerType)

    const cursor = enabled ? 'pointer' : 'auto'
    const style = {
      cursor: isDragging ? 'move' : cursor,
      color: enabled ? false : '#c8c8c8',
    }

    const markup = (
      <div className="question-card" style={style}>
        <span dangerouslySetInnerHTML={{ __html: text }} />
        <span
          className="learner-type"
          style={{ color: learnerTypeColor }}
          dangerouslySetInnerHTML={{ __html: learnerType }}
        />
      </div>
    )

    if (enabled) {
      return connectDragPreview(connectDragSource(markup), {
        offsetX: 0,
        offsetY: 0,
      })
    } else {
      return markup
    }
  }
}

export default QuestionCard
