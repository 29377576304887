import { gql } from 'react-apollo'

export default gql`
  query students($groupId: ID!) {
    group(id: $groupId) {
      sections {
        id
        name
        students {
          id
          name
          active
        }
      }
    }
    students {
      id
      name
      email
      graduation_year
      active
    }
  }
`
