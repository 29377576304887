import React, { Component } from 'react'
import Modal from 'react-modal'
import Tooltip from './tooltip'
import { graphql } from 'react-apollo'
import suggestQuestion from '../mutations/suggest-question'

const className = {
  base: 'modal',
  afterOpen: 'modal-after-open',
  beforeClose: 'modal-before-close',
}

const overlayClassName = {
  base: 'overlay',
  afterOpen: 'overlay-after-open',
  beforeClose: 'overlay-before-close',
}

const SuggestionForm = ({ question, handleSubmit, handleChange, variant }) => {
  return (
    <div>
      <h2>
        {variant === 'question' ? 'Suggest a Question' : 'Suggest a Bank'}
      </h2>
      <p>
        {variant === 'question'
          ? 'What question would you like to see added, and why?'
          : 'What curated question bank would you like to see?'}
      </p>
      {variant === 'question' && (
        <p>
          Please frame your question to be generally applicable.
          <Tooltip>
            <p>
              <small>
                What connections can I draw between Beowulf and the five other
                Old-English poems we've read this semester in English 9B?
              </small>
              <br />
              <br />
              <small>⬇</small>
              <br />
              <br />
              <small>
                What connections can I draw between this text and other works
                we've read recently?
              </small>
            </p>
          </Tooltip>
        </p>
      )}
      {variant === 'bank' && (
        <div className="list-container">
          <p>Please let us know:</p>
          <ul>
            <li>in what class would you use it?</li>
            <li>what resources should we know about?</li>
          </ul>
        </div>
      )}
      <p>Thanks for your feedback!</p>
      <form onSubmit={handleSubmit}>
        <textarea
          placeholder={
            variant === 'question' ? 'Your Question' : 'Bank Suggestion'
          }
          value={question}
          onChange={handleChange}
        />
        <button>Submit</button>
      </form>
    </div>
  )
}

const SuccessMessage = ({ onCancel, variant }) => {
  return (
    <div>
      <h2>
        {variant === 'question' ? 'Question Submitted!' : 'Bank Submitted!'}
      </h2>
      <p>
        {variant === 'question'
          ? 'Thank you for submitting a question!'
          : 'Thank you for submitting a question bank!'}
      </p>
      <button onClick={onCancel} className="close-button">
        Close Window
      </button>
    </div>
  )
}

@graphql(suggestQuestion, { name: 'suggestQuestion' })
class SuggestQuestionModal extends Component {
  constructor(props) {
    super(props)

    let contentLabel

    if (props.variant === 'bank') {
      contentLabel = 'Suggest a Bank'
    } else {
      contentLabel = 'Suggest a Question'
    }

    this.state = {
      question: null,
      isSubmitted: false,
      contentLabel: contentLabel,
    }
  }

  closeModal = () => {
    this.setState({ isSubmitted: false })
    this.props.onCancel()
  }

  handleChange = (e) => {
    this.setState({ question: e.target.value })
  }

  handleSubmit = (e) => {
    e.preventDefault()

    const { question } = this.state
    const { suggestQuestion } = this.props

    suggestQuestion({
      variables: { text: question },
    }).then(() => {
      this.setState({
        question: null,
        isSubmitted: true,
      })
    })
  }

  render() {
    const { isSubmitted, question, contentLabel } = this.state
    const { isOpen, onCancel, variant } = this.props

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onCancel}
        contentLabel={contentLabel}
        className={className}
        overlayClassName={overlayClassName}
      >
        <a className="close" onClick={this.closeModal}>
          Cancel
        </a>
        {isSubmitted ? (
          <SuccessMessage onCancel={this.closeModal} variant={variant} />
        ) : (
          <SuggestionForm
            handleSubmit={this.handleSubmit}
            handleChange={this.handleChange}
            question={question}
            variant={variant}
          />
        )}
      </Modal>
    )
  }
}

export default SuggestQuestionModal
