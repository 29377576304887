import React, { Component } from 'react'
import { formatDate, learnerTypeAccentColor } from '../utils'
import LearnerTypeChart from './learner-type-chart'

const LiveQuestion = ({ assignment, numStudents, togglePulse, onAssign }) => {
  const { question, submittedAnswers, metaReflection } = assignment
  const numSubmitted = submittedAnswers
    ? submittedAnswers.filter((a) => a.automatic === false).length
    : 0
  const borderLeftColor = learnerTypeAccentColor(question.learnerType)
  const pctSubmitted = numStudents > 0 ? (numSubmitted / numStudents) * 100 : 0
  const pct = 100 - Math.min(pctSubmitted, 100)
  const progressStyle = { width: `${pct}%` }
  const dueAt = assignment.dueAt
    ? formatDate(assignment.dueAt, 'MMM D').replace(/\s/g, '\u00A0')
    : null
  const dueAtText = dueAt ? `Due\u00A0${dueAt}` : ''
  const handleViewPulse = (e) => {
    e.preventDefault()
    togglePulse(assignment)
  }
  const handleOnAssignment = (e) => {
    e.preventDefault()
    onAssign(assignment)
  }
  const classes = metaReflection
    ? 'live-question meta-reflection'
    : 'live-question'

  return (
    <div className={classes} style={{ borderLeftColor }}>
      {metaReflection && <h4>Meta-Reflection</h4>}
      <div className="row">
        <div className="question-main">
          <div className="question-text">{question && question.text}</div>
          <div className="row">
            <div className="responses-count">
              {numSubmitted}/{numStudents} Responses
            </div>
            <div className="progress-bar">
              <div className="progress" style={progressStyle} />
            </div>
          </div>
        </div>
        <div className="question-info">
          <div className="responses-link">
            <a onClick={handleViewPulse}>View Responses</a>
          </div>
          <div className="due-at">
            <div className="edit-container">
              <a className="edit-link" onClick={handleOnAssignment}>
                Edit
              </a>
            </div>
            <div className="due-at-text">{dueAtText}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

class LiveQuestionList extends Component {
  constructor(props) {
    super(props)
    const { questions, numStudents, togglePulse, onAssign } = this.props
    this.state = {
      questions: questions,
      numStudents: numStudents,
      togglePulse: togglePulse,
      onAssign: onAssign,
      showChart: false,
    }
  }

  showPieChart = () => {
    this.setState({ showChart: true })
  }

  hidePieChart = () => {
    this.setState({ showChart: false })
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { questions, numStudents, togglePulse, onAssign } = props
    this.setState({
      questions: questions,
      numStudents: numStudents,
      onAssign: onAssign,
      togglePulse: togglePulse,
    })
  }

  render() {
    const { questions, numStudents, togglePulse, showChart, onAssign } =
      this.state

    if (questions && questions.length) {
      return (
        <div className="live-questions">
          <div className="header-with-pie-chart">
            <h2>Assigned Questions</h2>
            <div
              className="pie-icon"
              onMouseOver={this.showPieChart}
              onMouseOut={this.hidePieChart}
            >
              <img src="/images/icon-piechart@2x.png" />
              <span>Data</span>
            </div>

            {showChart && <LearnerTypeChart questions={questions} />}
          </div>
          {questions &&
            questions.map((q) => (
              <LiveQuestion
                key={q.id}
                assignment={q}
                numStudents={numStudents}
                togglePulse={togglePulse}
                onAssign={onAssign}
              />
            ))}
        </div>
      )
    } else {
      return (
        <div className="live-questions">
          <h2>Assigned Questions</h2>
          <p className="message">
            You have no assigned questions. Drag and drop questions to your
            Queue to get started.
          </p>
        </div>
      )
    }
  }
}

export default LiveQuestionList
