import 'babel-polyfill'
import 'phoenix_html'

import './admin'
import './back-button'
import './show-uploading'
import './dropdown'
import './fancy-table'
import './flash'
import './org-edit'
import './profile'
import './select'
import './track-focus'
import './edit-org-nav'

import './containers/group-list'
import './containers/notifications-list'
import './containers/outstanding-count'
import './containers/project-form'
import './containers/project-list'
import './containers/organization-user-list'
import './containers/reflection'
import './containers/reflection-list'
import './containers/organization-list'
import './containers/organization-report'
import './containers/section-form'
import './containers/question-list'
import './containers/question-form'
import './containers/bank-list'
import './containers/onboarding-link'

import { Socket } from 'phoenix'
import { LiveSocket } from 'phoenix_live_view'

let csrfToken = document
  .querySelector("meta[name='csrf-token']")
  .getAttribute('content')
let liveSocket = new LiveSocket('/live', Socket, {
  params: { _csrf_token: csrfToken },
})

// connect if there are any LiveViews on the page
liveSocket.connect()
window.liveSocket = liveSocket
