import { gql } from 'react-apollo'

export default gql`
  query findProject($id: ID!) {
    project(id: $id) {
      id
      name
      currentUserRole
      lastDueAt
      group {
        id
        name
      }
      sections {
        id
        name
        group {
          id
          name
          sections {
            id
            name
            students {
              id
              name
              graduation_year
            }
          }
        }
        students {
          id
          name
          graduation_year
        }
      }
      assignments {
        id
        assignedAt
        dueAt
        question {
          id
          text
          learnerType
        }
        submittedAnswers {
          id
          text
          evidence
          submittedAt
          postscript {
            text
            insertedAt
          }
          user {
            id
            name
          }
        }
      }
      liveQuestions {
        id
        assignedAt
        dueAt
        metaReflection
        question {
          id
          text
          learnerType
        }
        submittedAnswers {
          id
          text
          evidence
          submittedAt
          notes
          collaboratorNotes
          automatic
          postscript {
            text
            insertedAt
          }
          user {
            id
            name
          }
        }
      }
    }
  }
`
