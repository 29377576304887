import { gql } from 'react-apollo'

export default gql(`
  mutation updateAssignment(
    $id: ID!,
    $sortPosition: Int,
    $allowLate: Boolean,
    $notifyStudents: Boolean
    $requireEvidence: Boolean,
    $assignedAt: NaiveDateTime,
    $dueAt: NaiveDateTime,
    $notifyStudentsDueChange: Boolean
  ) {
    updateAssignment(
      id: $id,
      sortPosition: $sortPosition,
      allowLate: $allowLate,
      notifyStudents: $notifyStudents,
      requireEvidence: $requireEvidence,
      assignedAt: $assignedAt,
      dueAt: $dueAt,
      notifyStudentsDueChange: $notifyStudentsDueChange
    ) {
      id
      sortPosition
      allowLate
      notifyStudents
      requireEvidence
      assignedAt
      dueAt
      notifyStudentsDueChange
    }
  }
`)
