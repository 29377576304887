import { gql } from 'react-apollo'

export default gql`
  mutation updateProject($id: ID!, $sectionIds: [ID]!) {
    updateProject(id: $id, sectionIds: $sectionIds) {
      id
      sections {
        id
        name
      }
    }
  }
`
