const tableDivs = Array.from(document.querySelectorAll('table.fancy td > div'))

if (tableDivs.length > 0) {
  const heights = tableDivs.map((d) => d.offsetHeight)
  const maxHeight = Math.max(...heights)

  tableDivs.map((d) => {
    d.style.height = `${maxHeight}px`
  })
}
