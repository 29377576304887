import { gql } from 'react-apollo'

export default gql`
  query {
    ownedGroups {
      id
      name
      color
      archived
      owner {
        id
        lastName
      }
      sections {
        id
        code
        name
        archived
        students {
          id
          name
        }
      }
    }
    otherGroups {
      id
      name
      color
      archived
      owner {
        id
        lastName
        role
      }
      sections {
        id
        code
        name
        archived
        students {
          id
          name
        }
      }
    }
  }
`
