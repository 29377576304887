import React, { Component } from 'react'
import Modal from 'react-modal'
import QuestionForm from '../containers/question-form'

const className = {
  base: 'modal',
  afterOpen: 'modal-after-open',
  beforeClose: 'modal-before-close',
}

const overlayClassName = {
  base: 'overlay',
  afterOpen: 'overlay-after-open',
  beforeClose: 'overlay-before-close',
}

class QuestionFormModal extends Component {
  render() {
    const { isOpen, onCancel, formProps } = this.props

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onCancel}
        contentLabel="Edit Question"
        className={className}
        overlayClassName={overlayClassName}
        appElement={document.getElementById('admin-questions')}
      >
        <a className="close" onClick={onCancel}>
          Cancel
        </a>
        <QuestionForm {...formProps} />
      </Modal>
    )
  }
}

export default QuestionFormModal
