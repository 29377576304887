import { gql } from 'react-apollo'

export default gql`
  query {
    notifications {
      id
      message
      read
      updatedAt
    }
  }
`
