import { gql } from 'react-apollo'

export default gql`
  mutation sortAssignments($ids: [ID]!) {
    sortAssignments(ids: $ids) {
      id
      sortPosition
    }
  }
`
