import React, { Component } from 'react'
import AssignmentForm from '../components/assignment-form'
import QuestionBank from '../components/question-bank'

class ProjectSidebar extends Component {
  render() {
    const { assignment, data, lastDueAt, onCancel, onMetaSave } = this.props
    return (
      <aside id="project-sidebar">
        {assignment ? (
          <AssignmentForm
            {...assignment}
            key={assignment.id}
            data={data}
            onCancel={onCancel}
            onMetaSave={onMetaSave}
            lastDueAt={lastDueAt}
          />
        ) : (
          <QuestionBank />
        )}
      </aside>
    )
  }
}

export default ProjectSidebar
