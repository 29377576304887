import React, { Component } from 'react'
import TextareaAutosize from 'react-autosize-textarea'
import { graphql } from 'react-apollo'
import annotateAnswer from '../mutations/annotate-answer'
import { formatDate } from '../utils'
import HighlightedText from '../components/highlighted-text'
import HighlightInputControl from '../components/highlight-input-control'
import PostscriptText from './postscript-text'
import { includes, flatten, intersperse, prop, sortBy, uniqBy } from 'ramda'
import SendReminderModal from './send-reminder-modal'

const countStudents = (sections) => {
  return (
    sections &&
    sections.map((s) => s.students.length).reduce((i, acc) => i + acc, 0)
  )
}

const PulseResponseRow = ({
  highlight,
  answer,
  handleNoteUpdated,
  handleNoteFocus,
  role,
  ownerName,
  collabName,
  viewStudentReflections,
}) => {
  return (
    <div className="response-row">
      <div className="container">
        <PulseResponse
          highlight={highlight}
          answer={answer}
          viewStudentReflections={viewStudentReflections}
        />
        <PulseNote
          answer={answer}
          handleNoteUpdated={handleNoteUpdated}
          handleNoteFocus={handleNoteFocus}
          role={role}
          ownerName={ownerName}
          collabName={collabName}
        />
      </div>
    </div>
  )
}

const PulseResponse = ({ highlight, answer, viewStudentReflections }) => {
  const student = answer && answer.user
  const submittedAt =
    answer && formatDate(answer.submittedAt, 'ddd, MMM D [at] h:mma')

  return (
    <div className="response pulse">
      <div
        className="student-name"
        onClick={() => viewStudentReflections(student)}
      >
        {student && student.name}
      </div>
      <div className="submitted-at">
        <i className="material-icons">access_time</i>
        Submitted {submittedAt}
      </div>
      <div className="text textarea">
        {answer && (
          <div>
            <HighlightedText text={answer.text} highlight={highlight} />
            <PostscriptText answer={answer} />
          </div>
        )}
      </div>
      {answer.evidence && (
        <a className="evidence" href={answer.evidence}>
          <i className="material-icons">attach_file</i>
          {decodeURI(answer.evidence.split('/').pop().replace(/\?.+/, ''))}
        </a>
      )}
    </div>
  )
}

const notespace = (editable, opts) => {
  if (editable) {
    return (
      <div>
        <div className="title">Your Notespace</div>
        <TextareaAutosize
          placeholder={'+ Add your notes here'}
          onChange={opts.handleNoteUpdated}
          onFocus={opts.onFocus}
        >
          {opts.notes}
        </TextareaAutosize>
      </div>
    )
  } else {
    const content = opts.notes || 'Collaborator notes will appear here'
    const noteLabel = opts.name || 'Collaborator notespace'

    // TODO spinner
    return (
      <div>
        <div className="title">{noteLabel}</div>
        <span>{content}</span>
      </div>
    )
  }
}

const PulseNote = ({
  answer,
  handleNoteUpdated,
  handleNoteFocus,
  role,
  ownerName,
  collabName,
}) => {
  const { notes, collaboratorNotes } = answer
  const onFocus = answer && handleNoteFocus(answer)
  const opts = {
    handleNoteUpdated: handleNoteUpdated,
    onFocus: onFocus,
  }

  return (
    <div className="response-note pulse">
      <div className="notes teacher">
        {notespace(
          role === 'teacher',
          Object.assign(opts, { notes: notes, name: ownerName })
        )}
      </div>

      <div className="notes collaborator">
        {notespace(
          role === 'collaborator',
          Object.assign(opts, { notes: collaboratorNotes, name: collabName })
        )}
      </div>
    </div>
  )
}

const PulseResponseBody = ({
  highlight,
  students,
  answers,
  handleNoteUpdated,
  handleNoteFocus,
  role,
  ownerName,
  collabName,
  viewStudentReflections,
}) => {
  const numResponses = answers.filter((a) => !a.automatic).length

  return (
    <div className="responses">
      <div className="responses-key">
        <div className="responses-title">
          <h2>
            <span>Responses</span>
          </h2>
          <span className="notes-subtitle">
            {numResponses}/{students.length} Students
          </span>
        </div>
        <div className="notes-title">
          <h2>Teachers' Lounge</h2>
          <span className="notes-subtitle">
            (Viewable only by you and your colleague)
          </span>
        </div>
      </div>
      {answers &&
        answers.map((a) => (
          <PulseResponseRow
            answer={a}
            handleNoteUpdated={handleNoteUpdated}
            handleNoteFocus={handleNoteFocus}
            key={a.id}
            role={role}
            highlight={highlight}
            ownerName={ownerName}
            collabName={collabName}
            viewStudentReflections={viewStudentReflections}
          />
        ))}
    </div>
  )
}

class PulseNotYetResponded extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isReminderModalOpen: false,
    }
  }

  toggleReminderModal = () => {
    const { isReminderModalOpen } = this.state
    this.setState({ isReminderModalOpen: !isReminderModalOpen })
  }

  render() {
    const {
      students,
      answers,
      viewStudentReflections,
      questionId,
      assignment,
    } = this.props
    const { isReminderModalOpen } = this.state
    const studentCount = students.length
    const notRespondedCount = studentCount - answers.length

    if (notRespondedCount > 0) {
      const answeredStudentIds = answers.map((a) => a.user.id)
      const notRespondedStudents = students.filter(
        (s) => !includes(s.id, answeredStudentIds)
      )
      return (
        <div className="responses">
          <div className="responses-key">
            <div className="responses-title">
              <h2 id="not-yet-responded">Not Yet Responded</h2>
              <span className="notes-subtitle">
                {notRespondedCount}/{studentCount} Students
              </span>
            </div>
          </div>
          <div className="container">
            <button onClick={this.toggleReminderModal}>Send Reminder</button>
          </div>
          <div className="response-row">
            <div className="container">
              <div className="response pulse">
                {notRespondedStudents &&
                  intersperse(', ', notRespondedStudents).map((s) => {
                    if (s === ', ') {
                      return s
                    } else {
                      return (
                        <span
                          className="student-name"
                          onClick={() => viewStudentReflections(s)}
                        >
                          {s.name}
                        </span>
                      )
                    }
                  })}
              </div>
            </div>
          </div>
          <SendReminderModal
            isOpen={isReminderModalOpen}
            assignment={assignment}
            onCancel={this.toggleReminderModal}
            students={notRespondedStudents}
            id={questionId}
          />
        </div>
      )
    } else {
      return null
    }
  }
}

@graphql(annotateAnswer, { name: 'annotateAnswer' })
class Pulse extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeAnswer: null,
      selectedSection: null,
    }
  }

  handleNoteUpdated = (e) => {
    const { annotateAnswer } = this.props
    const { activeAnswer } = this.state
    const { id } = activeAnswer
    const notes = e.target.value
    annotateAnswer({
      variables: { id, notes },
    })
  }

  handleNoteFocus = (answer) => {
    return () => {
      this.setState({ activeAnswer: answer })
    }
  }

  isSelectedSection = (section) => {
    const { selectedSection } = this.state
    if (!selectedSection) {
      return !section
    } else if (!section) {
      return !selectedSection
    } else {
      return section.id === selectedSection.id
    }
  }

  setSelectedSection = (section) => {
    this.setState({ selectedSection: section })
  }

  sortedSections = () => {
    const {
      assignment: { project },
    } = this.props
    return sortBy(prop('name'), project.sections)
  }

  selectedStudents = () => {
    const { selectedSection } = this.state
    const {
      assignment: { project },
    } = this.props

    return selectedSection
      ? selectedSection.students
      : uniqBy(prop('id'), flatten(project.sections.map((s) => s.students)))
  }

  selectedSubmittedAnswers = () => {
    const { selectedSection } = this.state
    const { assignment } = this.props
    const {
      submittedAnswers,
      project: { sections },
    } = assignment

    if (!selectedSection) {
      return submittedAnswers
    } else {
      const selectedStudentIds = sections
        .find((s) => s.id === selectedSection.id)
        .students.map((s) => s.id)
      return submittedAnswers.filter((a) =>
        includes(a.user.id, selectedStudentIds)
      )
    }
  }

  render() {
    const {
      assignment,
      toggleAnonymous,
      setViewReflectionsStudent,
      searchText,
      setSearchText,
    } = this.props
    const {
      project: { sections, currentUserRole, collaborator, owner },
    } = assignment

    const ownerName = owner && owner.name
    let collabName = 'No collaborator'
    if (currentUserRole === 'teacher') {
      collabName = collaborator && collaborator.name
    } else if (currentUserRole === 'collaborator') {
      collabName = owner.name
    }

    const viewStudentReflections = (s) => {
      window.viewReflectionsBackAction = this.props.returnTo
      setViewReflectionsStudent(s)
    }

    const groupName = sections && sections.length && sections[0].group.name
    const numStudents = countStudents(sections)
    const assignText =
      assignment && assignment.assignedAt
        ? formatDate(assignment.assignedAt, 'ddd, MMM D [at] h:mma')
        : 'at not set'
    const dueText =
      assignment && assignment.dueAt
        ? formatDate(assignment.dueAt, 'ddd, MMM D [at] h:mma')
        : 'date not set'
    const questionId = assignment && assignment.question.id

    return (
      <section className="full-modal-body">
        <header className="responses-header pulse">
          <div className="container top-pad">
            <div className="info">
              <h1 className="title">
                {assignment && assignment.question.text}
              </h1>
              <div className="details">
                <span className="course-info">
                  {groupName}
                  {'\u00A0\u00A0'}|{'\u00A0\u00A0'}
                  {numStudents} Students{'\u00A0\u00A0'}|{'\u00A0\u00A0'}
                  Assigned {assignText}
                  {'\u00A0\u00A0'}|{'\u00A0\u00A0'}
                </span>
                <span className="due-at">Due {dueText}</span>
              </div>
            </div>
            <div className="actions">
              <a className="print" onClick={() => window.print()}>
                <i className="material-icons">print</i>
                Print
              </a>
              <button onClick={toggleAnonymous}>Anonymize Responses</button>
            </div>
          </div>
          <div className="container">
            <div className="controls">
              <div className="section-select">
                <a
                  className={`${
                    this.isSelectedSection(null) ? 'selected' : ''
                  }`}
                  onClick={() => this.setSelectedSection(null)}
                >
                  All Sections
                </a>
                {this.sortedSections().map((s) => (
                  <a
                    className={`${this.isSelectedSection(s) ? 'selected' : ''}`}
                    onClick={() => this.setSelectedSection(s)}
                  >
                    {s.name}
                  </a>
                ))}
              </div>
            </div>
            <HighlightInputControl
              searchText={searchText}
              setSearchText={setSearchText}
            />
          </div>
        </header>
        <PulseResponseBody
          students={this.selectedStudents()}
          answers={this.selectedSubmittedAnswers()}
          handleNoteUpdated={this.handleNoteUpdated}
          handleNoteFocus={this.handleNoteFocus}
          role={currentUserRole}
          ownerName={ownerName}
          collabName={collabName}
          highlight={searchText}
          viewStudentReflections={viewStudentReflections}
        />
        <PulseNotYetResponded
          students={this.selectedStudents()}
          answers={this.selectedSubmittedAnswers()}
          assignment={assignment}
          viewStudentReflections={viewStudentReflections}
          questionId={questionId}
        />
      </section>
    )
  }
}

export default Pulse
