import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { ApolloProvider, graphql } from 'react-apollo'
import client from '../client'
import fetchOrganizations from '../queries/organizations'
import { formatDate, formatDateOnly } from '../utils'

const sortOrganizations = (organizations, searchText) => {
  if (organizations.length <= 1) return organizations

  const upperSearchText = searchText.toUpperCase()

  return organizations.sort((orgA, orgB) => {
    const aName = orgA.name.toUpperCase()
    const bName = orgB.name.toUpperCase()
    const aIndex = aName.indexOf(upperSearchText)
    const bIndex = bName.indexOf(upperSearchText)

    if (aIndex === bIndex) {
      return 0
    }

    if (aIndex > -1 && bIndex === -1) {
      return -1
    }

    if (bIndex > -1 && aIndex === -1) {
      return 1
    }

    if (aIndex > -1 && bIndex > -1) {
      if (aIndex < bIndex) {
        return -1
      } else {
        return 1
      }
    }

    return 0
  })
}

@graphql(fetchOrganizations)
class OrganizationList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      tab: 'active',
      searchText: '',
    }
  }

  showTab = (tab) => {
    this.setState({ tab: tab })
  }

  handleSearch = (e) => {
    this.setState({ searchText: e.target.value })
  }

  showOrganization = (id) => {
    window.location.href = `/admin/organizations/${id}/edit`
  }

  findLastActiveTeacher = (users) => {
    const teachers = users.filter((user) => {
      return user.role === 'Teacher' && user.teacherDateOfLastActivity
    })

    const maxDate = Math.max(
      ...teachers.map((user) => {
        return new Date(user.teacherDateOfLastActivity)
      })
    )

    const lastActiveTeacher = teachers.filter((user) => {
      return maxDate === new Date(user.teacherDateOfLastActivity).getTime()
    })

    if (lastActiveTeacher.length > 0) {
      return lastActiveTeacher[0].name.split(' ')[1]
    }
  }

  findLastActiveTeacherDate = (users) => {
    const teachers = users.filter((user) => {
      return user.role === 'Teacher' && user.teacherDateOfLastActivity
    })

    const maxDate = Math.max(
      ...teachers.map((user) => {
        return new Date(user.teacherDateOfLastActivity)
      })
    )

    if (maxDate && maxDate > 10000) {
      return new Date(maxDate).toLocaleDateString()
    }
  }

  render() {
    const { tab, searchText } = this.state
    const {
      data: { organizations },
    } = this.props

    const activeLinkClass = tab === 'active' ? 'active' : ''
    const inActiveLinkClass = tab === 'inactive' ? 'active' : ''

    let displayedOrgs =
      organizations &&
      organizations.filter((org) => org.active === (tab === 'active'))

    if (searchText !== '' && displayedOrgs) {
      displayedOrgs = sortOrganizations(displayedOrgs, searchText)
    }

    return (
      <div>
        <div className="container">
          <div className="header">
            <h1>Organizations</h1>
            <nav className="actions">
              <a className="button" href="/admin/organizations/new">
                New Organization
              </a>
            </nav>
          </div>

          <nav className="subnav" role="navigation">
            <a
              className={activeLinkClass}
              onClick={() => this.showTab('active')}
            >
              Active
            </a>
            <a
              className={inActiveLinkClass}
              onClick={() => this.showTab('inactive')}
            >
              Inactive
            </a>
            <div className="search">
              <i className="material-icons">search</i>
              <input
                type="text"
                value={searchText}
                onChange={this.handleSearch}
                placeholder="Search Organizations..."
              />
            </div>
          </nav>
        </div>
        <div className="container">
          <table className="fancy">
            <thead>
              <tr>
                <th>
                  <div>Name</div>
                </th>
                <th>
                  <div>Product</div>
                </th>
                <th>
                  <div>Plan</div>
                </th>
                <th>
                  <div>Timezone</div>
                </th>
                <th>
                  <div># of Users</div>
                </th>
                <th>
                  <div>Last Modified</div>
                </th>
                <th>
                  <div>By</div>
                </th>
                <th>
                  <div>Sub end</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {displayedOrgs &&
                displayedOrgs.map((org) => (
                  <tr onClick={() => this.showOrganization(org.id)}>
                    <td>
                      <div>{org.name}</div>
                    </td>
                    <td>
                      <div>{org.product.name}</div>
                    </td>
                    <td>
                      <div>{org.plan.name}</div>
                    </td>
                    <td>
                      <div>{org.timezone}</div>
                    </td>
                    <td>
                      <div>{org.users.length}</div>
                    </td>
                    <td>
                      <div>{this.findLastActiveTeacherDate(org.users)}</div>
                    </td>
                    <td>
                      <div>{this.findLastActiveTeacher(org.users)}</div>
                    </td>
                    <td>
                      <div>{formatDateOnly(org.subscriptionStart)}</div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

const organizationsList = document.getElementById('organizations-list')
if (organizationsList) {
  ReactDOM.render(
    <ApolloProvider client={client}>
      <OrganizationList />
    </ApolloProvider>,
    organizationsList
  )
}
