import React, { Component } from 'react'
import { graphql } from 'react-apollo'
import fetchGroups from '../queries/group-list'

const goToGroups = (projectId) => {
  const search = projectId ? `?project_id=${projectId}` : ''
  window.location.href = `/groups${search}`
}

const Group = ({ name, onClick }) => {
  return (
    <div className="option" onClick={onClick}>
      {name}
    </div>
  )
}

@graphql(fetchGroups)
class GroupSelect extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showOptions: false,
    }
  }

  toggleOptions = () => {
    const { showOptions } = this.state
    this.setState({ showOptions: !showOptions })
  }

  onSelect = (group) => {
    this.props.onSelect(group)
    this.toggleOptions()
  }

  render() {
    const { projectId, selected, data } = this.props
    const { showOptions } = this.state
    const optionsClass = showOptions ? 'options active' : 'options'
    const activeOwnedGroups =
      data.ownedGroups && data.ownedGroups.filter((g) => !g.archived)
    const activeOtherGroups =
      data.otherGroups && data.otherGroups.filter((g) => !g.archived)

    return (
      <div className="select">
        <div className="trigger" onClick={this.toggleOptions}>
          <span>{selected ? selected.name : 'Select Group'}</span>
          <i className="material-icons">keyboard_arrow_down</i>
        </div>
        <input
          type="text"
          name="group"
          value={selected}
          onFocus={this.toggleOptions}
          onBlur={this.toggleOptions}
        />
        <div className={optionsClass}>
          <div className="option-group">
            <label>My Groups</label>
            {activeOwnedGroups &&
              activeOwnedGroups.map((g) => (
                <Group
                  key={g.id}
                  name={g.name}
                  onClick={() => this.onSelect(g)}
                />
              ))}
          </div>
          <div className="option-group">
            <label>Other Groups</label>
            {activeOtherGroups &&
              activeOtherGroups.map((g) => (
                <Group
                  key={g.id}
                  name={g.name}
                  onClick={() => this.onSelect(g)}
                />
              ))}
          </div>
          <footer>
            <a onClick={() => goToGroups(projectId)}>
              <i className="material-icons">add_circle</i>
              Create New Group
            </a>
          </footer>
        </div>
      </div>
    )
  }
}

export default GroupSelect
