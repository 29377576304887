import React, { Component } from 'react'
import { Doughnut } from 'react-chartjs-2'
import { learnerTypeColors } from '../utils'

class LearnerTypeChart extends Component {
  render() {
    const { questions } = this.props

    const options = {
      percentageInnerCutout: 50,
      segmentShowStroke: false,
      animateRotate: false,
      animateScale: false,
      showTooltips: false,
      maintainAspectRatio: false,
    }
    let percentages = {}
    let percentage

    let data = Object.keys(learnerTypeColors).map((learnerType) => {
      const numWithType = questions.filter(
        (q) => q.question.learnerType === learnerType
      ).length

      if (questions.length === 0) {
        percentage = 0
      } else {
        percentage = ((numWithType / questions.length) * 100.0).toFixed(1)
      }
      percentages[learnerType] = percentage

      return {
        value: numWithType,
        color: learnerTypeColors[learnerType],
        label: learnerType,
      }
    })

    let labels = []
    let backgroundColor = []
    let dataset = []

    data.forEach((set) => {
      dataset.push(set.value)
      backgroundColor.push(set.color)
      labels.push(set.label)
    })

    const dataobj = {
      datasets: [
        {
          data: dataset,
          labels: labels,
          backgroundColor: backgroundColor,
        },
      ],
    }

    return (
      <div className="learner-type-chart">
        <div className="chart-container">
          <Doughnut data={dataobj} options={options} />
        </div>
        <div className="legend">
          <h5>Learner Profile:</h5>
          {Object.keys(learnerTypeColors).map((learnerType) => (
            <div key={learnerType} className="legend-item">
              <div
                className="legend-circle"
                style={{ backgroundColor: learnerTypeColors[learnerType] }}
              />
              {learnerType}: {percentages[learnerType]}%
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export default LearnerTypeChart
