import React, { Component } from 'react'
import { compose, flatten, prop, sortBy, split, path, uniqBy } from 'ramda'

class SelectStudent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      searchText: '',
      selectedSection: null,
    }
  }

  handleSearch = (e) => {
    this.setState({ searchText: e.target.value })
  }

  filterStudents = (student) => {
    const { searchText } = this.state
    const search = new RegExp(searchText, 'i')
    return search.test(student.name)
  }

  isSelectedSection = (section) => {
    const { selectedSection } = this.state
    if (!selectedSection) {
      return !section
    } else if (!section) {
      return !selectedSection
    } else {
      return section.id === selectedSection.id
    }
  }

  setSelectedSection = (section) => {
    this.setState({ selectedSection: section })
  }

  sortedSections = () => {
    const { project } = this.props
    return sortBy(prop('name'), project.sections)
  }

  selectedStudents = () => {
    const { selectedSection } = this.state
    const { project } = this.props

    return selectedSection
      ? sortBy(
          compose(path([1]), split(' '), prop('name')),
          selectedSection.students
        )
      : sortBy(
          compose(path([1]), split(' '), prop('name')),
          uniqBy(prop('id'), flatten(project.sections.map((s) => s.students)))
        )
  }

  render() {
    const { searchText } = this.state
    const { project, onSelect } = this.props

    return (
      <div id="select-student">
        <header className="responses-header">
          <div className="container top-pad">
            <div className="info">
              <h1 className="title">Select Student</h1>
              <div className="details">
                <span className="course-info">{project.group.name}</span>
              </div>
            </div>
            <div className="actions">
              <div className="search">
                <i className="material-icons">search</i>
                <input
                  type="text"
                  value={searchText}
                  onChange={this.handleSearch}
                  placeholder="Search Students..."
                />
              </div>
            </div>
          </div>
          <div className="container">
            <div className="controls">
              <div className="section-select">
                <a
                  className={`${
                    this.isSelectedSection(null) ? 'selected' : ''
                  }`}
                  onClick={() => this.setSelectedSection(null)}
                >
                  All Sections
                </a>
                {this.sortedSections().map((s) => (
                  <a
                    className={`${this.isSelectedSection(s) ? 'selected' : ''}`}
                    onClick={() => this.setSelectedSection(s)}
                  >
                    {s.name}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </header>
        <div className="container">
          <div className="student-list">
            <table className="fancy">
              <thead>
                <tr>
                  <th>
                    <div>Name</div>
                  </th>
                  <th>
                    <div>Graduation Year</div>
                  </th>
                  <th>
                    <div />
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.selectedStudents()
                  .filter(this.filterStudents)
                  .map((s) => (
                    <tr onClick={() => onSelect(s)}>
                      <td>
                        <div>{s.name}</div>
                      </td>
                      <td>
                        <div>{s.graduation_year}</div>
                      </td>
                      <td className="actions">
                        <div>
                          <a>
                            View Journal
                            <i className="material-icons">arrow_forward</i>
                          </a>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}

export default SelectStudent
