import { gql } from 'react-apollo'

export default gql`
  query findPulse($id: ID!) {
    findPulse(id: $id) {
      id
      assignedAt
      dueAt
      question {
        id
        text
      }
      submittedAnswers {
        id
        text
        evidence
        submittedAt
        notes
        collaboratorNotes
        automatic
        postscript {
          id
          text
          insertedAt
        }
        user {
          id
          name
        }
      }
      project {
        id
        name
        currentUserRole
        owner {
          id
          name
        }
        collaborator {
          id
          name
        }
        sections {
          id
          name
          group {
            id
            name
          }
          students {
            id
            name
            graduation_year
          }
        }
      }
    }
  }
`
