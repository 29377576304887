import React, { Component } from 'react'
import { graphql } from 'react-apollo'
import Modal from 'react-modal'
import GroupSelect from './group-select'
import SectionPicker from './section-picker'
import findProject from '../queries/find-project'
import { equals } from 'ramda'

const className = {
  base: 'modal',
  afterOpen: 'modal-after-open',
  beforeClose: 'modal-before-close',
}

const overlayClassName = {
  base: 'overlay',
  afterOpen: 'overlay-after-open',
  beforeClose: 'overlay-before-close',
}

@graphql(findProject)
class SelectSectionsModal extends Component {
  constructor(props) {
    super(props)
    this.state = { group: null }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      data: { project },
    } = nextProps

    if (
      nextProps.selected &&
      !equals(nextProps.selected, this.props.selected)
    ) {
      const group = nextProps.selected.map((s) => s.group)[0]
      this.setState({ group })
    }

    const liveQuestions = (project && project.liveQuestions) || []
    this.setState({ liveQuestions })
  }

  closeModal = () => {
    this.props.onCancel()
  }

  handleGroupSelect = (group) => {
    this.setState({ group })
  }

  render() {
    const { isOpen, onCancel, onSave, selected, projectId } = this.props
    const { group, liveQuestions } = this.state

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onCancel}
        contentLabel="Select Sections Modal"
        className={className}
        overlayClassName={overlayClassName}
      >
        <a className="close" onClick={this.closeModal}>
          Cancel
        </a>
        <h2>Pick Group</h2>
        <GroupSelect
          projectId={projectId}
          selected={group}
          onSelect={(g) => this.handleGroupSelect(g)}
        />
        {group && (
          <SectionPicker
            projectId={projectId}
            liveQuestionCount={liveQuestions.length}
            group={group}
            onSave={onSave}
            selected={selected && selected.map((s) => s.id)}
          />
        )}
      </Modal>
    )
  }
}

export default SelectSectionsModal
