import { gql } from 'react-apollo'

export default gql`
  mutation updateSection($id: ID!, $name: String!, $studentIds: [ID]!) {
    updateSection(id: $id, name: $name, studentIds: $studentIds) {
      id
      name
      archived
      students {
        id
        name
        email
        graduation_year
      }
    }
  }
`
