import React, { Component } from 'react'
import ColorSelect from './color-select'
import { graphql } from 'react-apollo'
import fetchGroups from '../queries/group-list'
import createGroup from '../mutations/create-group'
import updateGroup from '../mutations/update-group'

const navigateToSectionsNew = (groupId) => {
  const { search } = window.location
  window.location.href = `/groups/${groupId}/sections/new${search}`
}

@graphql(createGroup, { name: 'createGroup' })
@graphql(updateGroup, { name: 'updateGroup' })
class GroupForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isEdit: !!this.props.name,
      name: this.props.name,
      color: this.props.color || '5A6175',
    }
  }

  resetState = () => {
    this.setState({
      isEdit: !!this.props.name,
      name: this.props.name,
      color: this.props.color || '5A6175',
    })
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.name !== prevProps.name) {
      this.resetState()
    }
  }

  handleChange = (event) => {
    this.setState({ name: event.target.value })
  }

  handleSubmit = (event) => {
    event.preventDefault()

    const { isEdit, name, color } = this.state
    const { id, dismiss, createGroup, updateGroup } = this.props

    if (isEdit) {
      updateGroup({
        refetchQueries: [{ query: fetchGroups }],
        variables: { id, name, color },
      }).then(dismiss)
    } else {
      createGroup({
        variables: { name, color },
      }).then(({ data }) => {
        navigateToSectionsNew(data.createGroup.id)
      })
    }
  }

  render() {
    const { isActive, dismiss } = this.props
    const { isEdit, name, color } = this.state
    const activeClass = isActive ? 'group-card form active' : 'group-card form'
    const title = isEdit ? 'Edit Group' : 'Create New Group'
    const saveText = isEdit ? 'Save' : 'Save And Add Section'

    return (
      <div className={activeClass} onClick={(e) => e.stopPropagation()}>
        <div className="group-form-header">
          {title}
          <div className="dismiss" onClick={dismiss}>
            <i className="material-icons">close</i>
          </div>
        </div>
        <form onSubmit={this.handleSubmit}>
          <div className="field floating">
            <input
              type="text"
              name="name"
              value={name}
              onChange={this.handleChange}
              required
            />
            <div className="highlight" />
            <label>Course Name</label>
          </div>
          <ColorSelect
            color={color}
            onSelect={(c) => this.setState({ color: c })}
          />
          <button className="group-save">{saveText}</button>
        </form>
      </div>
    )
  }
}

export default GroupForm
