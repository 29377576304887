import React from 'react'
import Autocomplete from 'react-autocomplete'

const HiglightInputControl = ({ searchText, setSearchText }) => {
  return (
    <Autocomplete
      wrapperProps={{
        className: 'search-autocomplete',
      }}
      items={[
        { label: 'goal', value: 'goal' },
        { label: 'help', value: 'help' },
        { label: 'feel', value: 'feel' },
        { label: 'belief / believe', value: 'belie' },
        { label: 'realize / realization', value: 'realiz' },
        { label: 'need', value: 'need' },
        { label: 'work', value: 'work' },
        { label: 'teacher', value: 'teacher' },
        { label: 'consider', value: 'consider' },
        { label: 'open', value: 'open' },
        { label: 'focus', value: 'focus' },
        { label: 'bias', value: 'bias' },
        { label: 'view', value: 'view' },
        { label: 'comfort', value: 'comfort' },
        { label: 'question', value: 'question' },
        { label: 'practice', value: 'practice' },
        { label: 'problem', value: 'problem' },
        { label: 'issue', value: 'issue' },
        { label: 'meaning', value: 'meaning' },
        { label: 'personal', value: 'personal' },
      ]}
      getItemValue={(item) => item.value}
      onChange={(e) => setSearchText(e.target.value)}
      onSelect={setSearchText}
      renderItem={(item, isHighlighted) => (
        <div style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
          {item.label}
        </div>
      )}
      inputProps={{
        placeholder: 'Search Reflections',
      }}
      menuStyle={{
        borderRadius: '3px',
        boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
        background: 'rgba(255, 255, 255, 0.9)',
        padding: '2px 0',
        fontSize: '90%',
        position: 'fixed',
        overflow: 'auto',
        maxHeight: '50%',
        zIndex: 1,
      }}
      value={searchText}
    />
  )
}

export default HiglightInputControl
