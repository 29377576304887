import { gql } from 'react-apollo'

export default gql`
  mutation createGroup($name: String!, $color: String!) {
    createGroup(name: $name, color: $color) {
      id
      name
      color
    }
  }
`
