import { gql } from 'react-apollo'

export default gql`
  mutation addSectionToProject($id: ID!, $sectionId: ID!) {
    addSectionToProject(id: $id, sectionId: $sectionId) {
      id
      name
      sections {
        id
        name
      }
    }
  }
`
