import { gql } from 'react-apollo'

export default gql`
  query outstandingCount {
    projects {
      id
      outstandingAssignments {
        id
      }
    }
  }
`
