import React, { Component } from 'react'
import { findDOMNode } from 'react-dom'
import { DragSource, DropTarget } from 'react-dnd'

const bankSource = {
  beginDrag(props) {
    return {
      index: props.index,
      bank: props.bank,
    }
  },
}

const bankTarget = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index
    const hoverIndex = props.index

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return
    }

    // Determine rectangle on screen
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect()

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

    // Determine mouse position
    const clientOffset = monitor.getClientOffset()

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return
    }

    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return
    }

    // Time to actually perform the action
    props.moveBank(dragIndex, hoverIndex)

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex
  },
}

const onMouseEnter = (e) => {
  e.target.classList.add('hover')
}

const onMouseLeave = (e) => {
  e.target.classList.remove('hover')
}

@DropTarget(['bank'], bankTarget, (connect) => ({
  connectDropTarget: connect.dropTarget(),
}))
@DragSource('bank', bankSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
}))
class Bank extends Component {
  render() {
    const {
      bank,
      showArchiveStatus,
      isDragging,
      connectDragSource,
      connectDropTarget,
    } = this.props
    const opacity = isDragging ? 0 : 1
    const cursor = isDragging ? 'move' : 'pointer'
    const center = { textAlign: 'center' }

    return connectDragSource(
      connectDropTarget(
        <tr
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          key={bank.id}
          style={{ opacity, cursor }}
          className={bank.archived ? 'bank disabled' : 'bank'}
        >
          <td>
            <i className="material-icons draggable">drag_indicator</i>
            {bank.name}
          </td>
          <td style={center}>{bank.count}</td>
          <td style={center}>{bank.initial}</td>
          <td style={center}>{bank.midstream}</td>
          <td style={center}>{bank.wrapup}</td>
          <td style={center}>{bank.meta}</td>
          <td style={center}>{showArchiveStatus(bank)}</td>
          <td style={center}>
            <a href={`/admin/banks/${bank.id}/edit`}>Edit</a>
          </td>
        </tr>
      )
    )
  }
}

export default Bank
