import React, { Component } from 'react'
import { formatDate } from '../utils'
import HighlightedText from '../components/highlighted-text'
import HighlightInputControl from '../components/highlight-input-control'
import PostscriptText from './postscript-text'
import { includes, flatten, prop, sortBy, uniqBy } from 'ramda'

const countStudents = (sections) => {
  return (
    sections &&
    sections.map((s) => s.students.length).reduce((i, acc) => i + acc, 0)
  )
}

const AnonResponse = ({ answer, highlight }) => {
  return (
    <div className="response-row reflection">
      <div className="container">
        <div className="response">
          <HighlightedText text={answer.text} highlight={highlight} />
          <PostscriptText answer={answer} />
          {answer.evidence && (
            <a className="evidence" href={answer.evidence}>
              <i className="material-icons">attach_file</i>
              {decodeURI(answer.evidence.split('/').pop().replace(/\?.+/, ''))}
            </a>
          )}
        </div>
      </div>
    </div>
  )
}

const AnonResponses = ({ answers, highlight }) => {
  return (
    <div className="responses anonymous">
      {answers.map((a) => (
        <AnonResponse answer={a} highlight={highlight} key={a.id} />
      ))}
    </div>
  )
}

class PulseAnonymous extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedSection: null,
    }
  }

  isSelectedSection = (section) => {
    const { selectedSection } = this.state
    if (!selectedSection) {
      return !section
    } else if (!section) {
      return !selectedSection
    } else {
      return section.id === selectedSection.id
    }
  }

  setSelectedSection = (section) => {
    this.setState({ selectedSection: section })
  }

  sortedSections = () => {
    const {
      assignment: { project },
    } = this.props
    return sortBy(prop('name'), project.sections)
  }

  selectedStudents = () => {
    const { selectedSection } = this.state
    const {
      assignment: { project },
    } = this.props

    return selectedSection
      ? selectedSection.students
      : uniqBy(prop('id'), flatten(project.sections.map((s) => s.students)))
  }

  selectedSubmittedAnswers = () => {
    const { selectedSection } = this.state
    const { assignment } = this.props
    const {
      submittedAnswers,
      project: { sections },
    } = assignment

    if (!selectedSection) {
      return submittedAnswers
    } else {
      const selectedStudentIds = sections
        .find((s) => s.id === selectedSection.id)
        .students.map((s) => s.id)
      return submittedAnswers.filter((a) =>
        includes(a.user.id, selectedStudentIds)
      )
    }
  }

  render() {
    const { assignment, toggleAnonymous, searchText, setSearchText } =
      this.props
    const {
      project: { sections },
    } = assignment
    const groupName = sections && sections.length && sections[0].group.name
    const numStudents = countStudents(sections)

    const assignText =
      assignment && assignment.assignedAt
        ? formatDate(assignment.assignedAt, 'ddd, MMM D [at] h:mma')
        : 'at not set'

    const dueText =
      assignment && assignment.dueAt
        ? formatDate(assignment.dueAt, 'ddd, MMM D [at] h:mma')
        : 'date not set'

    return (
      <section className="full-modal-body">
        <header className="responses-header pulse anonymous">
          <div className="container top-pad">
            <div className="info">
              <h1 className="title">
                {assignment && assignment.question.text}
              </h1>
              <div className="details">
                <span className="course-info">
                  {groupName}
                  {'\u00A0\u00A0'}|{'\u00A0\u00A0'}
                  {numStudents} Students{'\u00A0\u00A0'}|{'\u00A0\u00A0'}
                  Assigned {assignText}
                  {'\u00A0\u00A0'}|{'\u00A0\u00A0'}
                </span>
                <span className="due-at">Due {dueText}</span>
              </div>
            </div>
            <div className="actions">
              <a className="print" onClick={() => window.print()}>
                <i className="material-icons">print</i>
                Print
              </a>
              <button onClick={toggleAnonymous}>Show Names</button>
            </div>
          </div>
          <div className="container">
            <div className="controls">
              <div className="section-select">
                <a
                  className={`${
                    this.isSelectedSection(null) ? 'selected' : ''
                  }`}
                  onClick={() => this.setSelectedSection(null)}
                >
                  All Sections
                </a>
                {this.sortedSections().map((s) => (
                  <a
                    className={`${this.isSelectedSection(s) ? 'selected' : ''}`}
                    onClick={() => this.setSelectedSection(s)}
                  >
                    {s.name}
                  </a>
                ))}
              </div>
            </div>
            <HighlightInputControl
              searchText={searchText}
              setSearchText={setSearchText}
            />
          </div>
        </header>
        <AnonResponses
          answers={this.selectedSubmittedAnswers()}
          highlight={searchText}
        />
      </section>
    )
  }
}

export default PulseAnonymous
