import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { ApolloProvider, graphql } from 'react-apollo'
import client from '../client'
import fetchProjects from '../queries/project-list'
import ShareProject from '../components/share-project'
import ProjectRow from '../components/project-row'

const ProjectsTableHead = ({ isShared }) => {
  return (
    <thead>
      <tr>
        <th>
          <div>ReflEQ Name</div>
        </th>
        <th>
          <div>Created On</div>
        </th>
        <th>
          <div>Group(s)</div>
        </th>
        <th>
          <div>Students</div>
        </th>
        <th>
          <div>Questions</div>
        </th>
        <th>
          <div>{isShared ? 'Shared By' : 'Shared With'}</div>
        </th>
        <th>
          <div />
        </th>
        <th>
          <div />
        </th>
      </tr>
    </thead>
  )
}

const ProjectsTable = ({ projects, isShared, toggleShareProject }) => {
  if (projects && projects.length > 0) {
    return (
      <table className="fancy">
        <ProjectsTableHead isShared={isShared} />
        <tbody>
          {projects &&
            projects.map((p) => (
              <ProjectRow
                key={p.id}
                data={p}
                isShared={isShared}
                toggleShareProject={toggleShareProject}
              />
            ))}
        </tbody>
      </table>
    )
  } else {
    return <p>No projects yet</p>
  }
}

const getQueryVariable = (variable) => {
  var query = window.location.search.substring(1)
  var vars = query.split('&')
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=')
    if (pair[0] === variable) {
      return pair[1]
    }
  }
  return false
}

@graphql(fetchProjects)
class ProjectList extends Component {
  constructor(props) {
    super(props)

    const urlTab = getQueryVariable('tab')

    this.state = {
      toShareProject: null,
      shareModalOpen: false,
      activeTab: urlTab || 'my-refleqs',
    }
  }

  toggleShareProject = (project) => {
    const { shareModalOpen } = this.state
    this.setState({
      toShareProject: project,
      shareModalOpen: !shareModalOpen,
    })
  }

  onCancel = () => {
    this.toggleShareProject(null)
  }

  afterShare = () => {
    this.props.data.refetch()
  }

  showTab = (tabName) => {
    this.setState({ activeTab: tabName })
  }

  renderTabControl = (tabName, tabDisplayName) => {
    const { activeTab } = this.state
    let className = ''

    if (activeTab === tabName) {
      className = 'active'
    }

    return (
      <a className={className} onClick={() => this.showTab(tabName)}>
        {tabDisplayName}
      </a>
    )
  }

  render() {
    const {
      data: { projects, sharedProjects },
    } = this.props
    const { toShareProject, shareModalOpen, activeTab } = this.state

    return (
      <div className="project-list">
        <nav className="tabbed-subnav" role="navigation">
          <div className="container">
            {this.renderTabControl('my-refleqs', 'My ReflEQs')}
            {this.renderTabControl('shared-refleqs', 'Shared With Me')}
          </div>
        </nav>
        <div className="container">
          {activeTab === 'my-refleqs' && (
            <ProjectsTable
              projects={projects}
              toggleShareProject={this.toggleShareProject}
            />
          )}
          {activeTab === 'shared-refleqs' && (
            <ProjectsTable
              projects={sharedProjects}
              toggleShareProject={this.toggleShareProject}
              isShared
            />
          )}
          <ShareProject
            project={toShareProject}
            isOpen={shareModalOpen}
            onCancel={this.onCancel}
            afterShare={this.afterShare}
          />
        </div>
      </div>
    )
  }
}

const projectList = document.getElementById('project-list')
if (projectList) {
  ReactDOM.render(
    <ApolloProvider client={client}>
      <ProjectList />
    </ApolloProvider>,
    projectList
  )
}
