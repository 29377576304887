import React, { Component } from 'react'
import VisibilitySensor from 'react-visibility-sensor'
import { graphql } from 'react-apollo'
import markRead from '../mutations/mark-read'
import { distanceInWords } from 'date-fns'

@graphql(markRead, { name: 'markRead' })
class Notification extends Component {
  handleMarkRead = (isVisible) => {
    if (!isVisible) return // Only mark if visible

    const {
      data: { id, read },
      markRead,
    } = this.props

    if (read) return // Don't mark if already read

    markRead({ variables: { id } })
  }

  render() {
    const { active, data, parentRef } = this.props
    const now = new Date()
    const nowUTC = new Date(now.valueOf() + now.getTimezoneOffset() * 60000)

    if (!data) return null

    return (
      <VisibilitySensor
        active={active}
        container={parentRef}
        onChange={this.handleMarkRead}
        intervalCheck={false}
        scrollCheck
      >
        <div className="notification">
          <div className="message">
            <p dangerouslySetInnerHTML={{ __html: data.message }} />
          </div>
          <div className="created-at">
            <p>{distanceInWords(data.updatedAt, nowUTC)} ago</p>
          </div>
        </div>
      </VisibilitySensor>
    )
  }
}

export default Notification
