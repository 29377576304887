import React, { Component } from 'react'
import {
  addMonths,
  endOfMonth,
  format,
  getDate,
  getDay,
  setDate,
  startOfMonth,
  subMonths,
  isAfter,
} from 'date-fns'
import { range, splitEvery } from 'ramda'

const Day = ({ i, w, d, date, key, onClick, min, max }) => {
  let day = setDate(date || new Date(), i)
  const prevMonth = w === 0 && i > 7
  const nextMonth = w >= 4 && i <= 14
  const midnightMin = min.setHours(0, 0, 0, 0)

  if (prevMonth) {
    day = subMonths(date, 1)
  }

  if (nextMonth) {
    day = addMonths(date, 1)
  }

  const isBeforeMin = min ? day.getTime() < midnightMin : false
  const isBeyondMax = max ? isAfter(day, max) : false
  const disallowed = isBeforeMin || isBeyondMax

  let classes = ''
  classes += disallowed ? ' disallowed' : ''
  classes += prevMonth ? ' prev-month' : ''
  classes += nextMonth ? ' next-month' : ''
  classes += !prevMonth && !nextMonth && i === d ? ' current-day' : ''

  if (disallowed) {
    return (
      <td key={key} className={classes}>
        <span>{i}</span>
      </td>
    )
  } else {
    return (
      <td key={key} className={classes} onClick={onClick}>
        <span>{i}</span>
      </td>
    )
  }
}

class Calendar extends Component {
  selectDate = (i, w) => {
    const { date, onChange, min, max } = this.props
    const prevMonth = w === 0 && i > 7
    const nextMonth = w >= 4 && i <= 14
    const d = setDate(date || new Date(), i)

    if (prevMonth) {
      let newDate = subMonths(d, 1)

      if (!min || newDate >= min) {
        return onChange(newDate)
      } else {
        return onChange(min)
      }
    }

    if (nextMonth) {
      let newDate = addMonths(d, 1)

      if (!max || newDate <= max) {
        return onChange(newDate)
      } else {
        return onChange(max)
      }
    }

    return onChange(d)
  }

  prevMonth = (e) => {
    e.preventDefault()
    const { date, onChange, min } = this.props
    const newDate = subMonths(date, 1)

    if (!min || newDate >= min) {
      return onChange(newDate, 'prev')
    } else {
      return onChange(min, 'prev')
    }
  }

  nextMonth = (e) => {
    e.preventDefault()
    const { date, onChange, max } = this.props
    const newDate = addMonths(date, 1)

    if (!max || newDate <= max) {
      return onChange(newDate, 'next')
    } else {
      return onChange(max, 'next')
    }
  }

  render() {
    const d = this.props.date || new Date()
    const d1 = getDate(endOfMonth(subMonths(d, 1)))
    const d2 = getDay(startOfMonth(d))
    const d3 = getDate(endOfMonth(d))
    const days = [].concat(
      range(d1 - d2 + 1, d1 + 1),
      range(1, d3 + 1),
      range(1, 42 - d3 - d2 + 1)
    )
    const weeks = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']

    return (
      <div className="calendar">
        <div className="toolbar">
          <button type="button" className="prev-month" onClick={this.prevMonth}>
            <i className="material-icons">keyboard_arrow_left</i>
          </button>
          <span className="current-date">{format(d, 'MMMM YYYY')}</span>
          <button type="button" className="next-month" onClick={this.nextMonth}>
            <i className="material-icons">keyboard_arrow_right</i>
          </button>
        </div>
        <table>
          <thead>
            <tr>
              {weeks.map((w, i) => (
                <th key={i}>{w}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {splitEvery(7, days).map((row, w) => (
              <tr key={w}>
                {row.map((i) => (
                  <Day
                    key={i}
                    i={i}
                    d={getDate(d)}
                    date={d}
                    w={w}
                    onClick={() => this.selectDate(i, w)}
                    min={this.props.min}
                    max={this.props.max}
                  />
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }
}

export default Calendar
