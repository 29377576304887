import React, { Component } from 'react'
import Modal from 'react-modal'
import { graphql } from 'react-apollo'
import sendReminderEmail from '../mutations/send-reminder-email'
import { formatDate } from '../utils'

const className = {
  base: 'modal wide',
  afterOpen: 'modal-after-open',
  beforeClose: 'modal-before-close',
}

const overlayClassName = {
  base: 'overlay',
  afterOpen: 'overlay-after-open',
  beforeClose: 'overlay-before-close',
}

const SuccessMessage = ({ onCancel }) => {
  return (
    <div>
      <h2>Reminder Emails have been sent!</h2>
      <p>
        A reminder email has been sent to each student who has not yet submitted
        a reflection.
      </p>
      <button onClick={onCancel} className="close-button">
        Close Window
      </button>
    </div>
  )
}

const ReminderForm = ({ emailBody, handleSubmit, handleChange, question }) => {
  return (
    <div>
      <h2>Reminder email</h2>
      <p style={{ fontStyle: 'italic' }}>{question}</p>
      <form onSubmit={handleSubmit}>
        <div style={{ textAlign: 'left' }}>
          Instructor: You can personalize the text below with any specific
          instructions, etc.
        </div>
        <textarea value={emailBody} onChange={handleChange} />
        <button>Submit</button>
      </form>
    </div>
  )
}

@graphql(sendReminderEmail, { name: 'sendReminderEmail' })
class SendReminderModal extends Component {
  constructor(props) {
    super(props)

    const { assignment } = this.props
    const due = formatDate(this.props.assignment.dueAt, 'MM/DD/YYYY')

    this.state = {
      isSubmitted: false,
      link: `Link: [${assignment.question.text}](https://my.refleq.com/my-reflections/${assignment.project.id})`,
      emailBody: `
Hi students:

Please submit your reflection to this question in the ${assignment.project.name}.  It has a due date of ${due}.

Thank you.
        `,
    }
  }

  closeModal = () => {
    this.setState({ isSubmitted: false })
    this.props.onCancel()
  }

  handleChange = (e) => {
    this.setState({ emailBody: e.target.value })
  }

  handleSubmit = (e) => {
    e.preventDefault()

    const { emailBody, link } = this.state
    const { students, id, sendReminderEmail } = this.props

    sendReminderEmail({
      variables: {
        id: id,
        emailBody: this.addParagraphs(emailBody),
        link: link,
        studentIds: students.map((s) => s.id),
      },
    }).then(() => {
      this.setState({
        emailBody: '',
        isSubmitted: true,
      })
    })
  }

  addParagraphs = (emailBody) => {
    return emailBody.replace(/\n/g, '  \n')
  }

  render() {
    const { isSubmitted, emailBody } = this.state
    const { isOpen, onCancel, assignment } = this.props

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onCancel}
        className={className}
        overlayClassName={overlayClassName}
      >
        <a className="close" onClick={this.closeModal}>
          Cancel
        </a>
        {isSubmitted ? (
          <SuccessMessage onCancel={this.closeModal} />
        ) : (
          <ReminderForm
            emailBody={emailBody}
            handleChange={this.handleChange}
            handleSubmit={this.handleSubmit}
            question={assignment.question.text}
          />
        )}
      </Modal>
    )
  }
}

export default SendReminderModal
