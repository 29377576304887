import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { ApolloProvider, graphql } from 'react-apollo'
import client from '../client'
import fetchOutstandingCount from '../queries/outstanding-count'
import { sum } from 'ramda'

@graphql(fetchOutstandingCount)
class OutstandingCount extends Component {
  render() {
    const {
      data: { projects },
    } = this.props
    const count =
      projects && sum(projects.map((p) => p.outstandingAssignments.length))
    if (count && count > 0) {
      return <div className="badge">{count}</div>
    } else {
      return null
    }
  }
}

const outstandingCount = document.getElementById('outstanding-count')
if (outstandingCount) {
  ReactDOM.render(
    <ApolloProvider client={client}>
      <OutstandingCount />
    </ApolloProvider>,
    outstandingCount
  )
}
