const dropdownTriggers = document.querySelectorAll('[data-dropdown]')

Array.prototype.forEach.call(dropdownTriggers, (trigger) => {
  trigger.addEventListener('click', (e) => {
    e.preventDefault()

    const dropdownId = trigger.getAttribute('data-dropdown')
    const dropdown = document.getElementById(dropdownId)

    if (dropdown.classList.contains('active')) {
      dropdown.classList.remove('active')
    } else {
      dropdown.classList.add('active')
    }
  })
})

document.body.addEventListener('click', (e) => {
  const activeDropdowns = document.querySelectorAll('.dropdown.active')
  const isDropdown = e.target.classList.contains('dropdown')
  const isTrigger = e.target.getAttribute('data-dropdown')
  const inDropdown = e.target.closest('.dropdown')

  if (!isDropdown && !isTrigger && !inDropdown) {
    Array.prototype.forEach.call(activeDropdowns, (dropdown) => {
      dropdown.classList.remove('active')
    })
  }
})
