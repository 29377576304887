/* eslint no-param-reassign: 0 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// NodeList.forEach Polyfill for IE & Edge
if (window.NodeList && !window.NodeList.prototype.forEach) {
  window.NodeList.prototype.forEach = function (callback, thisArg) {
    thisArg = thisArg || window
    for (var i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this)
    }
  }
}

export default class HeightMatchingGroup extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    selector: PropTypes.string,
    tagName: PropTypes.string,
  }

  static defaultProps = {
    selector: '.match-height',
    tagName: 'span',
  }

  constructor({ tagName }) {
    super()
    this.RootTag = `${tagName}`
  }

  componentDidMount() {
    window.addEventListener('resize', this.matchHeights)
    this.preloadAndRun()
  }

  componentDidUpdate() {
    this.preloadAndRun()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.matchHeights)
  }

  getContainerRef = (node) => {
    this.container = node
  }

  getMaxHeight = (els) =>
    Array.prototype.map
      .call(els, (el) => el.offsetHeight)
      .reduce((pre, cur) => Math.max(pre, cur), -Infinity)

  loadImages = () => {
    const p = new Promise((resolve) => {
      const images = this.container.querySelectorAll('img')

      let loaded = 0

      const handleImage = () => {
        loaded += 1
        if (loaded === images.length) resolve()
      }

      images.forEach((image) => {
        image.onload = handleImage
      })
    })

    return p
  }

  preloadAndRun = () => {
    this.loadImages().then(this.matchHeights)
    setTimeout(this.matchHeights, 0)
  }

  matchHeights = () => {
    const els = this.container.querySelectorAll(this.props.selector)
    els.forEach((el) => {
      el.style.height = null
    })
    const maxHeight = this.getMaxHeight(els)
    els.forEach((el) => {
      el.style.height = `${maxHeight}px`
    })
  }

  render() {
    const CustomTag = `${this.RootTag}`

    return (
      <CustomTag
        className={this.props.className}
        ref={this.getContainerRef}
        onClick={this.props.onClick}
      >
        {this.props.children}
      </CustomTag>
    )
  }
}
