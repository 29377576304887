import React, { Component } from 'react'

class FloatingLabel extends Component {
  render() {
    const { children, error, labelFor, labelText } = this.props
    const classNames = error ? 'field floating error' : 'field floating'
    return (
      <div className={classNames}>
        {children}
        <div className="highlight" />
        <label htmlFor={labelFor}>{labelText}</label>
        {error && <span className="error-message">{error}</span>}
      </div>
    )
  }
}

export default FloatingLabel
