import { gql } from 'react-apollo'

export default gql`
  query {
    organizations {
      id
      name
      subscriptionStart
      timezone
      active
      updatedAt

      product {
        name
      }

      plan {
        name
      }

      users {
        id
        name
        role
        teacherDateOfLastActivity
      }
    }
  }
`
