const setupTabLink = document.querySelector('#setup-tab')
const usersTabLink = document.querySelector('#users-tab')

if (setupTabLink) {
  setupTabLink.addEventListener('click', (e) => {
    e.preventDefault()
    document.querySelector('div.well.edit-organization').style.display = 'block'
    document.querySelector('#organization-users').style.display = 'none'
    setupTabLink.classList.add('active')
    usersTabLink.classList.remove('active')
  })
}

if (usersTabLink) {
  usersTabLink.addEventListener('click', (e) => {
    e.preventDefault()
    document.querySelector('div.well.edit-organization').style.display = 'none'
    document.querySelector('#organization-users').style.display = 'block'
    setupTabLink.classList.remove('active')
    usersTabLink.classList.add('active')
  })
}
