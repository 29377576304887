import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import Fuse from 'fuse.js'
import { ApolloProvider, graphql } from 'react-apollo'
import client from '../client'
import fetchOrganization from '../queries/find-organization'
import { formatDate } from '../utils'
import archiveUsers from '../mutations/archive-user'
import deleteUsers from '../mutations/delete-user'
import unarchiveUsers from '../mutations/unarchive-user'
import generatePassword from '../mutations/generate-password'
import Choices from 'choices.js'
import WarningModal from '../components/warning-modal'
import PubSub from 'pubsub-js'

const userRowClass = (user) => {
  if (user.active) {
    return ''
  } else {
    return 'disabled'
  }
}

@graphql(fetchOrganization)
@graphql(archiveUsers, { name: 'archiveUsers' })
@graphql(deleteUsers, { name: 'deleteUsers' })
@graphql(unarchiveUsers, { name: 'unarchiveUsers' })
@graphql(generatePassword, { name: 'generatePassword' })
class OrganizationUserList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedUserIds: [],
      archiveButtonText: 'Archive',
      deleteButtonText: 'Delete',
      unarchiveButtonText: 'Un-archive',
      activeTab: props.activeTab ? props.activeTab : 'students',
      searchText: '',
      sort: { field: 'insertedAt', direction: 'desc' },
      selectedYear: 'Graduation Year',
      selectBuilt: false,
      resettingUserId: null,
      showWarning: false,
      warningAction: null,
      generatedPasswords: {},
    }
  }

  componentDidUpdate() {
    const {
      data: { organization },
    } = this.props
    const { selectBuilt } = this.state

    if (selectBuilt) return

    if (organization && organization.users) {
      const selects = document.querySelector('th select.choices')
      if (selects) {
        // Standard hates this
        const opts = {
          shouldSort: false,
          shouldSortItems: false,
          itemSelectText: '',
          searchPlaceholderValue: 'Search year',
        }
        const choices = new Choices('th select.choices', opts) // eslint-disable-line
      }

      this.setState({ selectBuilt: true })
    }
  }

  setResetPasswordWarning = (user) => {
    this.setState({
      showWarning: true,
      warningAction: () => {
        this.handleGeneratePassword(user)
      },
    })
  }

  dismissWarning = (e) => {
    e.preventDefault()

    this.setState({ showWarning: false, warningAction: null })
  }

  signInAs = (user) => {
    document.location.href = `/admin/login-as?user_id=${user.id}`
  }

  handleGeneratePassword = (user) => {
    let { generatedPasswords } = this.state

    generatedPasswords[user.id] = null
    this.setState({
      resettingUserId: user.id,
      generatedPasswords: generatedPasswords,
      showWarning: false,
    })

    this.props
      .generatePassword({
        variables: { userId: user.id },
      })
      .then((operation) => {
        generatedPasswords[user.id] = operation.data.generatePassword

        window.setTimeout(() => {
          this.setState({
            resettingUserId: null,
            generatedPasswords: generatedPasswords,
          })
        }, 500)
      })
  }

  handleArchive = () => {
    const { id } = this.props
    const { selectedUserIds } = this.state

    if (selectedUserIds.length === 0) {
      return
    }

    this.setState({ archiveButtonText: 'Archiving' })
    this.props
      .archiveUsers({
        variables: { userIds: selectedUserIds, organizationId: id },
      })
      .then(() => {
        window.setTimeout(() => {
          this.setState({ archiveButtonText: 'Archive', selectedUserIds: [] })
          this.props.data.refetch()
        }, 2000)
      })
  }

  handleDelete = () => {
    const {
      data: { organization },
      id,
    } = this.props
    const { selectedUserIds } = this.state

    if (selectedUserIds.length === 0) {
      return
    }
    if (
      organization.users.filter((user) => {
        return (
          selectedUserIds.includes(user.id.toString()) &&
          user.lastQuestionAnsweredByStudent
        )
      }).length > 0
    ) {
      alert(`A user has an answered question, unable to delete!`)
      return
    }

    this.setState({ deleteButtonText: 'Deleting' })
    this.props
      .deleteUsers({
        variables: { userIds: selectedUserIds, organizationId: id },
      })
      .then(() => {
        window.setTimeout(() => {
          this.setState({ deleteButtonText: 'Delete', selectedUserIds: [] })
          this.props.data.refetch()
        }, 2000)
      })
  }

  handleUnArchive = () => {
    const { id } = this.props
    const { selectedUserIds } = this.state

    if (selectedUserIds.length === 0) {
      return
    }

    this.setState({ unarchiveButtonText: 'Un-archiving' })
    this.props
      .unarchiveUsers({
        variables: { userIds: selectedUserIds, organizationId: id },
      })
      .then(() => {
        window.setTimeout(() => {
          this.setState({
            unarchiveButtonText: 'Un-archive',
            selectedUserIds: [],
          })
          this.props.data.refetch()
        }, 2000)
      })
  }

  setSearchField = (newField) => {
    const { activeTab } = this.state
    if (activeTab === 'students' && newField === 'updatedAt') {
      newField = 'lastQuestionAnsweredByStudent'
    } else if (newField === 'updatedAt') {
      newField = 'teacherDateOfLastActivity'
    }
    const {
      sort: { field, direction },
    } = this.state
    let newDirection

    if (newField === field) {
      if (direction === 'asc') {
        newDirection = 'desc'
      } else {
        newDirection = 'asc'
      }
    } else {
      newDirection = 'desc'
    }

    this.setState({ sort: { field: newField, direction: newDirection } })
  }

  toggleSelectAll = () => {
    const { selectedUserIds } = this.state

    const allUserIds = this.filterUsersToDisplay().map((user) => {
      return user.id
    })

    if (allUserIds.length === selectedUserIds.length) {
      this.setState({ selectedUserIds: [] })
    } else {
      this.setState({ selectedUserIds: allUserIds })
    }
  }

  toggleUserChecked = (id) => {
    const { selectedUserIds } = this.state
    const idIndex = selectedUserIds.indexOf(id)

    if (idIndex > -1) {
      selectedUserIds.splice(idIndex, 1)
      this.setState({ selectedUserIds: selectedUserIds })
    } else {
      this.setState({
        selectedUserIds: [...new Set([...selectedUserIds, ...[id]])],
      })
    }
  }

  editUser = (userId) => {
    const { id } = this.props

    window.location.href = `/admin/organizations/${id}/users/${userId}/edit`
  }

  showTab = (tab) => {
    this.setState({ activeTab: tab, selectBuilt: false })
  }

  handleSearch = (e) => {
    this.setState({ searchText: e.target.value })
  }

  handleGraduationYearChange = (e) => {
    let year = e.target.value

    if (year === null) return

    if (year && (year === 'All' || year === 'Graduation Year')) {
      year = 'All'
    } else {
      year = parseInt(year)
    }

    this.setState({ selectedYear: year })
  }

  sortByField = (users) => {
    const {
      sort: { field, direction },
    } = this.state

    return users.sort((userA, userB) => {
      if (direction === 'desc') {
        if (userB[field] === null) return -1
        if (userA[field] > userB[field]) return -1
        if (userA[field] === userB[field]) return 0
        return 1
      } else {
        if (userA[field] === null) return -1
        if (userB[field] > userA[field]) return -1
        if (userB[field] === userA[field]) return 0
        return 1
      }
    })
  }

  sortByName = (users) => {
    return users.sort((userA, userB) => {
      const userALastName = userA.name.split(' ')[1]
      const userBLastName = userB.name.split(' ')[1]
      if (userALastName === null) return -1
      if (userBLastName > userALastName) return -1
      if (userBLastName === userALastName) return 0
      return 1
    })
  }

  sortBySearch = (users) => {
    const { searchText } = this.state

    if (searchText === null || searchText === '') {
      return users
    }

    const fuseOptions = {
      shouldSort: true,
      tokenize: true,
      threshold: 1,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: ['name', 'email'],
    }

    const fuse = new Fuse(users, fuseOptions)

    return fuse.search(searchText)
  }

  showArchivedLast = (users) => {
    return users.sort((a, b) => {
      if (a.active === b.active) {
        return 0
      }

      if (a.active) {
        return -1
      }

      return 1
    })
  }

  showAdminFirst = (users) => {
    return users.sort((a, b) => {
      if (a.role === b.role) {
        return 0
      }

      if (a.role === 'School Admin') {
        return -1
      }

      return 1
    })
  }

  filterUsersToDisplay = () => {
    const {
      data: { organization },
    } = this.props
    const { activeTab, selectedYear } = this.state

    if (organization && organization.users) {
      return organization.users.filter((u) => {
        if (activeTab === 'students') {
          if (
            selectedYear !== 'Graduation Year' &&
            selectedYear !== 'All' &&
            u.graduationYear !== selectedYear
          ) {
            return false
          }

          return u.role === 'Student'
        } else {
          return u.role !== 'Student'
        }
      })
    } else {
      return []
    }
  }

  renderOption = (option, selected) => {
    if (option === selected) {
      return (
        <option value={option} selected>
          {option}
        </option>
      )
    } else {
      return <option value={option}>{option}</option>
    }
  }

  assignmentCountByRole = (user) => {
    if (user.role === 'Student') {
      return user.studentAssignmentCount
    } else {
      return user.teacherAssignmentCount
    }
  }

  lastModified = (user) => {
    if (user.role === 'Student') {
      return (
        <td>
          <div className="match-height">
            {formatDate(user.lastQuestionAnsweredByStudent, 'MM/DD/YY')}
          </div>
        </td>
      )
    } else {
      return (
        <td>
          <div className="match-height" title={user.lastActiveProjectByTeacher}>
            {formatDate(user.teacherDateOfLastActivity, 'MM/DD/YY')}
          </div>
        </td>
      )
    }
  }

  render() {
    const {
      data: { organization },
      id,
      role,
    } = this.props
    const {
      selectedUserIds,
      archiveButtonText,
      deleteButtonText,
      unarchiveButtonText,
      activeTab,
      searchText,
      selectedYear,
      resettingUserId,
      generatedPasswords,
      showWarning,
      warningAction,
    } = this.state

    const teachersLinkClass = activeTab === 'teachers' ? 'active' : ''
    const studentsLinkClass = activeTab === 'students' ? 'active' : ''
    const disableArchive = selectedUserIds.length === 0
    let years = []

    if (organization && organization.users) {
      years = Array.from(
        new Set(organization.users.map((u) => u.graduationYear))
      ).sort()
    }

    const displayedUsers =
      activeTab === 'teachers'
        ? this.sortBySearch(
            this.showAdminFirst(
              this.showArchivedLast(
                this.sortByField(this.filterUsersToDisplay())
              )
            )
          )
        : this.sortBySearch(
            this.showArchivedLast(this.sortByName(this.filterUsersToDisplay()))
          )

    const refreshSubscriber = () => {
      this.props.data.refetch()
    }

    PubSub.subscribe('refresh_users', refreshSubscriber)

    return (
      <form>
        <div className="container">
          <div className="header">
            <h2>
              Users{' '}
              {organization && (
                <span>
                  <br />({organization.name})
                </span>
              )}
            </h2>
            <nav className="actions">
              {activeTab === 'students' && role === 'Admin' && false && (
                <a
                  className={
                    disableArchive ? 'disabled button' : 'delete-button'
                  }
                  onClick={this.handleDelete}
                >
                  {deleteButtonText}
                </a>
              )}
              <a
                className={disableArchive ? 'disabled button' : 'button'}
                onClick={this.handleArchive}
              >
                {archiveButtonText}
              </a>
              <a
                className={disableArchive ? 'disabled button' : 'button'}
                onClick={this.handleUnArchive}
              >
                {unarchiveButtonText}
              </a>
              <a
                className="button"
                href={`/admin/organizations/${id}/users/new`}
              >
                Add User
              </a>
              <a
                className="button"
                href={`/admin/organizations/${id}/users/import-${activeTab}`}
              >
                Batch Import (CSV)
              </a>
            </nav>
          </div>
        </div>
        <div className="container">
          <div className="header">
            <nav
              className="subnav"
              role="navigation"
              aria-label="Organization Navigation"
            >
              <a
                className={studentsLinkClass}
                onClick={() => this.showTab('students')}
              >
                Students
              </a>
              <a
                className={teachersLinkClass}
                onClick={() => this.showTab('teachers')}
              >
                Teachers
              </a>
              <div className="search">
                <i className="material-icons">search</i>
                <input
                  type="text"
                  value={searchText}
                  onChange={this.handleSearch}
                  placeholder="Search Users..."
                />
              </div>
            </nav>
          </div>
          <table className="fancy remove-top-margin">
            <thead>
              <tr>
                <th
                  onClick={this.toggleSelectAll}
                  style={{ cursor: 'pointer' }}
                >
                  <div>Select All</div>
                </th>
                <th>
                  <div>Name</div>
                </th>
                <th>
                  <div>Email</div>
                </th>
                {activeTab === 'students' && (
                  <th>
                    <div>
                      <select
                        className="choices"
                        onChange={this.handleGraduationYearChange}
                      >
                        <option placeholder>Graduation Year</option>
                        {['All', ...years].map((y) =>
                          this.renderOption(y, selectedYear)
                        )}
                      </select>
                    </div>
                  </th>
                )}
                <th>
                  <div>Role</div>
                </th>
                <th>
                  <div>Questions</div>
                </th>
                {activeTab === 'teachers' && (
                  <th>
                    <div>Projects</div>
                  </th>
                )}
                <th
                  onClick={() => this.setSearchField('updatedAt')}
                  style={{ cursor: 'pointer' }}
                >
                  <div>Last Active</div>
                </th>
                {(role === 'Admin' || role === 'School Admin') && (
                  <th>
                    <div>Reset Password</div>
                  </th>
                )}
                {role === 'Admin' && (
                  <th>
                    <div>Login</div>
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {displayedUsers &&
                displayedUsers.map((user) => (
                  <tr className={userRowClass(user)}>
                    <td>
                      <div
                        className="match-height"
                        onClick={() => this.toggleUserChecked(user.id)}
                      >
                        <input
                          className="bulk-action-checkbox"
                          type="checkbox"
                          value={user.id}
                          checked={
                            selectedUserIds.indexOf(user.id) > -1
                              ? 'checked'
                              : ''
                          }
                        />
                        <label htmlFor="user_id_#{u.id}" />
                      </div>
                    </td>
                    <td>
                      <div className="match-height">
                        {['Admin', 'School Admin'].includes(role) && (
                          <a onClick={() => this.editUser(user.id)}>
                            {user.name}
                          </a>
                        )}
                        {!['Admin', 'School Admin'].includes(role) && user.name}
                      </div>
                    </td>
                    <td>
                      <div className="match-height">{user.email}</div>
                    </td>
                    {activeTab === 'students' && (
                      <td>
                        <div className="match-height">
                          {user.graduationYear || 'N/A'}
                        </div>
                      </td>
                    )}
                    <td>
                      <div className="match-height">{user.role}</div>
                    </td>
                    <td>
                      <div
                        className="match-height"
                        style={{ alignItems: 'center' }}
                      >
                        {' '}
                        {this.assignmentCountByRole(user)}{' '}
                      </div>
                    </td>
                    {user.role !== 'Student' && (
                      <td>
                        <div
                          className="match-height"
                          style={{ alignItems: 'center' }}
                        >
                          {' '}
                          {user.allActiveProjectsByTeacherCount}{' '}
                        </div>
                      </td>
                    )}
                    {this.lastModified(user)}
                    {(role === 'Admin' || role === 'School Admin') && (
                      <td>
                        <div
                          className="match-height"
                          style={{ alignItems: 'center' }}
                        >
                          <a onClick={() => this.setResetPasswordWarning(user)}>
                            <img
                              src="/images/icon-lock.png"
                              width="20"
                              height="20"
                            />
                          </a>
                          {resettingUserId === user.id && (
                            <span>Resetting</span>
                          )}
                          {generatedPasswords[user.id] && (
                            <span>
                              New Password:
                              <br />
                              {generatedPasswords[user.id]}
                            </span>
                          )}
                        </div>
                      </td>
                    )}
                    {role === 'Admin' && (
                      <td>
                        <div className="match-height">
                          <a onClick={() => this.signInAs(user)}>Sign in as</a>
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
          <WarningModal
            isOpen={showWarning}
            onClose={this.dismissWarning}
            onConfirm={warningAction}
            confirmText="Continue"
            dismissText="Cancel"
          >
            <h2 style={{ marginTop: 48, marginBottom: 54 }}>
              Generate a new password for a user who is locked out. (You'll need
              to provide it to the user.)
            </h2>
          </WarningModal>
        </div>
      </form>
    )
  }
}

const organizationUserList = document.getElementById('organization-users')
if (organizationUserList) {
  const id = organizationUserList.getAttribute('data-id')
  const role = organizationUserList.getAttribute('data-role')
  const activeTab = organizationUserList.getAttribute('data-active-tab')
  const props = { id, role, activeTab }

  ReactDOM.render(
    <ApolloProvider client={client}>
      <OrganizationUserList {...props} />
    </ApolloProvider>,
    organizationUserList
  )
}
