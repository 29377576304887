import { gql } from 'react-apollo'

export default gql`
  mutation submitAnswer($assignmentId: ID!) {
    submitAnswer(assignmentId: $assignmentId) {
      id
      assignmentId
      text
      evidence
      submittedAt
      updatedAt
    }
  }
`
