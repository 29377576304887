import { gql } from 'react-apollo'

export default gql`
  query {
    question_categories {
      id
      name
      sortOrder
      allowed
      hidden
    }
    questions {
      id
      text
      stage
      learnerType
      category {
        id
        name
        allowed
      }
      archived
      preview
      pAndA
    }
  }
`
