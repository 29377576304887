import React, { Component } from 'react'
import Modal from 'react-modal'

const className = {
  base: 'modal',
  afterOpen: 'modal-after-open',
  beforeClose: 'modal-before-close',
}

const overlayClassName = {
  base: 'overlay',
  afterOpen: 'overlay-after-open',
  beforeClose: 'overlay-before-close',
}

class SetupCompleteModal extends Component {
  render() {
    const { isOpen, onCancel } = this.props

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onCancel}
        contentLabel="Setup Complete Modal"
        className={className}
        overlayClassName={overlayClassName}
      >
        <a className="close" onClick={onCancel}>
          Cancel
        </a>
        <p style={{ marginTop: 48, marginBottom: 54 }}>
          Students can print, export (PDF), or share their journals by secure
          weblink. <br />
          <br />
          The PDF export can include all reflections, or just those the student
          wants to showcase.
        </p>
        <button onClick={onCancel}>Review Questions</button>
      </Modal>
    )
  }
}

export default SetupCompleteModal
