import { dateFromISO8601, formatDate } from './utils'

// Display last modified in local time
const dates = document.querySelectorAll('[data-datetime]')
if (dates && dates.length > 0) {
  Array.from(dates).map((date) => {
    const ios8601 = date.getAttribute('data-datetime')
    const datetime = dateFromISO8601(ios8601)
    date.innerText = formatDate(datetime)
  })
}

// Hide graduation_year if teacher user being created
const userGraduation = document.getElementById('user_graduation_choices')
const userGraduationField = document.getElementById('user_graduation_year')
const userRoleField = document.getElementById('user_role_choices')
const subjectArea = document.getElementById('subject_area')
if (userGraduation && userRoleField) {
  userRoleField.addEventListener('change', () => {
    if (userRoleField.value === 'Teacher') {
      subjectArea.style.display = 'block'
    } else {
      subjectArea.style.display = 'none'
    }
    if (
      userRoleField.value === 'Teacher' ||
      userRoleField.value === 'School Admin'
    ) {
      userGraduationField.value = ''
      userGraduation.style.display = 'none'
    } else {
      userGraduation.style.display = 'block'
    }
  })
}

// Enable/disable bulk actions on organization edit form
// when editing users
let bulkCheckbox = document.querySelectorAll('.bulk-action-checkbox')

Array.from(bulkCheckbox).forEach((checkbox) => {
  checkbox.addEventListener('change', () => {
    if (document.querySelectorAll('.bulk-action-checkbox:checked').length > 0) {
      document.querySelectorAll('.bulk-action').forEach((action) => {
        action.removeAttribute('disabled')
      })
    } else {
      document.querySelectorAll('.bulk-action').forEach((action) => {
        action.setAttribute('disabled', 'disabled')
      })
    }
  })
})
