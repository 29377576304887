import { gql } from 'react-apollo'

export default gql`
  mutation updateAnswer($assignmentId: ID!, $text: String) {
    updateAnswer(assignmentId: $assignmentId, text: $text) {
      id
      assignmentId
      text
      evidence
      submittedAt
      updatedAt
    }
  }
`
