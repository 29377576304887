import React, { Component } from 'react'
import DatePicker from './date-picker'
import { formatDate } from '../utils'

class DateInput extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isActive: false,
    }
  }

  handleSave = (d) => {
    this.props.onChange(d)
    this.setState({ isActive: false, scheduled: true })
  }

  toggleActive = () => {
    if (!this.readOnly()) {
      const { isActive } = this.state
      this.setState({ isActive: !isActive })
    }
  }

  formattedDate = () => {
    const { datetime, dateAssigned, dateDueAssigned } = this.props

    if (dateAssigned === false && this.readOnly() === false) {
      return ''
    }

    if (dateDueAssigned === false && this.readOnly() === false) {
      return ''
    }

    const formatted = formatDate(datetime, 'DD MMMM YYYY')
    return formatted
  }

  divClass = (readOnly) => {
    return `field date ${readOnly ? 'disabled' : ''}`
  }

  readOnly = () => {
    const { readOnly, dateDueAssigned } = this.props

    if (dateDueAssigned === undefined) {
      return readOnly
    }
    return false
  }

  render() {
    const { isActive } = this.state
    const { datetime, label, required, min, max } = this.props

    return (
      <div className={this.divClass(this.readOnly())}>
        <label>{label}</label>
        <input
          type="text"
          onFocus={this.toggleActive}
          placeholder="Select a Date"
          value={this.formattedDate()}
          readOnly={this.readOnly()}
          required={required}
        />
        <div
          className={isActive ? 'overlay no-shadow' : ''}
          onClick={this.toggleActive}
        />
        <DatePicker
          min={min}
          max={max}
          datetime={datetime}
          isActive={isActive}
          onSave={this.handleSave}
        />
      </div>
    )
  }
}

export default DateInput
