import { gql } from 'react-apollo'

export default gql(`
  mutation createStudent($email: String!, $firstName: String!, $lastName: String!, $graduationYear: String!) {
    createStudent(email: $email, firstName: $firstName, lastName: $lastName, graduationYear: $graduationYear) {
      id
      name
      firstName
      lastName
      role
      graduationYear
      updatedAt
    }
  }
`)
