import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import Choices from 'choices.js'
import FloatingLabel from '../components/floating-label'
import replaceQuestion from '../mutations/replace-question'
import createQuestion from '../mutations/create-question'
import updateQuestion from '../mutations/update-question'
import { ApolloProvider, graphql } from 'react-apollo'
import client from '../client'

@graphql(replaceQuestion, { name: 'replaceQuestion' })
@graphql(createQuestion, { name: 'createQuestion' })
@graphql(updateQuestion, { name: 'updateQuestion' })
class QuestionForm extends Component {
  constructor(props) {
    super(props)

    const {
      question: {
        text,
        category,
        learner_type,
        stage,
        archived,
        preview,
        pAndA,
      },
      categories,
      stages,
      learnerTypes,
    } = props
    let {
      question: { learnerType },
    } = props
    const replaceQuestionText = 'Replace Question'
    if (learnerType === undefined) {
      learnerType = learner_type // eslint-disable-line
    }
    let intermediateState = {
      text,
      category,
      learnerType,
      stage,
      archived,
      preview,
      replaceQuestionText,
      pAndA: pAndA || false,
    }

    if (category === undefined) {
      intermediateState.category = props.category
    }

    if (text) {
      // Persisted Question

      if (intermediateState.learnerType === null) {
        intermediateState.learnerType = 'Flexible'
      }

      this.state = intermediateState
    } else {
      // New Question
      this.state = {
        text: '',
        category: categories[0],
        learnerType: learnerTypes[0],
        stage: stages[0],
        archived: false,
        preview: false,
        replaceQuestionText,
        pAndA: false,
      }
    }
  }

  componentDidMount() {
    const selects = document.querySelector('.choices')
    if (selects) {
      // Standard hates this
      const opts = { shouldSort: false, shouldSortItems: false }
      const choices = new Choices('.choices', opts) // eslint-disable-line
    }
  }

  handleTextChange = (e) => {
    this.setState({ text: e.target.value.replace(/\n/g, '') })
  }

  handleCategoryChange = (e) => {
    const category = this.props.categories.filter(
      (c) => Number(c.id) === Number(e.target.value)
    )[0]
    this.setState({ category: category })
  }

  handleStageChange = (e) => {
    this.setState({ stage: e.target.value })
  }

  handleLearnerTypeChange = (e) => {
    this.setState({ learnerType: e.target.value })
  }

  handleArchivedChange = (e) => {
    let isTrue = e.target.value === 'true'
    this.setState({ archived: isTrue })
  }

  handlePreviewChange = (e) => {
    let isTrue = e.target.value === 'true'
    this.setState({ preview: isTrue })
  }

  handlePandAChange = () => {
    const { pAndA } = this.state
    this.setState({ pAndA: !pAndA })
  }

  handleReplaceQuestion = () => {
    this.setState({ replaceQuestionText: 'Replacing question...' })

    this.props
      .replaceQuestion({
        variables: { id: this.props.question.id },
      })
      .then((operation) => {
        window.setTimeout(() => {
          this.setState({
            replaceQuestionText: 'Question replaced! Redirecting...',
          })
          window.location = `/admin/questions/${operation.payload.data.replaceQuestion.id}/edit?success=Question%20Replaced!`
        }, 2000)
      })
  }

  handleSubmit = (event) => {
    event.preventDefault()

    const {
      question: { id },
    } = this.props
    const { category, stage, learnerType, archived, preview, text, pAndA } =
      this.state
    const categoryId = Number(category.id)

    let mutation = null
    let variables = null

    if (id) {
      mutation = this.props.updateQuestion
      variables = {
        id,
        categoryId,
        stage,
        learnerType,
        archived,
        preview,
        text,
        pAndA,
      }
    } else {
      mutation = this.props.createQuestion
      variables = { categoryId, stage, learnerType, text, preview, pAndA }
    }

    mutation({
      variables: variables,
    }).then(() => {
      window.location.href = '/admin/questions?success=Saved%20Question!'
    })
  }

  render() {
    const { categories, stages, learnerTypes } = this.props
    const { text, category, learnerType, stage, archived, preview, pAndA } =
      this.state

    return (
      <div className="question-form">
        <form acceptCharset="UTF-8" onSubmit={this.handleSubmit}>
          <FloatingLabel labelText="Text" labelFor="question_text">
            <textarea
              id="question_text"
              name="question[text]"
              onChange={this.handleTextChange}
              required
              type="text"
              value={text}
            />
          </FloatingLabel>
          <select
            id="question_category"
            className="choices"
            name="question[category_id]"
            onChange={this.handleCategoryChange}
          >
            {categories.map((c) => (
              <option
                value={c.id}
                selected={c.id === category.id ? 'true' : ''}
              >
                {c.name}
              </option>
            ))}
          </select>
          <select
            id="question_stage"
            className="choices"
            name="question[stage]"
            onChange={this.handleStageChange}
          >
            {stages.map((s) => (
              <option value={s} selected={s === stage ? 'true' : ''}>
                {s}
              </option>
            ))}
          </select>
          <select
            id="question_learner_type"
            className="choices"
            name="question[learner_type]"
            onChange={this.handleLearnerTypeChange}
          >
            {learnerTypes.map((s) => (
              <option value={s} selected={s === learnerType ? 'true' : ''}>
                {s}
              </option>
            ))}
          </select>
          <select
            id="question_archived"
            className="choices"
            name="question[archived]"
            onChange={this.handleArchivedChange}
          >
            <option value="false" {...(archived ? {} : { selected: true })}>
              Active
            </option>
            <option value="true" {...(archived ? { selected: true } : {})}>
              Archived
            </option>
          </select>
          <select
            id="question_preview"
            className="choices"
            name="question[preview]"
            onChange={this.handlePreviewChange}
          >
            <option value="false" {...(preview ? {} : { selected: true })}>
              No Preview
            </option>
            <option value="true" {...(preview ? { selected: true } : {})}>
              Preview
            </option>
          </select>
          <div onClick={this.handlePandAChange}>
            <input
              type="checkbox"
              name="process-and-authority"
              id="process-and-authority"
              checked={pAndA}
            />
            <label htmlFor={'process-and-authority'}>
              <strong>P&A</strong>
            </label>
          </div>
          <br />
          <input type="submit" value="Save Question" />
        </form>
        <br />
        <a onClick={this.handleReplaceQuestion.bind(this)} href="#">
          {this.state.replaceQuestionText}
        </a>
      </div>
    )
  }
}

const questionForm = document.getElementById('question-form')
if (questionForm) {
  const csrfTag = document.querySelector('meta[name=csrf-token]')
  const csrfToken = csrfTag.getAttribute('content')
  const categories = JSON.parse(questionForm.getAttribute('data-categories'))
  const learnerTypes = JSON.parse(
    questionForm.getAttribute('data-learner-types')
  )
  const stages = JSON.parse(questionForm.getAttribute('data-stages'))
  const question = JSON.parse(questionForm.getAttribute('data-question')) || {}
  const category = JSON.parse(questionForm.getAttribute('data-category'))
  const props = {
    csrfToken,
    category,
    categories,
    learnerTypes,
    stages,
    question,
  }

  ReactDOM.render(
    <ApolloProvider client={client}>
      <QuestionForm {...props} />
    </ApolloProvider>,
    questionForm
  )
}

export default QuestionForm
