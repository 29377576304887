import Modal from 'react-modal'
import React, { Component } from 'react'
import { formatDate } from '../utils'
import PostscriptText from '../components/postscript-text'

const className = {
  base: 'modal widest',
  afterOpen: 'modal-after-open',
  beforeClose: 'modal-before-close',
}

const overlayClassName = {
  base: 'overlay',
  afterOpen: 'overlay-after-open',
  beforeClose: 'overlay-before-close',
}

const formattedTime = (timestamp) => {
  return formatDate(timestamp, 'ddd, MMMM D [at] h:mma')
}

class JournalExportModal extends Component {
  render() {
    const {
      exportModal,
      handleClose,
      handleExport,
      exportList,
      exportable,
      toggleChecked,
      isChecked,
    } = this.props

    return (
      <Modal
        isOpen={exportModal}
        onRequestClose={handleClose}
        contentLabel={'Export Reflection'}
        className={className}
        overlayClassName={overlayClassName}
      >
        <a className="close" onClick={handleClose}>
          Cancel
        </a>
        <h1>Export Reflection Journal (PDF)</h1>
        <div id="reflection">
          <div className="description">Select questions to export</div>
          <div className="completed-assignments">
            {exportable.map((a) => (
              <div
                className="assignment"
                key={a.id}
                onClick={() => {
                  toggleChecked(a.id)
                }}
              >
                <div className="export-row">
                  <div className="col0">
                    <input
                      type="checkbox"
                      value={a.id}
                      checked={isChecked(a.id)}
                    />
                    <label />
                  </div>
                  <div className="col1">
                    <div className="submitted-at">
                      {a.answer.submittedAt > a.dueAt && (
                        <div className="badge">Late</div>
                      )}
                      Submitted {formattedTime(a.answer.submittedAt)}
                    </div>
                    <label>{a.question.text}</label>
                    <div className="text">
                      {a.answer.text}
                      <PostscriptText answer={a.answer} />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <button
          style={{ width: '300px', marginTop: '25px' }}
          disabled={exportList.length === 0}
          onClick={handleExport}
        >
          Export Questions
        </button>
      </Modal>
    )
  }
}

export default JournalExportModal
