import React, { Component } from 'react'
import DateInput from './date-input'
import { format } from 'date-fns'

class OrganizationReportForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      minStartDate: new Date(props.minStartDate),
      startDate: new Date(props.startDate),
      endDate: new Date(props.endDate),
    }
  }

  handleStartDateChange = (newDate) => {
    this.setState({ startDate: newDate })
  }

  handleEndDateChange = (newDate) => {
    this.setState({ endDate: newDate })
  }

  runReport = () => {
    const { organizationId } = this.props
    const { startDate, endDate } = this.state

    const startString = format(startDate, 'YYYY-MM-DDTHH:mm')
    const endString = format(endDate, 'YYYY-MM-DDTHH:mm')

    window.location.href = `/admin/organizations/${organizationId}/reports?start_date=${startString}&end_date=${endString}`
  }

  render() {
    const { minStartDate, startDate, endDate } = this.state

    return (
      <div className="organization-report-form">
        <div className="fields">
          <DateInput
            datetime={startDate}
            label="Start Date"
            min={minStartDate}
            onChange={this.handleStartDateChange}
            required
          />
          <DateInput
            datetime={endDate}
            label="End Date"
            min={startDate}
            onChange={this.handleEndDateChange}
            required
          />
        </div>
        <div className="actions">
          <button onClick={this.runReport}>View Report</button>
        </div>
      </div>
    )
  }
}

export default OrganizationReportForm
