import React, { Component } from 'react'
import Filters from './question-filters'
import QuestionCard from './question-card'
import QuestionUnlockBankInfo from './question-unlock-bank-info'
import SuggestQuestionModal from './suggest-question-modal'
import { graphql } from 'react-apollo'
import fetchQuestions from '../queries/questions'
import Fuse from 'fuse.js'
import * as R from 'ramda'

const stages = ['Initial', 'Mid-stream', 'Wrap-up', 'Meta']

@graphql(fetchQuestions)
class QuestionBank extends Component {
  constructor(props) {
    super(props)

    this.state = {
      category: null,
      stage: stages[0],
      isBankModalOpen: false,
      isQuestionModalOpen: false,
      dropdown: false,
      searchText: '',
    }
  }

  handleCategoryChange = (category) => {
    this.setState({ category: category })
  }

  handleStageChange = (stage) => {
    this.setState({ stage })
  }

  handleSearchTextChange = (searchText) => [this.setState({ searchText })]

  toggleDropdown = (e) => {
    e.stopPropagation()

    this.setState({ dropdown: !this.state.dropdown })
  }

  hideDropdown = () => {
    this.setState({ dropdown: false })
  }

  toggleBankModal = () => {
    const { isBankModalOpen } = this.state
    this.setState({ isBankModalOpen: !isBankModalOpen })
  }

  toggleQuestionModal = () => {
    const { isQuestionModalOpen } = this.state
    this.setState({ isQuestionModalOpen: !isQuestionModalOpen })
  }

  questionCardMap = (filteredQuestions, category) => {
    return filteredQuestions.map((q) => (
      <QuestionCard
        enabled={category.allowed}
        id={q.item.id}
        key={q.item.id}
        text={q.item.text}
        learnerType={q.item.learnerType}
        score={q.score}
      />
    ))
  }

  questionBankUnlock = (category) => {
    if (category && category.allowed === false) {
      return <QuestionUnlockBankInfo />
    }
  }

  filterBySearchText = (objects, searchText) => {
    if (searchText.length > 2) {
      const fuseOptions = {
        findAllMatches: true,
        includeScore: true,
        includeMatches: true,
        shouldSort: true,
        tokenize: true,
        matchAllTokens: true,
        threshold: 0.05,
        location: 0,
        distance: 500,
        maxPatternLength: 32,
        minMatchCharLength: searchText.length,
        keys: [
          { name: 'text', weight: 0.9 },
          { name: 'learnerType', weight: 0.1 },
        ],
      }

      if (searchText.toUpperCase() === 'P&A') {
        return objects
          .filter((obj) => obj.pAndA === true)
          .map((item) => ({ item: item, matches: [] }))
      }

      const fuse = new Fuse(objects, fuseOptions)
      return fuse.search(searchText)
    } else {
      return objects.map((item) => ({ item: item, matches: [] }))
    }
  }

  renderQuestions = () => {
    const { data } = this.props
    const { category, stage, searchText } = this.state
    const sortByPreview = R.sortWith([R.descend(R.prop('preview'))])

    if (data.questions) {
      let questions = data.questions
        .filter((q) => q.archived === false)
        .filter(
          (q) =>
            category !== null &&
            q.category.id === category.id &&
            q.stage === stage
        )
      if (category !== null && category.allowed === false) {
        questions = questions.filter((q) => q.preview === true)
      }
      questions = sortByPreview(questions)
      var filteredQuestions = this.filterBySearchText(
        questions,
        searchText
      ).map(this.highlightMatches)
      if (category !== null && filteredQuestions.length === 0) {
        return <p style={{ textAlign: 'center' }}>No matching questions.</p>
      } else {
        return (
          <div>
            {this.questionCardMap(filteredQuestions, category)}
            {this.questionBankUnlock(category)}
          </div>
        )
      }
    }
  }

  highlightMatches = (result) => {
    // if (result.score < 0.5) {
    let newResult = R.clone(result)
    // let matches = result.matches.filter((m) => m.key == 'text')

    for (let match of result.matches) {
      // Find the longest match in format [startIndex, endIndex]
      let index = match.indices.reduce(
        (prev, next) => {
          let prevLength = prev[1] - prev[0]
          let nextLength = next[1] - next[0]

          return nextLength > prevLength ? next : prev
        },
        [0, 0]
      )

      let str = newResult.item[match.key]
      newResult.item[match.key] =
        str.substring(0, index[0]) +
        '<mark>' +
        str.substring(index[0], index[1] + 1) +
        '</mark>' +
        str.substring(index[1] + 1)
    }

    return newResult
    // } else {
    //   return result
    // }
  }

  render() {
    const { category, stage, isBankModalOpen, dropdown, searchText } =
      this.state
    const { data } = this.props

    return (
      <div className="question-bank" onClick={this.hideDropdown}>
        <Filters
          categories={data.question_categories}
          category={category}
          stages={stages}
          stage={stage}
          dropdown={dropdown}
          toggleDropdown={this.toggleDropdown}
          onCategoryChange={this.handleCategoryChange}
          onStageChange={this.handleStageChange}
          searchText={searchText}
          onSearchTextChange={this.handleSearchTextChange}
        />
        {this.renderQuestions()}
        {category === null && (
          <div className="suggest-a-question" onClick={this.toggleBankModal}>
            Suggest a Bank
          </div>
        )}
        <SuggestQuestionModal
          isOpen={isBankModalOpen}
          variant="bank"
          onCancel={this.toggleBankModal}
        />
      </div>
    )
  }
}

export default QuestionBank
