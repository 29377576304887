import React from 'react'

const HighlightedText = ({ text, highlight }) => {
  if (highlight && highlight !== '') {
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'))

    return (
      <div>
        {parts.map((part, i) => (
          <span
            key={i}
            style={
              part.toLowerCase() === highlight.toLowerCase()
                ? { backgroundColor: '#FFC969' }
                : {}
            }
          >
            {part}
          </span>
        ))}
      </div>
    )
  } else {
    return <div>{text}</div>
  }
}

export default HighlightedText
