import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { DragDropContext } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import SelectSectionsModal from '../components/select-sections-modal'
import WarningModal from '../components/warning-modal'
import SetupCompleteModal from '../components/setup-complete-modal'
import Sidebar from '../components/project-sidebar'
import ViewReflections from '../components/view-reflections'
import Queue from '../components/project-queue'
import LiveQuestionList from '../components/live-question-list'
import PulseModal from '../components/pulse-modal'
import { ApolloProvider, graphql } from 'react-apollo'
import client from '../client'
import findProject from '../queries/find-project'
import renameProject from '../mutations/rename-project'
import addSections from '../mutations/add-sections'
import debounce from 'debounce'

const countStudents = (sections) => {
  return (
    sections &&
    sections.map((s) => s.students.length).reduce((i, acc) => i + acc, 0)
  )
}

@DragDropContext(HTML5Backend)
@graphql(addSections, { name: 'addSections' })
@graphql(renameProject, { name: 'renameProject' })
@graphql(findProject)
class ProjectForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      name: props.name,
      selectSectionsOpen: false,
      setupComplete: false,
      viewReflectionsOpen: false,
      viewReflectionsStudent: null,
      warningOpen: false,
      activeAssignment: null,
      pulseAssignment: null,
    }
  }

  renameProject = debounce(() => {
    const { renameProject, id } = this.props
    const { name } = this.state

    renameProject({
      variables: { id, name },
    }).then(() => {})
  }, 500)

  handleNameFocus = () => {
    const { name } = this.state

    if (name === 'Untitled Journal') {
      this.setState({ name: '' })
    }
  }

  handleNameChange = (e) => {
    this.setState({ name: e.target.value })

    if (e.target.value.length > 0) {
      this.renameProject()
    }
  }

  handleAddSections = (sectionIds) => {
    const { addSections, data, id } = this.props
    this.setState({ selectSectionsOpen: false })
    addSections({
      variables: { id, sectionIds },
    }).then(() => {
      data.refetch()
    })
  }

  handleCancelAssignment = () => {
    const { data } = this.props
    this.setState({ activeAssignment: null })
    data.refetch()
  }

  handleSetupComplete = () => {
    const { data } = this.props
    this.setState({ setupComplete: true, activeAssignment: null })
    data.refetch()
  }

  handleSetupCompleteClose = () => {
    this.setState({
      setupComplete: false,
    })
  }

  setActiveAssignment = (assignment) => {
    const { name } = this.state
    const {
      data: { project },
    } = this.props
    if (name && name.length > 0 && project.sections.length > 0) {
      this.setState({ activeAssignment: assignment })
    } else {
      this.toggleWarning()
    }
  }

  toggleWarning = (e) => {
    e && e.preventDefault()
    const { warningOpen } = this.state
    this.setState({ warningOpen: !warningOpen })
  }

  toggleViewReflections = (e) => {
    e && e.preventDefault()
    const { viewReflectionsOpen } = this.state
    this.setState({ viewReflectionsOpen: !viewReflectionsOpen })
  }

  setViewReflectionsStudent = (student) => {
    if (student) {
      this.setState({
        viewReflectionsOpen: true,
        viewReflectionsStudent: student,
      })
    } else {
      this.setState({ viewReflectionsStudent: student })
    }
  }

  toggleSelectSections = () => {
    const { selectSectionsOpen } = this.state
    this.setState({ selectSectionsOpen: !selectSectionsOpen })
  }

  togglePulse = (assignment) => {
    this.setState({ pulseAssignment: assignment })
  }

  selectSectionLinkText = (project) => {
    if (project && project.sections.length) {
      const firstSection = project.sections[0]
      const groupName = firstSection.group.name
      const sectionNames = project.sections.map((s) =>
        s.name.replace(/\s/g, '\u00A0')
      )
      return `${groupName}: ${sectionNames.join(', ')}`
    } else {
      return 'Add Groups'
    }
  }

  viewReflectionsEnabled = () => {
    const {
      data: { project },
    } = this.props
    return (
      project &&
      project.assignments &&
      project.assignments.filter(
        (a) => a.submittedAnswers && a.submittedAnswers.length > 0
      ).length !== 0
    )
  }

  projectTitle = (currentUserRole, name) => {
    if (currentUserRole === 'teacher' || currentUserRole === 'collaborator') {
      return (
        <div className="field floating project-name">
          <input
            type="text"
            value={name}
            onChange={this.handleNameChange}
            onFocus={this.handleNameFocus}
            required
          />
          <div className="highlight" />
        </div>
      )
    } else {
      return (
        <div className="project-name">
          <div className="title">{name}</div>
        </div>
      )
    }
  }

  sectionDisplay = (currentUserRole, project) => {
    if (currentUserRole === 'teacher' || currentUserRole === 'collaborator') {
      return (
        <a onClick={this.toggleSelectSections}>
          <i className="material-icons">add_circle</i>
          {this.selectSectionLinkText(project)}
        </a>
      )
    } else {
      return (
        <div className="sections-list">
          {this.selectSectionLinkText(project)}
        </div>
      )
    }
  }

  render() {
    const {
      activeAssignment,
      name,
      selectSectionsOpen,
      viewReflectionsOpen,
      viewReflectionsStudent,
      warningOpen,
      pulseAssignment,
      setupComplete,
    } = this.state
    const {
      id,
      data,
      data: { project },
    } = this.props
    const selectedSections = project && project.sections
    const lastDueAt = project && project.lastDueAt
    const liveQuestions = project && project.liveQuestions
    const sections = project && project.sections
    const currentUserRole = project && project.currentUserRole
    const numStudents = countStudents(sections)
    const pulseAssignmentId = pulseAssignment && pulseAssignment.id
    const isMeta = (a) => a.metaReflection
    const liveMeta = liveQuestions && liveQuestions.filter(isMeta)
    const metaWasAssigned = liveMeta && liveMeta.length > 0

    return (
      <div className="project-form">
        <Sidebar
          data={data}
          lastDueAt={lastDueAt}
          assignment={activeAssignment}
          onCancel={this.handleCancelAssignment}
          onMetaSave={this.handleSetupComplete}
        />
        <form>
          <div className="header">
            {this.projectTitle(currentUserRole, name)}
            <button
              disabled={!this.viewReflectionsEnabled()}
              onClick={this.toggleViewReflections}
            >
              View Journals
            </button>
          </div>
          <div className="subheader">
            {this.sectionDisplay(currentUserRole, project)}
            <SelectSectionsModal
              id={id}
              projectId={id}
              isOpen={selectSectionsOpen}
              onCancel={this.toggleSelectSections}
              onSave={this.handleAddSections}
              selected={selectedSections}
            />
          </div>
          <div className="project-queue">
            <LiveQuestionList
              questions={liveQuestions}
              numStudents={numStudents}
              togglePulse={this.togglePulse}
              onAssign={this.setActiveAssignment}
            />
            <div>
              <hr />
              <div className="queue">
                <h2>Queue</h2>
                <p>The Queue is your workspace for organizing questions.</p>
                {!metaWasAssigned && (
                  <Queue
                    projectId={id}
                    activeAssignment={activeAssignment}
                    onAssign={this.setActiveAssignment}
                    allowAssign={!metaWasAssigned}
                  />
                )}
              </div>
              <div className="meta-reflection">
                <p>
                  <b>Meta-Reflection</b>
                </p>
                <Queue
                  projectId={id}
                  metaReflection
                  onAssign={this.setActiveAssignment}
                  allowAssign={true}
                  activeAssignment={activeAssignment}
                />
              </div>
            </div>
          </div>
        </form>
        <ViewReflections
          isOpen={viewReflectionsOpen}
          onClose={this.toggleViewReflections}
          project={project}
          selectedStudent={viewReflectionsStudent}
          setSelectedStudent={this.setViewReflectionsStudent}
        />
        <PulseModal
          id={pulseAssignmentId}
          onClose={this.togglePulse}
          setViewReflectionsStudent={this.setViewReflectionsStudent}
        />
        <WarningModal isOpen={warningOpen} onClose={this.toggleWarning}>
          <h2>Additional Project Setup Required</h2>
          <p style={{ marginTop: 48, marginBottom: 54 }}>
            Your project needs a Name and Group(s) before you can assign
            questions.
          </p>
        </WarningModal>
        <SetupCompleteModal
          isOpen={setupComplete}
          onCancel={this.handleSetupCompleteClose}
        />
      </div>
    )
  }
}

const projectForm = document.getElementById('project-form')
if (projectForm) {
  const id = projectForm.getAttribute('data-id')
  const name = projectForm.getAttribute('data-name')
  const props = { id, name }

  ReactDOM.render(
    <ApolloProvider client={client}>
      <ProjectForm {...props} />
    </ApolloProvider>,
    projectForm
  )
}
