import { gql } from 'react-apollo'

export default gql`
  mutation archiveSection($id: ID!) {
    updateSection(id: $id, archived: true) {
      id
      name
      archived
      students {
        id
        name
        email
        graduation_year
      }
    }
  }
`
