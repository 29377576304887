import { ApolloClient } from 'react-apollo'
import { createBatchingNetworkInterface } from 'apollo-upload-client'

const networkInterface = createBatchingNetworkInterface({
  uri: '/graphql',
  batchInterval: 10,
})

networkInterface.use([
  {
    applyBatchMiddleware(req, next) {
      // create the header object if needed.
      if (!req.options.headers) {
        req.options.headers = {}
      }

      // get the authentication token from meta tag if it exists
      const tokenElm = document.querySelector('meta[name=auth-token]')
      const token = tokenElm.getAttribute('content')
      req.options.headers.authorization = token ? `Bearer ${token}` : null
      next()
    },
  },
])

networkInterface.useAfter([
  {
    applyBatchAfterware(res, next) {
      // handle absinthe batch responses
      res.responses.forEach((resp) => {
        resp.data = resp.payload.data
      })
      next()
    },
  },
])

const client = new ApolloClient({
  networkInterface,
})

export default client
